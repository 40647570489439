import requestServer from "../request-server";

let internals = {};

internals.getChat = function (data) {
  return requestServer(
    "v1/api/chats/",
    data,
    "GET",
    {},
    "application/json",
    true
  );
};

internals.getChatByID = function (data) {
  return requestServer(
    `v1/api/chats/${data.id}/messages`,
    "",
    "GET",
    {},
    "application/json",
    true
  );
};

internals.updateChatName = function (data,id) {
  return requestServer(
    `v1/api/chats/${id}/title`,
    data,
    "PATCH",
    {},
    "application/json",
    true
  );
};

internals.attachFilesInChats = function (chatroom,data) {
  return requestServer(
    `v1/user/chat/${chatroom}/fileupload`,
    data,
    'POST',
    {},
    'multipart/form-data',
    true
  );
};

export default internals;
