import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LocationChangeListener = ({ handlePathChange }) => {
  const location = useLocation();

  useEffect(() => {
    const handleLocationChange = () => {
      console.log('Location changed to:', location.pathname);
      handlePathChange(location.pathname);
    };

    handleLocationChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return null;
};

export default LocationChangeListener;
