import { Helmet } from 'react-helmet-async';

const Meta = ({ title, description, keywords, favIcon }) => {
  return (
    <Helmet>
      {favIcon && <link rel="icon" href={favIcon} />}
      {favIcon && <link rel="apple-touch-icon" href={favIcon} />}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
    </Helmet>
  );
};

export default Meta;
