import axios from 'axios';
import { LOCAL_STORAGE_DATA } from '../constants/app-constants';
import apis from '../Services/api-services';

const API_URL = process.env.REACT_APP_API_BASE_URL;


export const getAccessToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_DATA.ACCESS_TOKEN);
};

export const getRefreshToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_DATA.REFRESH_TOKEN);
};

export const getUserData = () => {
  const userDetails = localStorage.getItem(LOCAL_STORAGE_DATA.USER);
  if (userDetails) {
    return JSON.parse(userDetails);
  } else return expireSession();
};

export const expireSession = () => {
  localStorage.clear();
  window.location.replace('/');
  return null;
};

export const getInitials = (name) => {
  if (name) {
    const words = name.split(' ');
    const initials = words
      .slice(0, 2)
      .map((word) => word.charAt(0).toUpperCase());
    return initials.join(' ');
  }
  return '';
};

export const numDifferentiation = (val,isSqFt = false) => {
  let formatted = '';
  if (typeof val === 'string') {
    val = Number(val);
  }
  if (val === null) {
    return '';
  }
  if (val >= 10000000) {
    formatted = `${ !isSqFt ? '₹' : ''} ${parseFloat((val / 10000000).toFixed(2))} Cr`;
  } else if (val >= 100000) {
    formatted = `${ !isSqFt ? '₹' : ''} ${parseFloat((val / 100000).toFixed(2))} Lac`;
  } else if (val >= 1000) {
    formatted = `${ !isSqFt ? '₹' : ''} ${!isSqFt ? parseFloat((val / 1000).toFixed(2)) + ' K' : val } `;
  }

  return formatted;
};

export const createQueryURl = (params) => {
  const query = Object.keys(params)
    .map(key => {
      const value = params[key];
      
      if (Array.isArray(value)) {
        return value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&');
      }
      
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return `?${query}`;
};

export const sanitizeData = (data) => {
  if (data === undefined || data === null) {
    return false; 
  }

  if (Array.isArray(data)) {
    return data.length > 0; 
  }

  if (typeof data === "number") {
    return data !== 0; 
  }

  if (data instanceof Date) {
    return !isNaN(data.getTime()); 
  }

  if (typeof data === "object" && data.isDayjsObject) {
    return data.isValid(); 
  }

  return data !== "" && data !== "null"; 
};


export const renderAreaOfOperatioChips = (data) => {
  if (data) {
    try {
      let parsedData = JSON.parse(JSON.parse(data || null));

      return parsedData[0].places || [];
    } catch (err) {
      console.log(err);
      return [];
    }
  } else {
    return [];
  }
};


export const fetchRefreshToken = async function () {
  try {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_DATA.REFRESH_TOKEN);
    if (!refreshToken) {
      console.error('No refresh token found. Logging out...');
      expireSession();
      return null;
    }

    const response = await axios.post(`${API_URL}v1/users/refresh_token`, {}, {
      headers: {
        'Grant-Type': 'refresh_token',
        Authorization: `Bearer ${refreshToken}`,
      },
    });

    if (response.status === 200) {
      const newAccessToken = response.data.access_token;
      const newRefreshToken = response.data.refresh_token;
      localStorage.setItem(LOCAL_STORAGE_DATA.ACCESS_TOKEN, newAccessToken);
      localStorage.setItem(LOCAL_STORAGE_DATA.REFRESH_TOKEN, newRefreshToken);
      console.log('Access token refreshed');
      return newAccessToken;
    } else {
      console.error('Failed to refresh token, logging out...');
      expireSession();
      return null;
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    expireSession();
    return null;
  }
};


export const isEmptyString = (str) => {
  return typeof str === 'string' && str.trim().length === 0;
};



