import { Box, Typography } from '@mui/material';
import React from 'react';

const CustomChip = ({ text, color, icon = null }) => {
  return (
    <Box
      sx={{
        minWidth: '100px',
        background: color,
        fontSize: '14px',
        borderRadius: '8px',
        margin: '6px 10px',
        marginLeft: 0,
        padding: '8px 12px',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {icon && (
        <img src={icon} alt="icon" width={18} style={{ marginRight: '5px' }} />
      )}
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          color: '#474747',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default CustomChip;
