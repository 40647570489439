import requestServer from '../request-server';

let internals = {};

internals.getSavedProperties = function (data) {
  return requestServer(
    'v1/user/wishlist',
    data,
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getSavedPropertyByID = function (data) {
  return requestServer(
    `v1/user/wishlist/${data.id}/details`,
    '',
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getRecomendedPropertyByID = function (data) {
  return requestServer(
    `v1/user/recommendation/${data.id}/details`,
    '',
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getListingProperties = function (data) {
  return requestServer(
    'v1/user/properties',
    data,
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getListingPropertiesByID = function (data) {
  return requestServer(
    `v1/user/property/${data.id}/details`,
    '',
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getRecommendations = function (data) {
  return requestServer(
    `v1/user/recommendations${data}`,
    '',
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getDropDownValues = function (data) {
  return requestServer(
    `v1/api/recommendation/dropdown_values`,
    data,
    'GET',
    {},
    'application/json',
    true
  );
};

internals.wishList = function (data, method) {
  return requestServer(
    `v1/user/wishlist`,
    data,
    method,
    {},
    'application/json',
    true
  );
};

internals.addProperty = function (data) {
  return requestServer(
    'v1/user/property/add',
    data,
    'POST',
    {},
    'multipart/form-data',
    true
  );
};

internals.fillPropertyDetails = function (data) {
  return requestServer(
    'v1/api/fill/property/details',
    data,
    'POST',
    {},
    'application/json',
    true
  );
};
internals.fillPropertyDescription = function (data) {
  return requestServer(
    'v1/api/fill/property/description',
    data,
    'POST',
    {},
    'application/json',
    true
  );
};

internals.getUnlockedProperties = function (data) {
  return requestServer(
    'v1/user/properties/unlocked',
    data,
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getUnlockedPropertiesByID = function (data) {
  return requestServer(
    `v1/user/property/${data.id}/unlocked/details`,
    '',
    'GET',
    {},
    'application/json',
    true
  );
};

internals.verifyProperty = function (data, id) {
  return requestServer(
    `v1/agent/property/${id}/verification`,
    data,
    'POST',
    {},
    'multipart/form-data',
    true
  );
};

internals.setPropertyCoverPicture = function (propertyId, fileId) {
  return requestServer(
    `v1/user/property/${propertyId}/file/${fileId}/set_cover`,
    {},
    'POST',
    {},
    'application/json',
    true
  );
};

internals.markDealClosure = function (propertyId, data) {
  return requestServer(
    `v1/user/property/${propertyId}/closure`,
    data,
    'POST',
    {},
    'application/json',
    true
  );
};

internals.getLeadsOnProperty = function (propertyId) {
  return requestServer(
    `v1/user/property/${propertyId}/leads`,
    {},
    'GET',
    {},
    'application/json',
    true
  );
};

internals.getRecommendedAgentProperties = function (agentId, propertyIds) {
  let query = '';

  propertyIds.forEach((propertyId) => {
    query += `property=${propertyId}&`;
  });

  return requestServer(
    `v1/agent/${agentId}/recommendations?${query}`,
    {},
    'GET',
    {},
    'application/json',
    true
  );
};

export default internals;
