import { TRANSLATIONS } from '../translations';

const translationTamil = {
  // titles
  [TRANSLATIONS.TITLES.HOME]: 'முகப்பு',
  [TRANSLATIONS.TITLES.PROPERTIES]: 'சொத்துக்கள்',
  [TRANSLATIONS.TITLES.MESSAGES]: 'செய்திகள்',
  [TRANSLATIONS.TITLES.AGENT_ID]: 'முகவர் ஐடி',
  [TRANSLATIONS.TITLES.LOGOUT]: 'லாக் அவுட்',
  [TRANSLATIONS.TITLES.PROFILE]: 'சுயவிவரம்',
  [TRANSLATIONS.TITLES.ASK_AIRA]: 'AIRA ஐக் கேளுங்கள்',
  [TRANSLATIONS.TITLES.SAVED]: 'சேமிக்கப்பட்டது',
  [TRANSLATIONS.TITLES.UNLOCKED]: 'திறக்கப்பட்டது',
  [TRANSLATIONS.TITLES.MY_LISTINGS]: 'எனது பட்டியல்கள்',

  // subtitles
  [TRANSLATIONS.SUB_TITLES.ADD_A_PROPERTY]: 'ஒரு சொத்தைச் சேர்க்கவும்',
  [TRANSLATIONS.SUB_TITLES.CONNECT_SMARTER]: 'சிக்கலில்லாமல் இணையுங்கள்,',
  [TRANSLATIONS.SUB_TITLES.CLOSE_FASTER]: 'vREfyd உடன் வேகமாக முடிக்கவும்.',
  [TRANSLATIONS.SUB_TITLES.AIRA_FIND]: 'நீங்கள் தேடுகிறதை கண்டுபிடிக்கவும்...',
  [TRANSLATIONS.SUB_TITLES.NO_PROPERTY]:
    'நீங்கள் இன்னும் எந்த சொத்தையும் சேர்க்கவில்லை',

  // paragraphs
  [TRANSLATIONS.PARAGRAPHS.SERVICEABLE_IN_BENGALURU]:
    'நாங்கள் தற்போது பெங்களூரில் சேவையில் உள்ளோம் மற்றும் விரைவில் மேலும் நகரங்களுக்கு விரிவுபடுத்தவுள்ளோம்!',

  [TRANSLATIONS.PARAGRAPHS.AIRA_INTRO]:
    'நான் AIRA, இந்தியாவின் முதல் AI ரியல் எஸ்டேட் முகவர். நான் பொருத்தங்களை கண்டுபிடிக்கிறேன், சொத்து விளக்கங்களை எழுதுகிறேன், மற்றும் உங்களை உதவுவேன்.',
};

export default translationTamil;
