import { TRANSLATIONS } from '../translations';

const translationHindi = {
  // titles
  [TRANSLATIONS.TITLES.HOME]: 'होम',
  [TRANSLATIONS.TITLES.PROPERTIES]: 'संपत्तियाँ',
  [TRANSLATIONS.TITLES.MESSAGES]: 'संदेश',
  [TRANSLATIONS.TITLES.AGENT_ID]: 'एजेंट आईडी',
  [TRANSLATIONS.TITLES.LOGOUT]: 'लॉगआउट',
  [TRANSLATIONS.TITLES.PROFILE]: 'प्रोफ़ाइल',
  [TRANSLATIONS.TITLES.ASK_AIRA]: 'AIRA से पूछें',
  [TRANSLATIONS.TITLES.SAVED]: 'सहेजे गए',
  [TRANSLATIONS.TITLES.UNLOCKED]: 'अनलॉक किया गया',
  [TRANSLATIONS.TITLES.MY_LISTINGS]: 'मेरी लिस्टिंग',
  [TRANSLATIONS.TITLES.LANGUAGE]: 'भाषा',
  [TRANSLATIONS.TITLES.SUBMIT]: 'जमा करें',
  [TRANSLATIONS.TITLES.CHANGE]: 'बदलें',
  [TRANSLATIONS.TITLES.BACK]: 'पीछे',
  [TRANSLATIONS.TITLES.DASHBOARD]: 'डैशबोर्ड',
  [TRANSLATIONS.TITLES.SAVE]: 'सहेजें',
  [TRANSLATIONS.TITLES.CANCEL]: 'रद्द करें',
  [TRANSLATIONS.TITLES.SHARE_FEEDBACK]: 'प्रतिक्रिया साझा करें',
  [TRANSLATIONS.TITLES.CITY]: 'शहर',
  [TRANSLATIONS.TITLES.LOCALITY]: 'इलाका',
  [TRANSLATIONS.TITLES.LOOKING_FOR]: 'क्या ढूंढ रहे हैं',
  [TRANSLATIONS.TITLES.PROPERTY_TYPE]: 'संपत्ति का प्रकार',
  [TRANSLATIONS.TITLES.BEDROOM]: 'शयनकक्ष',
  [TRANSLATIONS.TITLES.BEDROOMS]: 'शयनकक्ष',
  [TRANSLATIONS.TITLES.FILTERS]: 'फ़िल्टर',
  [TRANSLATIONS.TITLES.FURNISHING]: 'सजावट',
  [TRANSLATIONS.TITLES.BUILD_UP_AREA]: 'निर्मित क्षेत्र',
  [TRANSLATIONS.TITLES.BUDGET]: 'बजट',
  [TRANSLATIONS.TITLES.CLEAR_ALL]: 'सभी साफ़ करें',
  [TRANSLATIONS.TITLES.APPLY]: 'लागू करें',
  [TRANSLATIONS.TITLES.MIN_BUILD_UP]: 'न्यूनतम निर्मित क्षेत्र',
  [TRANSLATIONS.TITLES.MAX_BUILD_UP]: 'अधिकतम निर्मित क्षेत्र',
  [TRANSLATIONS.TITLES.FILTER]: 'फ़िल्टर',
  [TRANSLATIONS.TITLES.AMENITIES]: 'सुविधाएँ',
  [TRANSLATIONS.TITLES.NOTIFICATIONS]: 'सूचनाएँ',
  [TRANSLATIONS.TITLES.BATHROOMS]: 'बाथरूम',
  [TRANSLATIONS.TITLES.MESSAGE]: 'संदेश',
  [TRANSLATIONS.TITLES.NAME]: 'नाम',
  [TRANSLATIONS.TITLES.PHONE_NUMBER]: 'फ़ोन नंबर',
  [TRANSLATIONS.TITLES.EMAIL]: 'ईमेल',
  [TRANSLATIONS.TITLES.LANGUAGE_S]: 'भाषा(एँ)',
  [TRANSLATIONS.TITLES.REMOVE]: 'हटाएँ',
  [TRANSLATIONS.TITLES.ADDRESS]: 'पता',
  [TRANSLATIONS.TITLES.AGENT]: 'एजेंट',
  [TRANSLATIONS.TITLES.AREA]: 'क्षेत्र',
  [TRANSLATIONS.TITLES.VERIFY]: 'सत्यापित करें',
  [TRANSLATIONS.TITLES.PROCEED]: 'आगे बढ़ें',
  [TRANSLATIONS.TITLES.STATE]: 'राज्य',
  [TRANSLATIONS.TITLES.PINCODE]: 'पिनकोड',
  [TRANSLATIONS.TITLES.SELECT]: 'चुनें',
  [TRANSLATIONS.TITLES.RERA_NUMBER]: 'RERA नंबर',
  [TRANSLATIONS.TITLES.ADD_MORE_DETAILS]: 'और विवरण जोड़ें',
  [TRANSLATIONS.TITLES.RETAKE_REUPLOAD_PHOTO]:
    'फिर से लें / फोटो पुनः अपलोड करें',
  [TRANSLATIONS.TITLES.CLOSED_DEALS]: 'बंद सौदे',
  [TRANSLATIONS.TITLES.ACTIVE_LISTING]: 'सक्रिय लिस्टिंग',
  [TRANSLATIONS.TITLES.VERIFICATION]: 'सत्यापन',
  [TRANSLATIONS.TITLES.ABOUT]: 'के बारे में',
  [TRANSLATIONS.TITLES.CLOSED]: 'बंद',
  [TRANSLATIONS.TITLES.AVAILABILITY]: 'उपलब्धता',
  [TRANSLATIONS.TITLES.ACCESS]: 'पहुंच',
  [TRANSLATIONS.TITLES.TRY_AGAIN]: 'फिर से कोशिश करें',
  [TRANSLATIONS.TITLES.LATITUDE]: 'अक्षांश',
  [TRANSLATIONS.TITLES.LONGITUDE]: 'देशांतर',
  [TRANSLATIONS.TITLES.RE_VERIFY]: 'पुनः सत्यापित करें',
  [TRANSLATIONS.TITLES.HIDE]: 'छुपाएं',
  [TRANSLATIONS.TITLES.VIEW_ALL]: 'सभी देखें',
  [TRANSLATIONS.TITLES.FIND_PROPERTY]: 'संपत्ति ढूंढें',
  [TRANSLATIONS.TITLES.TAP_THE]: 'टैप करें',
  [TRANSLATIONS.TITLES.WE_FOUND]: 'हमें मिल गया',
  [TRANSLATIONS.TITLES.MATCHES_INSTANTLY]: 'तुरंत मिलान',
  [TRANSLATIONS.TITLES.WE_ARE_ON_IT]: 'हम इस पर काम कर रहे हैं!',
  [TRANSLATIONS.TITLES.SELECT_CITY]: 'शहर चुनें',
  [TRANSLATIONS.TITLES.TYPE]: 'प्रकार',
  [TRANSLATIONS.TITLES.FIND]: 'ढूंढें',
  [TRANSLATIONS.TITLES.BALCONIES]: 'बालकनी',
  [TRANSLATIONS.TITLES.PROPERTY_CATEGORY]: 'संपत्ति श्रेणी',
  [TRANSLATIONS.TITLES.OCCUPANCY_STATUS]: 'अधिभोग स्थिति',
  [TRANSLATIONS.TITLES.CARPET_AREA]: 'कालीन क्षेत्र',
  [TRANSLATIONS.TITLES.TOTAL_FLOOR]: 'कुल मंजिल',
  [TRANSLATIONS.TITLES.PROPERTY_ON_FLOOR]: 'संपत्ति की मंजिल',
  [TRANSLATIONS.TITLES.PROPERTY_FACING]: 'संपत्ति का मुख',
  [TRANSLATIONS.TITLES.BUILT_IN_YEAR]: 'निर्माण वर्ष',
  [TRANSLATIONS.TITLES.PROPERTY_NAME]: 'संपत्ति का नाम',
  [TRANSLATIONS.TITLES.LIFT]: 'लिफ्ट',
  [TRANSLATIONS.TITLES.CAR_PARKING]: 'कार पार्किंग',
  [TRANSLATIONS.TITLES.FURNISHING_TYPE]: 'सजावट का प्रकार',
  [TRANSLATIONS.TITLES.PROPERTY_VALUE]: 'संपत्ति का मूल्य',
  [TRANSLATIONS.TITLES.LOCK_IN_PERIOD]: 'लॉक-इन अवधि',
  [TRANSLATIONS.TITLES.RENT_AMOUNT]: 'किराया राशि',
  [TRANSLATIONS.TITLES.SECURITY_DEPOSIT]: 'सुरक्षा जमा',
  [TRANSLATIONS.TITLES.MAINTENANCE_AMOUNT]: 'रखरखाव राशि',
  [TRANSLATIONS.TITLES.RENT_AVAILABLE_FROM]: 'किराया उपलब्ध है',
  [TRANSLATIONS.TITLES.PROPERTY_ADDED_ON]: 'संपत्ति जोड़ी गई',
  [TRANSLATIONS.TITLES.TENANT_PREFERENCES]: 'किरायेदार प्राथमिकताएं',
  [TRANSLATIONS.TITLES.UNSAVE]: 'अनसेव करें',
  [TRANSLATIONS.TITLES.CLOSED_WITH]: 'के साथ बंद',
  [TRANSLATIONS.TITLES.CHAT_UNLOCKED]: 'चैट अनलॉक!',
  [TRANSLATIONS.TITLES.BALCONY]: 'बालकनी!',
  [TRANSLATIONS.TITLES.INTERESTING_HIGHLIGHTS]: 'रोचक हाइलाइट्स',
  [TRANSLATIONS.TITLES.ESSENTIAL_INFORMATION]: 'आवश्यक जानकारी',
  [TRANSLATIONS.TITLES.DOCUMENT]: 'दस्तावेज़',
  [TRANSLATIONS.TITLES.UNLOCK_TO_CHAT]: 'चैट करने के लिए अनलॉक करें',
  [TRANSLATIONS.TITLES.PROPERTY_REJECTED]: 'संपत्ति अस्वीकृत',
  [TRANSLATIONS.TITLES.VERIFY_NOW]: 'अभी सत्यापित करें',
  [TRANSLATIONS.TITLES.RE_VERIFY_NOW]: 'अभी पुनः सत्यापित करें',
  [TRANSLATIONS.TITLES.MARK_AS_CLOSED]: 'बंद के रूप में चिह्नित करें',
  [TRANSLATIONS.TITLES.PREVIEW_AND_PERFECT]: 'पूर्वावलोकन करें और संपूर्ण करें',
  [TRANSLATIONS.TITLES.EDIT_PROFILE]: 'प्रोफाइल संपादित करें',
  [TRANSLATIONS.TITLES.SHARE_PROFILE]: 'प्रोफाइल साझा करें',
  [TRANSLATIONS.TITLES.RE_INITIATE_VERIFICATION]: 'पुनः सत्यापन शुरू करें',
  [TRANSLATIONS.TITLES.VERIFICATION_IN_PROGRESS]: 'सत्यापन प्रक्रिया जारी है',
  [TRANSLATIONS.TITLES.VERIFICATION_REJECTED]: 'सत्यापन अस्वीकृत',
  [TRANSLATIONS.TITLES.ADDITIONAL_SERVICES]: 'अतिरिक्त सेवाएं',
  [TRANSLATIONS.TITLES.GENERATE]: 'सृजन करें',
  [TRANSLATIONS.TITLES.ADD_CITY]: 'शहर जोड़ें',
  [TRANSLATIONS.TITLES.LISTING_TYPE]: 'सूची प्रकार',
  [TRANSLATIONS.TITLES.COMMERCIAL_PARKING]: 'वाणिज्यिक पार्किंग',
  [TRANSLATIONS.TITLES.ADD_AMENITY]: 'सुविधा जोड़ें',
  [TRANSLATIONS.TITLES.ADD]: 'जोड़ें',
  [TRANSLATIONS.TITLES.SKIP]: 'छोड़ें',
  [TRANSLATIONS.TITLES.NEXT]: 'आगे',
  [TRANSLATIONS.TITLES.MY_LEADS]: 'मेरे लीड्स',
  [TRANSLATIONS.TITLES.DONE]: 'पूरा हुआ',
  [TRANSLATIONS.TITLES.WELCOME_TO]: 'स्वागत है',
  [TRANSLATIONS.TITLES.VIEW_PROFILE]: 'प्रोफ़ाइल देखें',
  [TRANSLATIONS.TITLES.DEALS_CLOSED]: 'सौदे पूरे हुए',
  [TRANSLATIONS.TITLES.YEARS]: 'वर्ष',
  [TRANSLATIONS.TITLES.FRESHER]: 'नवसिखिया',
  [TRANSLATIONS.TITLES.SPECIALIZATION]: 'विशेषज्ञता',
  [TRANSLATIONS.TITLES.AGENTS]: 'एजेंट्स',
  [TRANSLATIONS.TITLES.TOP]: 'शीर्ष',
  [TRANSLATIONS.TITLES.SHARE_NOW]: 'अभी साझा करें',
  [TRANSLATIONS.TITLES.SEARCH]: 'खोजें',
  [TRANSLATIONS.TITLES.BUY_PURCHASE]: 'खरीदें/संपत्ति खरीदें',
  [TRANSLATIONS.TITLES.VERIFYING_OTP]: 'OTP सत्यापित किया जा रहा है',
  [TRANSLATIONS.TITLES.RESEND_CODE]: 'कोड पुनः भेजें',
  [TRANSLATIONS.TITLES.CONGRATULATIONS]: 'बधाई हो',
  [TRANSLATIONS.TITLES.SEARCH_MESSAGES]: 'संदेश खोजें',
  [TRANSLATIONS.TITLES.UPLOAD_DOC]: 'दस्तावेज़ अपलोड करें',
  [TRANSLATIONS.TITLES.BROKERAGE]: 'ब्रोकर कमीशन',
  [TRANSLATIONS.TITLES.UNLOCKS]: 'अनलॉक',
  [TRANSLATIONS.TITLES.LOCATION]: 'स्थान',
  [TRANSLATIONS.TITLES.INFO]: 'जानकारी',
  [TRANSLATIONS.TITLES.COMPANY]: 'कंपनी',
  [TRANSLATIONS.TITLES.DESCRIPTION]: 'विवरण',
  [TRANSLATIONS.TITLES.SAVING_CHANGES]: 'सहेजा जा रहा है...',
  [TRANSLATIONS.TITLES.REGENERATE]: 'पुनः उत्पन्न करें',
  [TRANSLATIONS.TITLES.REGENERATING_DESCRIPTION]:
    'विवरण पुनः उत्पन्न किया जा रहा है...',
  [TRANSLATIONS.TITLES.ENTER_DESCRIPTION]: 'विवरण दर्ज करें',

  // sub titles
  [TRANSLATIONS.SUB_TITLES.PROPERTY_VERIFICATION]: 'संपत्ति सत्यापन',
  [TRANSLATIONS.SUB_TITLES.SELECTED_COORDINATES]: 'चयनित निर्देशांक',
  [TRANSLATIONS.SUB_TITLES.LOCATION_PIN_REQUIRED]: 'स्थान पिन आवश्यक',
  [TRANSLATIONS.SUB_TITLES.PAGE_NOT_FOUND]: 'पृष्ठ नहीं मिला',
  [TRANSLATIONS.SUB_TITLES.FIND_CONNECT_CLOSE_FASTER]:
    'खोजें, जुड़ें, तेजी से बंद करें।',
  [TRANSLATIONS.SUB_TITLES.PASTE_PROPERTY_DETAILS_HERE]:
    'अपने संपत्ति विवरण यहाँ पेस्ट करें',
  [TRANSLATIONS.SUB_TITLES.AUTO_FILL_WITH]: 'स्वतः भरें',
  [TRANSLATIONS.SUB_TITLES.FILTER_S_ADDED]: 'फ़िल्टर(एँ) जोड़े गए',
  [TRANSLATIONS.SUB_TITLES.AREAS_OF_OPERATION]: 'संचालन के क्षेत्र',
  [TRANSLATIONS.SUB_TITLES.LEGAL_BUSINESS_NAME]: 'कानूनी व्यवसाय नाम',
  [TRANSLATIONS.SUB_TITLES.BRAND_NAME]: 'ब्रांड नाम',
  [TRANSLATIONS.SUB_TITLES.GST_NUMBER]: 'GST नंबर',
  [TRANSLATIONS.SUB_TITLES.SOCIAL_LINKS]: 'सोशल लिंक',
  [TRANSLATIONS.SUB_TITLES.INSTAGRAM_PAGE_LINK]: 'इंस्टाग्राम पेज लिंक',
  [TRANSLATIONS.SUB_TITLES.FACEBOOK_PAGE_LINK]: 'फेसबुक पेज लिंक',
  [TRANSLATIONS.SUB_TITLES.LINKEDIN_PAGE_LINK]: 'लिंक्डइन पेज लिंक',
  [TRANSLATIONS.SUB_TITLES.YOUTUBE_PAGE_LINK]: 'यूट्यूब पेज लिंक',
  [TRANSLATIONS.SUB_TITLES.PHONE_NUMBER_OF_THE_AGENT]: 'एजेंट का फ़ोन नंबर',
  [TRANSLATIONS.SUB_TITLES.ADD_A_PROPERTY]: 'संपत्ति जोड़ें',
  [TRANSLATIONS.SUB_TITLES.CONNECT_SMARTER]: 'चालाकी से जुड़ें,',
  [TRANSLATIONS.SUB_TITLES.CLOSE_FASTER]: 'vREfyd के साथ तेजी से बंद करें।',
  [TRANSLATIONS.SUB_TITLES.AIRA_FIND]: 'जो आप ढूंढ रहे हैं वह FIND करें...',
  [TRANSLATIONS.SUB_TITLES.NO_PROPERTY]:
    'आपने अभी तक कोई संपत्ति नहीं जोड़ी है',
  [TRANSLATIONS.SUB_TITLES.NOT_JUST_PART_OF_SYSTEM]:
    'आप सिर्फ सिस्टम का हिस्सा नहीं हैं।',
  [TRANSLATIONS.SUB_TITLES.YOU_ARE_THE_SYSTEM]: 'आप सिस्टम हैं!',
  [TRANSLATIONS.SUB_TITLES.JOIN_COMMUNITY]:
    'भारत के सबसे भरोसेमंद रियल एस्टेट समुदाय में शामिल हों',
  [TRANSLATIONS.SUB_TITLES.WELCOME_TO_VREFYD]: 'vREfyd में आपका स्वागत है',
  [TRANSLATIONS.SUB_TITLES.LETS_BEGIN]: 'अपने फ़ोन नंबर से शुरू करें',
  [TRANSLATIONS.SUB_TITLES.OTP_NOT_RECEIVED]:
    'OTP प्राप्त नहीं हुआ? फिर से भेजें',
  [TRANSLATIONS.SUB_TITLES.ENTER_VERIFICATION_CODE]:
    'अपने फ़ोन पर भेजे गए सत्यापन कोड को दर्ज करें',
  [TRANSLATIONS.SUB_TITLES.PLEASE_CHECK_WHATSAPP_OR_SMS]:
    'कृपया सत्यापन कोड के लिए WhatsApp/SMS जांचें',
  [TRANSLATIONS.SUB_TITLES.LIST_MY_PROPERTY]: 'मेरी संपत्ति सूचीबद्ध करें',
  [TRANSLATIONS.SUB_TITLES.CHOOSE_LANGUAGE]: 'भाषा चुनें',
  [TRANSLATIONS.SUB_TITLES.LOADING_NOTIFICATIONS]: 'सूचनाएँ लोड हो रही हैं....',
  [TRANSLATIONS.SUB_TITLES.NO_NOTIFICATION_FOUND]: 'कोई सूचना नहीं मिली',
  [TRANSLATIONS.SUB_TITLES.BY_SUBMITTING]: 'जमा करके आप हमारी ',
  [TRANSLATIONS.SUB_TITLES.PRIVACY_POLICY]: 'गोपनीयता नीति',
  [TRANSLATIONS.SUB_TITLES.AND]: 'और',
  [TRANSLATIONS.SUB_TITLES.TERMS_OF_USE]: 'उपयोग की शर्तें',
  [TRANSLATIONS.SUB_TITLES.LETS_GET_TO_KNOW_YOU]: 'आइए आपको जानें',
  [TRANSLATIONS.SUB_TITLES.YEARS_OF_EXPERIENCE]: 'अनुभव के वर्ष',
  [TRANSLATIONS.SUB_TITLES.HIGHLIGHT_YOUR_SUPERPOWERS]:
    'अपनी महाशक्तियों को उजागर करें',
  [TRANSLATIONS.SUB_TITLES.MY_SPECIALIZATION]: 'मेरी विशेषज्ञता',
  [TRANSLATIONS.SUB_TITLES.OTHER_SERVICES]: 'अन्य सेवाएँ',
  [TRANSLATIONS.SUB_TITLES.SPEAK_THEIR_LANGUAGE]: 'उनकी भाषा बोलें',
  [TRANSLATIONS.SUB_TITLES.DEFINE_YOUR_AREA_OF_OPERATION]:
    'अपने संचालन के क्षेत्र को परिभाषित करें',
  [TRANSLATIONS.SUB_TITLES.ADD_ANOTHER_CITY]: 'एक और शहर जोड़ें',
  [TRANSLATIONS.SUB_TITLES.SHOWCASE_YOUR_BRAND]:
    'अपने ब्रांड को प्रदर्शित करें',
  [TRANSLATIONS.SUB_TITLES.BUSINESS_DETAILS]: 'व्यवसाय विवरण',
  [TRANSLATIONS.SUB_TITLES.IMAGE_OF_VISITING_CARD]: 'विज़िटिंग कार्ड की छवि',
  [TRANSLATIONS.SUB_TITLES.IMAGE_OF_SHOP_ESTABLISHMENT]:
    'दुकान / प्रतिष्ठान की छवि',
  [TRANSLATIONS.SUB_TITLES.COMPANY_PAN_CARD_GST_CERTIFICATE]:
    'कंपनी पैन कार्ड / GST प्रमाणपत्र',
  [TRANSLATIONS.SUB_TITLES.VERIFIED_AGENT_VERIFICATION]:
    'सत्यापित एजेंट सत्यापन',
  [TRANSLATIONS.SUB_TITLES.FASTEST_IMMEDIATE_VERIFICATION]:
    'सबसे तेज - तत्काल सत्यापन',
  [TRANSLATIONS.SUB_TITLES.BUSINESS_VERIFICATION]: 'व्यवसाय सत्यापन',
  [TRANSLATIONS.SUB_TITLES.RERA_VERIFICATION]: 'RERA सत्यापन',
  [TRANSLATIONS.SUB_TITLES.COMMUNITY_VERIFICATION]: 'समुदाय सत्यापन',
  [TRANSLATIONS.SUB_TITLES.UPLOAD_DOCUMENTS]: 'दस्तावेज़ अपलोड करें',
  [TRANSLATIONS.SUB_TITLES.LETS_CAPTURE_YOUR_BEST_LOOK]:
    'आइए आपकी सबसे अच्छी छवि कैप्चर करें',
  [TRANSLATIONS.SUB_TITLES.POSITION_YOUR_FACE_IN_THE_SQUARE_BELOW]:
    'नीचे दिए गए वर्ग में अपना चेहरा रखें',
  [TRANSLATIONS.SUB_TITLES.SAMPLE_PROFILE_PREVIEW]:
    'नमूना प्रोफ़ाइल पूर्वावलोकन',
  [TRANSLATIONS.SUB_TITLES.SAMPLE_PROFILE_APPEARS]:
    'नमूना प्रोफ़ाइल – यह आपकी प्रोफ़ाइल कैसे दिखती है',
  [TRANSLATIONS.SUB_TITLES.BACK_TO_HOME]: 'घर वापस जाएँ',
  [TRANSLATIONS.SUB_TITLES.DEAL_CLOSED_OUTSIDE]: 'सौदा बाहर बंद किया गया था',
  [TRANSLATIONS.SUB_TITLES.VERIFICATION_INITIATED]: 'सत्यापन शुरू किया गया!',
  [TRANSLATIONS.SUB_TITLES.PROPERTY_VERIFICATION_FAILED]:
    'संपत्ति सत्यापन विफल',
  [TRANSLATIONS.SUB_TITLES.DUPLICATE_LISTING_DETECTED]:
    'डुप्लिकेट लिस्टिंग का पता चला',
  [TRANSLATIONS.SUB_TITLES.IMAGE_NOT_AVAILABLE]: 'छवि उपलब्ध नहीं है',
  [TRANSLATIONS.SUB_TITLES.SAVE_FAV_PROP]: 'अपनी पसंदीदा संपत्तियों को सहेजें',
  [TRANSLATIONS.SUB_TITLES.TAP_TO_SAVE]:
    'किसी भी संपत्ति को अपनी सहेजी गई संपत्तियों में जोड़ने के लिए टैप करें',
  [TRANSLATIONS.SUB_TITLES.PERSONALIZE_SEARCH]:
    'अपनी खोज को व्यक्तिगत बनाने के लिए अधिक फ़िल्टर लागू करें',
  [TRANSLATIONS.SUB_TITLES.SEARCH_FOR_LOCALITY]: 'इलाके के लिए खोजें',
  [TRANSLATIONS.SUB_TITLES.PURCHASE_RENT]: 'खरीदें/किराए पर लें',
  [TRANSLATIONS.SUB_TITLES.ADVANCE_FILTERS]: 'उन्नत फ़िल्टर',
  [TRANSLATIONS.SUB_TITLES.PROPERTY_SALE_TYPE]: 'संपत्ति बिक्री प्रकार',
  [TRANSLATIONS.SUB_TITLES.CHAT_TO_KNOW_MORE]:
    'संपत्ति के बारे में अधिक जानने के लिए चैट करें',
  [TRANSLATIONS.SUB_TITLES.HOUSE_FLAT_BLOCK_NUMBER]: 'घर / फ्लैट / ब्लॉक नंबर',
  [TRANSLATIONS.SUB_TITLES.APARTMENT_SOCIETY]: 'अपार्टमेंट / सोसाइटी',
  [TRANSLATIONS.SUB_TITLES.ROAD_AREA]: 'सड़क / क्षेत्र',
  [TRANSLATIONS.SUB_TITLES.BEDROOMS_ARE_REQUIRED]: 'शयनकक्ष आवश्यक हैं',
  [TRANSLATIONS.SUB_TITLES.BALCONIES_ARE_REQUIRED]: 'बालकनी आवश्यक हैं',
  [TRANSLATIONS.SUB_TITLES.BATHROOMS_ARE_REQUIRED]: 'बाथरूम आवश्यक हैं',
  [TRANSLATIONS.SUB_TITLES.LISTING_TYPE_IS_REQUIRED]:
    'लिस्टिंग प्रकार आवश्यक है',
  [TRANSLATIONS.SUB_TITLES.PROPERTY_CATEGORY_IS_REQUIRED]:
    'संपत्ति श्रेणी आवश्यक है',
  [TRANSLATIONS.SUB_TITLES.PROPERTY_TYPE_IS_REQUIRED]:
    'संपत्ति प्रकार आवश्यक है',
  [TRANSLATIONS.SUB_TITLES.FURNISHING_TYPE_IS_REQUIRED]:
    'फर्निशिंग प्रकार आवश्यक है',
  [TRANSLATIONS.SUB_TITLES.OCCUPANCY_STATUS_IS_REQUIRED]:
    'आवास की स्थिति आवश्यक है',
  [TRANSLATIONS.SUB_TITLES.BUILD_UP_AREA_IS_REQUIRED]:
    'निर्मित क्षेत्र आवश्यक है',
  [TRANSLATIONS.SUB_TITLES.CARPET_AREA_IS_REQUIRED]: 'कालीन क्षेत्र आवश्यक है',
  [TRANSLATIONS.SUB_TITLES.NO_PROPERTIES_YET]: 'अभी तक कोई संपत्तियां नहीं हैं',
  [TRANSLATIONS.SUB_TITLES.NO_CLOSED_PROPERTIES_YET]:
    'अभी तक कोई बंद संपत्तियां नहीं हैं',
  [TRANSLATIONS.SUB_TITLES.NO_LISTING_AVAILABLE]:
    'इस समय कोई सूची उपलब्ध नहीं है।',
  [TRANSLATIONS.SUB_TITLES.NO_DEALS_CLOSED]:
    'अभी तक कोई सौदे पूरे नहीं हुए हैं। जुड़े रहें!',
  [TRANSLATIONS.SUB_TITLES.PERSONAL_DETAILS]: 'व्यक्तिगत विवरण',
  [TRANSLATIONS.SUB_TITLES.WELCOME_GLAD_TO_HAVE_YOU_ABOARD]:
    'स्वागत है! हमें आपके साथ जुड़कर खुशी है।',
  [TRANSLATIONS.SUB_TITLES.TELL_US_YOUR_NAME]: 'हमें अपना नाम बताएं',
  [TRANSLATIONS.SUB_TITLES.YOUR_PREFERRED_EMAIL_ADDRESS]:
    'आपका पसंदीदा ईमेल पता',
  [TRANSLATIONS.SUB_TITLES.TRANSACTIONAL_MESSAGES_WHATSAPP]:
    'लेन-देन से संबंधित संदेश और अपडेट आपके व्हाट्सएप पर भेजे जाएंगे',
  [TRANSLATIONS.SUB_TITLES.LETS_BEGIN_WITH_PHONE_NUMBER]:
    'आइए शुरू करें आपके फोन नंबर से',
  [TRANSLATIONS.SUB_TITLES.NO_CONVERSATIONS_AVAILABLE]:
    'कोई बातचीत उपलब्ध नहीं है।',
  [TRANSLATIONS.SUB_TITLES.NO_SPECIALIZATIONS_ADDED]:
    'कोई विशेषताएं जोड़ी नहीं गई हैं',
  [TRANSLATIONS.SUB_TITLES.MATCHING_LISTINGS]: 'मिलते-जुलते लिस्टिंग',
  [TRANSLATIONS.SUB_TITLES.ENTER_YRS_OF_EXP]: 'अनुभव के वर्ष दर्ज करें',

  // paragraphs
  [TRANSLATIONS.PARAGRAPHS.SERVICEABLE_IN_BENGALURU]:
    'हम वर्तमान में बेंगलुरु में सेवा योग्य हैं और जल्द ही अधिक शहरों में विस्तार करेंगे!',
  [TRANSLATIONS.PARAGRAPHS.AIRA_INTRO]:
    'मैं AIRA हूँ, भारत की पहली AI रियल एस्टेट एजेंट। मैं मैच ढूंढती हूँ, संपत्ति विवरण लिखती हूँ, और आपके काम को आसान बनाने के लिए टिप्स साझा करती हूँ।',
  [TRANSLATIONS.PARAGRAPHS.ADD_OTHER_DETAILS_FOR_SETUP]:
    'मेरे पास आपका नाम और फ़ोन नंबर है। बस अन्य विवरण जोड़ें, और मैं आपकी प्रोफ़ाइल को तुरंत सेट कर दूंगी!',
  [TRANSLATIONS.PARAGRAPHS.QUCIK_AND_EASY_SETUP]:
    'त्वरित और आसान सेटअप आपके व्यवसाय को बढ़ाने पर ध्यान केंद्रित करने के लिए समय बचाता है।',
  [TRANSLATIONS.PARAGRAPHS.SHARE_YOUR_TOP_SKILLS]:
    'अपने शीर्ष कौशल और शक्तियों को साझा करें। मैं उन्हें प्रदर्शित करूंगी ताकि यह दिखाया जा सके कि आप रियल एस्टेट में सर्वश्रेष्ठ क्यों हैं।',
  [TRANSLATIONS.PARAGRAPHS.ATTRACT_MORE_LEADS]:
    'अधिक लीड आकर्षित करने के लिए स्पष्ट रूप से प्रस्तुत करें कि आपको क्या विशिष्ट बनाता है।',
  [TRANSLATIONS.PARAGRAPHS.TELL_ME_LANGUAGE]:
    'मुझे बताएं कि आप कौन सी भाषाएँ धाराप्रवाह बोलते हैं। यह आपको व्यापक दर्शकों तक पहुंचने में मदद करता है।',
  [TRANSLATIONS.PARAGRAPHS.EXPAND_YOUR_REACH]:
    'स्थानीय क्षेत्रों से परे अपनी पहुंच का विस्तार करें और विविध ग्राहकों से जुड़ें।',
  [TRANSLATIONS.PARAGRAPHS.SELECT_THE_CITIES]:
    'उन शहरों और क्षेत्रों का चयन करें जिनमें आप संचालित होते हैं। यह लोगों को आपको उन जगहों पर खोजने में मदद करता है जहां यह सबसे अधिक मायने रखता है।',
  [TRANSLATIONS.PARAGRAPHS.TARGET_LOCAL_MARKET]:
    'अपने स्थानीय बाजार को प्रभावी ढंग से लक्षित करें और अपने व्यवसाय के अवसरों को बढ़ाएं।',
  [TRANSLATIONS.PARAGRAPHS.AVAILABLE_IN_FIVE_CITIES]:
    'हम वर्तमान में इन पांच शहरों में उपलब्ध हैं और जल्द ही विस्तार करने की उम्मीद करते हैं',
  [TRANSLATIONS.PARAGRAPHS.ADD_BUSSINESS_AND_SOCIAL_MEDIA_DETAILS]:
    'अपने व्यवसाय विवरण और सोशल मीडिया लिंक जोड़ें। मैं उन्हें आपकी पेशेवर छवि को बढ़ावा देने के लिए एकीकृत करूंगी।',
  [TRANSLATIONS.PARAGRAPHS.ENCHANE_ONLINE_PRESENCE]:
    'अपनी ऑनलाइन उपस्थिति को बढ़ाएं और ब्रांडिंग प्रयासों पर पैसे बचाएं।',
  [TRANSLATIONS.PARAGRAPHS.RERA_VERIFICATION_NOTE]:
    'vREfyd पर, हम आपकी गोपनीयता को प्राथमिकता देते हैं और सभी दस्तावेज़ों को सुरक्षित रूप से प्रबंधित करते हैं और RERA सत्यापन प्रक्रिया के दौरान आपके डेटा की सुरक्षा करते हैं।',
  [TRANSLATIONS.PARAGRAPHS.DOCUMENT_VERIFICATION_NOTE]:
    'vREfyd पर, आपकी गोपनीयता हमारी प्राथमिकता है। हम सुनिश्चित करते हैं कि आपके सभी दस्तावेज़ सुरक्षित रूप से प्रबंधित हों, हर कदम पर आपके डेटा की सुरक्षा करते हुए।',
  [TRANSLATIONS.PARAGRAPHS.VREFYD_AGENT_DETAILS]:
    'कृपया एक vREfyd एजेंट का विवरण प्रदान करें',
  [TRANSLATIONS.PARAGRAPHS.COMMUNITY_DETAILS]:
    'कृपया अपने समुदाय का विवरण प्रदान करें',
  [TRANSLATIONS.PARAGRAPHS.PROVIDE_RERA_DETAILS]:
    'कृपया अपने RERA विवरण प्रदान करें',
  [TRANSLATIONS.PARAGRAPHS.TWO_AGENT_REFERENCE]:
    'कृपया दो एजेंटों के संदर्भ प्रदान करें जो हमें यह सत्यापित करने में मदद कर सकते हैं कि आप एक एजेंट हैं',
  [TRANSLATIONS.PARAGRAPHS.KICK_THINGS_OFF]:
    'एक शानदार फोटो के साथ शुरुआत करें! एक पेशेवर तस्वीर अपलोड करें जो आपको दृष्टिकोण और यादगार बनाती है।',
  [TRANSLATIONS.PARAGRAPHS.INSPIRATION_EXAMPLE]:
    'तुरंत बाहर खड़े हों और अधिक रुचि आकर्षित करें—कोई अतिरिक्त मार्केटिंग की आवश्यकता नहीं! प्रेरणा के लिए नीचे दिए गए उदाहरण को देखें।',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_IMAGE_APPEARANCE]:
    'यह आपकी प्रोफ़ाइल छवि कैसे दिखती है। सबसे अच्छा प्रभाव के लिए एक गुणवत्ता वाली तस्वीर अपलोड/क्लिक करें।',
  [TRANSLATIONS.PARAGRAPHS.ENCRYPT_DATA]:
    'आपके द्वारा साझा किया गया डेटा एन्क्रिप्ट किया जाएगा, सुरक्षित रूप से संग्रहीत किया जाएगा, और केवल आपकी पहचान सत्यापित करने के लिए उपयोग किया जाएगा।',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_SHINE]:
    'आइए शुरू करें और आपकी प्रोफ़ाइल को चमकाएं! प्रेरणा के लिए नीचे दिए गए उदाहरण को देखें।',
  [TRANSLATIONS.PARAGRAPHS.START_ADDING_PROPERTIES]:
    'अपनी लिस्टिंग को संभावित ग्राहकों को दिखाने के लिए अब संपत्तियाँ जोड़ना शुरू करें।',
  [TRANSLATIONS.PARAGRAPHS.EYE_CATCHING_PROFILE_NOTE]:
    'मेरी मदद से, आप एक पेशेवर और आकर्षक प्रोफ़ाइल बनाएंगे जो अधिक लीड आकर्षित करती है, आपके पैसे बचाती है, और आपके रियल एस्टेट व्यवसाय को फलने-फूलने में मदद करती है।',
  [TRANSLATIONS.PARAGRAPHS.SIMPLY_TYPE_PROP_DETAILS_FOR_AUTOFILL]:
    'बस टाइप करें या अपने WhatsApp संदेशों से विवरण साझा करें, और हम आपकी जानकारी को आसानी से स्वतः भर देंगे।',
  [TRANSLATIONS.PARAGRAPHS.PROPERTIES_TRUSTED_COMMUNITY]:
    'संपत्तियाँ, भरोसेमंद समुदाय, और त्वरित, सुरक्षित सौदों के लिए AI टूल।',
  [TRANSLATIONS.PARAGRAPHS.BETA_VERSION_NOTE]:
    'आप वर्तमान में एक आंतरिक बीटा संस्करण का उपयोग कर रहे हैं',
  [TRANSLATIONS.PARAGRAPHS.HEAVY_LOAD_NOTE]:
    'हम इस समय कुछ भारी लोड का सामना कर रहे हैं और दबाव महसूस कर रहे हैं। थोड़ा इंतजार करें, और फिर आप फिर से कोशिश कर सकते हैं।',
  [TRANSLATIONS.PARAGRAPHS.CANNOT_FIND_PAGE]:
    'क्षमा करें, हम आपके द्वारा खोजे जा रहे पृष्ठ को नहीं ढूंढ पा रहे हैं।',
  [TRANSLATIONS.PARAGRAPHS.DROP_THE_MAP_PIN]:
    'कृपया अपने स्थान की पुष्टि करने के लिए मानचित्र पर पिन ड्रॉप करें।',
  [TRANSLATIONS.PARAGRAPHS.DEAL_CLOSED_SUCCESSFULLY]:
    'सौदा सफलतापूर्वक बंद किया गया।',
  [TRANSLATIONS.PARAGRAPHS.NEED_TO_CONFIRM_THE_DEAL_CLOSED_WITH]:
    'सौदा सफलतापूर्वक बंद किया गया।',
  [TRANSLATIONS.PARAGRAPHS.USER_UNLOCKED_PROPERTY]:
    'सौदा सफलतापूर्वक बंद किया गया।',
  [TRANSLATIONS.PARAGRAPHS.SIT_BACK_AND_RELAX]:
    'आराम से बैठें और relax करें, संपत्ति के सत्यापित होने पर हम आपको सूचित करेंगे।',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_ALREADY_LISTED]:
    'ऐसा लगता है कि यह संपत्ति पहले से ही सूचीबद्ध है। फिर से सबमिट करने से पहले मौजूदा लिस्टिंग की जांच करें।',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_VERIFICATION_EXPIRES_IN]:
    'संपत्ति सत्यापन की समय सीमा समाप्त हो रही है',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_CANNOT_VERIFIED]:
    'हमें खेद है, आपकी संपत्ति का सत्यापन नहीं हो सका।',
  [TRANSLATIONS.PARAGRAPHS.NO_UNLOCK_PROPS_YET]:
    'अभी तक कोई अनलॉक संपत्ति नहीं है। अधिक लीड्स एक्सप्लोर करने का समय आ गया है!',
  [TRANSLATIONS.PARAGRAPHS.NO_PERFECT_MATCH_YET]:
    'अभी तक कोई सही मैच नहीं मिला है, लेकिन चिंता न करें, हमने अपने पूरे नेटवर्क को सतर्क कर दिया है!',
  [TRANSLATIONS.PARAGRAPHS.FIRST_TO_KNOW]:
    'जैसे ही कुछ आदर्श सामने आएगा, आप सबसे पहले जानेंगे।',
  [TRANSLATIONS.PARAGRAPHS.FAILURE_TO_GUIDELINES]:
    '*इन दिशानिर्देशों का पालन न करने पर सत्यापन विफल हो जाएगा।',
  [TRANSLATIONS.PARAGRAPHS.COMPLETE_YOUR_PROFILE_FOR_PROP_VER]:
    'संपत्ति सत्यापन प्रक्रिया शुरू करने के लिए कृपया अपनी प्रोफाइल सत्यापन पूरी करें।',
  [TRANSLATIONS.PARAGRAPHS.VERIFICATION_PENDING_NOTE]:
    'सत्यापन लंबित है। हम आपको स्थिति के बारे में सूचित करेंगे।',
  [TRANSLATIONS.PARAGRAPHS.VERIFICATION_IN_PROGRESS_NOTE]:
    'आपकी प्रोफाइल सत्यापन प्रक्रिया जारी है, प्रोफाइल सत्यापित होने पर हम आपको सूचित करेंगे।',
  [TRANSLATIONS.PARAGRAPHS.QUICK_LOOK_PROFILE]:
    'लाइव होने से पहले अपनी प्रोफ़ाइल पर एक त्वरित नज़र डालें। यदि आप कोई बदलाव चाहते हैं तो मुझे बताएं।',
  [TRANSLATIONS.PARAGRAPHS.ENSURE_PROFILE_PERFECTION]:
    'सुनिश्चित करें कि आपकी प्रोफ़ाइल पूरी तरह से सही दिखे, जिससे भविष्य में समायोजन की आवश्यकता न पड़े।',
  [TRANSLATIONS.PARAGRAPHS.PREFILLED_DETAILS_AIRA]:
    'मैंने पहले साझा की गई जानकारी के आधार पर आपके लिए विवरण पहले से भर दिए हैं। एक बार जल्दी से देख लें और जो फ़ील्ड खाली रह गई हैं, उन्हें भरें!',
  [TRANSLATIONS.PARAGRAPHS.PROPERTIES_YOU_RE_INTERESTED_IN]:
    'आप जिन संपत्तियों में रुचि रखते हैं, वे यहां दिखाई जाती हैं।',
  [TRANSLATIONS.PARAGRAPHS.CHAT_WITH_SOMEONE]:
    'किसी से बातचीत करना अच्छा लगता है।',
  [TRANSLATIONS.PARAGRAPHS.PICK_A_PERSON]:
    'बाएँ मेनू से किसी व्यक्ति को चुनें और अपनी बातचीत शुरू करें।',
  [TRANSLATIONS.PARAGRAPHS.LEAD_INTERESTED]:
    'आपकी संपत्ति में रुचि रखने वाले लीड्स यहां दिखाए जाते हैं।',
  [TRANSLATIONS.PARAGRAPHS.FOUND_A_PROP_MATCH]:
    'मुझे एक संपत्ति मिली है जो आपकी खोज से मेल खाती है। यहाँ वह शीर्ष एजेंट हैं जिन्होंने आपकी खोज मानदंडों को पूरा करने वाली संपत्तियाँ सूचीबद्ध की हैं।',
  [TRANSLATIONS.PARAGRAPHS.COULD_NOT_FIND_ANY_PROP]:
    'मुझे अभी आपकी खोज से मेल खाने वाली कोई संपत्ति नहीं मिली, लेकिन चिंता न करें! मैंने पहले ही हमारे एजेंट नेटवर्क को सतर्क कर दिया है, और जैसे ही कुछ उपलब्ध होगा, मैं आपको तुरंत सूचित करूंगा।',
  [TRANSLATIONS.PARAGRAPHS.FOUND_SEVERAL_PROP_MATCH]:
    'मुझे कई संपत्तियाँ मिली हैं जो आपकी खोज से मेल खाती हैं। यहाँ वे',
  [TRANSLATIONS.PARAGRAPHS.WHO_HAVE_LISTED]:
    'हैं जिन्होंने आपकी खोज से मेल खाने वाली संपत्तियाँ सूचीबद्ध की हैं।',
  [TRANSLATIONS.PARAGRAPHS.SHARE_YOUR_PREFERRED_LOCALITY]:
    'अपना पसंदीदा स्थान, बजट, या कोई अन्य विवरण साझा करें, और मैं आपको तुरंत सही एजेंट से जोड़ दूंगा!',
  [TRANSLATIONS.PARAGRAPHS.LOOKING_FOR_SOMETHING_SPECIFIC]:
    'क्या आप कुछ विशिष्ट खोज रहे हैं?',
  [TRANSLATIONS.PARAGRAPHS.BUT_WE_HAVE_ALERTED_OUR_NETWORK]:
    'लेकिन हमने अपने नेटवर्क को सतर्क कर दिया है। सही संपत्ति उपलब्ध होते ही आपको सूचित किया जाएगा!',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_PREVIEW_AIRA_TITLE]:
    'नमूना प्रोफ़ाइल – इस तरह आपकी प्रोफ़ाइल दिखाई देगी',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_PREVIEW_AIRA_DESCRIPTION]:
    'मेरी मदद से, आप एक पेशेवर और आकर्षक प्रोफ़ाइल बना सकते हैं जो अधिक लीड आकर्षित करती है, आपका पैसा बचाती है, और आपके रियल एस्टेट व्यवसाय को बढ़ावा देती है।',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_PREVIEW_AIRA_NOTE]:
    'आइए शुरुआत करें और आपकी प्रोफ़ाइल को चमकदार बनाएं! प्रेरणा के लिए नीचे दिए गए उदाहरण को देखें।',
  [TRANSLATIONS.PARAGRAPHS.CHECK_VERIFICATION_CODE]:
    'कृपया सत्यापन कोड के लिए व्हाट्सएप/एसएमएस जांचें।',
  [TRANSLATIONS.PARAGRAPHS.LOGIN_SUCCESSFUL]: 'आपका लॉगिन सफल रहा।',
  [TRANSLATIONS.PARAGRAPHS.TELL_ME_ABOUT_PROPERTY_INVESTMENT]:
    'मुझे संपत्ति निवेश के बारे में बताएं।',
  [TRANSLATIONS.PARAGRAPHS.NEED_ADVICE_ON_BUYING]:
    'मुझे घर खरीदने पर सलाह चाहिए।',
  [TRANSLATIONS.PARAGRAPHS.HOW_CAN_I_EARN_MORE_RENTAL_INCOME]:
    'मैं अधिक किराया आय कैसे कमा सकता हूँ?',
  [TRANSLATIONS.PARAGRAPHS.WHAT_ARE_THE_BEST_AREAS]:
    'रियल एस्टेट में निवेश करने के लिए सबसे अच्छे क्षेत्र कौन से हैं?',
  [TRANSLATIONS.PARAGRAPHS.BETA_VERSION_FEEDBACK_NOTE]:
    'आप बीटा संस्करण का उपयोग कर रहे हैं। प्रतिक्रियाओं पर थंब्स-अप या थंब्स-डाउन देकर AIRA को बेहतर बनाने में मदद करें।',

  [TRANSLATIONS.PARAGRAPHS.HANG_TIGHT]:
    'थोड़ा इंतजार करें! मैं आपकी संपत्ति सूची बनाने की तैयारी कर रहा हूँ',
  [TRANSLATIONS.PARAGRAPHS.ANALYSING_YOUR_INPUTS]:
    'सही संपत्ति विवरण तैयार करने के लिए आपके इनपुट का विश्लेषण कर रहा हूँ',
  [TRANSLATIONS.PARAGRAPHS.OPTIMIZING_LISTING]:
    'सही खरीदारों और किरायेदारों को आकर्षित करने के लिए आपकी सूची का अनुकूलन कर रहा हूँ',
  [TRANSLATIONS.PARAGRAPHS.HIGHLIGHTING_FEATURES]:
    'आपकी संपत्ति को सबसे अलग दिखाने के लिए प्रमुख विशेषताओं को उजागर कर रहा हूँ',
  [TRANSLATIONS.PARAGRAPHS.ALMOST_VERIFYING_DETAILS]:
    'लगभग हो गया! अंतिम टच के लिए आपके विवरण की पुष्टि कर रहा हूँ',
  [TRANSLATIONS.PARAGRAPHS.AT_LEAST_ONE_FILE_REQUIRED]:
    'कम से कम एक फ़ाइल आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_PINCODE]:
    'कृपया एक मान्य पिनकोड दर्ज करें',
  [TRANSLATIONS.PARAGRAPHS.PINCODE_REQUIRED]: 'पिनकोड आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.PIN_LOCATION]: 'मानचित्र पर स्थान पिन करें',
  [TRANSLATIONS.PARAGRAPHS.ROAD_AREA_REQUIRED]: 'सड़क या क्षेत्र आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.LOCALITY_REQUIRED]: 'स्थान आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.CITY_REQUIRED]: 'शहर आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.STATE_REQUIRED]: 'राज्य आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.APARTMENT_REQUIRED]:
    'अपार्टमेंट या सोसाइटी का नाम आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.HOUSE_NO_REQUIRED]: 'मकान नंबर आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.BUILT_UP_AREA_MUST_BE_A_NUMBER]:
    'निर्मित क्षेत्र एक संख्या होनी चाहिए',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_BUILT_UP_AREA]:
    'कृपया मान्य निर्मित क्षेत्र दर्ज करें',
  [TRANSLATIONS.PARAGRAPHS.CARPET_AREA_MUST_BE_A_NUMBER]:
    'कालीन क्षेत्र एक संख्या होनी चाहिए',
  [TRANSLATIONS.PARAGRAPHS.CARPET_AREA_MUST_BE_SMALLER_THAN_BUILT_UP_AREA]:
    'कालीन क्षेत्र निर्मित क्षेत्र से छोटा होना चाहिए',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_CARPET_AREA]:
    'कृपया मान्य कालीन क्षेत्र दर्ज करें',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_INPUT]: 'कृपया मान्य इनपुट दर्ज करें',
  [TRANSLATIONS.PARAGRAPHS.TOTAL_FLOORS_ARE_REQUIRED]:
    'कुल मंजिलों की संख्या आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_NUMBER_OF_FLOORS]:
    'कृपया मंजिलों की मान्य संख्या दर्ज करें',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_ON_FLOOR_REQUIRED]:
    'मंजिल पर संपत्ति आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_ON_FLOOR_MUST_BE_LESSER_THAN_TOTAL_FLOORS]:
    'संपत्ति की मंजिल कुल मंजिलों से कम होनी चाहिए',
  [TRANSLATIONS.PARAGRAPHS.LOCK_IN_PERIOD_IS_REQUIRED]: 'लॉक-इन अवधि आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.MAINTENANCE_AMOUNT_REQUIRED]:
    'किराए की लिस्टिंग के लिए रखरखाव राशि आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_MAINTENANCE_AMOUNT]:
    'कृपया मान्य रखरखाव राशि दर्ज करें',
  [TRANSLATIONS.PARAGRAPHS.SECURITY_DEPOSIT_REQUIRED]:
    'किराए की लिस्टिंग के लिए सुरक्षा जमा आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_SECURITY_DEPOSIT_AMOUNT]:
    'कृपया मान्य सुरक्षा जमा राशि दर्ज करें',
  [TRANSLATIONS.PARAGRAPHS.RENT_AMOUNT_REQUIRED]:
    'किराए की लिस्टिंग के लिए किराए की राशि आवश्यक है',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_RENT_AMOUNT]:
    'कृपया मान्य किराया राशि दर्ज करें',
  [TRANSLATIONS.PARAGRAPHS.SALE_PRICE_REQUIRED]:
    "'बिक्री के लिए' लिस्टिंग के लिए बिक्री मूल्य आवश्यक है",
};

export default translationHindi;
