import { TRANSLATIONS } from '../translations';

const translationTelugu = {
  // titles
  [TRANSLATIONS.TITLES.HOME]: 'హోమ్',
  [TRANSLATIONS.TITLES.PROPERTIES]: 'ఆస్తులు',
  [TRANSLATIONS.TITLES.MESSAGES]: 'సందేశాలు',
  [TRANSLATIONS.TITLES.AGENT_ID]: 'ఏజెంట్ ఐడి',
  [TRANSLATIONS.TITLES.LOGOUT]: 'లాగ్అవుట్',
  [TRANSLATIONS.TITLES.PROFILE]: 'ప్రొఫైల్',
  [TRANSLATIONS.TITLES.ASK_AIRA]: 'AIRA ను అడగండి',
  [TRANSLATIONS.TITLES.SAVED]: 'సేవ్ చేయబడింది',
  [TRANSLATIONS.TITLES.UNLOCKED]: 'అన్‌లాక్ చేయబడింది',
  [TRANSLATIONS.TITLES.MY_LISTINGS]: 'నా జాబితాలు',

  // subtitles
  [TRANSLATIONS.SUB_TITLES.ADD_A_PROPERTY]: 'ఒక ఆస్తిని జోడించండి',
  [TRANSLATIONS.SUB_TITLES.CONNECT_SMARTER]: 'స్మార్ట్‌గా కనెక్ట్ అవ్వండి,',
  [TRANSLATIONS.SUB_TITLES.CLOSE_FASTER]: 'vREfydతో వేగంగా ముగించండి.',
  [TRANSLATIONS.SUB_TITLES.AIRA_FIND]: 'మీరు వెతుకుతున్నదాన్ని కనుగొనండి...',
  [TRANSLATIONS.SUB_TITLES.NO_PROPERTY]:
    'మీరు ఇప్పటి వరకు ఏ ఆస్తినీ జోడించలేదు',

  // paragraphs
  [TRANSLATIONS.PARAGRAPHS.SERVICEABLE_IN_BENGALURU]:
    'మేము ప్రస్తుతం బెంగళూరులో సేవలు అందిస్తున్నాము మరియు త్వరలో మరిన్ని నగరాలకు విస్తరించనున్నాము!',
  [TRANSLATIONS.PARAGRAPHS.AIRA_INTRO]:
    'నేను AIRA, భారతదేశపు మొదటి AI రియల్ ఎస్టేట్ ఏజెంట్. నేను మ్యాచ్‌లను కనుగొంటాను, ఆస్తి వివరణలు రాస్తాను, మరియు మీ పని సులభతరం చేయడానికి చిట్కాలను పంచుకుంటాను.',
};

export default translationTelugu;
