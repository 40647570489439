import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Chip,
  Box,
  Modal,
  FormLabel,
  Grid,
  Divider,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import './updateprofile.css';
import CloseIcon from '@mui/icons-material/Close';
import apis from '../../../Services/api-services';
import { toast } from 'react-toastify';
import AreasOfOperation from '../components/AreasOfOperation';
import { Add } from '@mui/icons-material';
import DropdownWithLabel from '../../../components/dropdownWithLabel';
import AgentProfilePicture from '../components/AgentProfilePicture';
import { useSelector } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import { colorsEnums } from '../../../constants/enums';
import { TRANSLATIONS } from '../../../Utils/translations';
import { useTranslation } from 'react-i18next';

const UpdateProfile = ({
  open = false,
  onClose = () => {},
  agentDetails = {},
  getAgentDetails = () => {},
  updateAgentProfilePicture = () => {},
}) => {
  const { t } = useTranslation();
  const { userId } = useSelector((state) => state.userDetails || {});

  const parsedAreasOfOperation = JSON.parse(
    JSON.parse(agentDetails?.area_of_operation || null)
  );

  const [isDescriptionLoading, setIsDescriptionLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [dropdownValues, setDropdownValues] = useState([]);

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    getAgentProfileDropdownValues();
  }, []);

  async function getStates() {
    const response = await apis.dropdown.getStates('IN');
    setStates(response?.data?.[0]?.states);
  }

  async function getCities(stateName) {
    let findState = states?.find((item) => item?.state_name === stateName);
    let stateCode = findState?.state_code;

    const response = await apis.dropdown.getCities('IN', stateCode);
    setCities(response?.data?.[0]?.cities);
  }

  async function getAgentProfileDropdownValues() {
    const response = await apis.agent.getAgentProfileDropdownValues();
    setDropdownValues(response?.data?.dropdowns);
  }

  const profileInitialValues = {
    name: agentDetails?.full_name || '',
    city: agentDetails?.city || '',
    state: agentDetails?.state || '',
    description: agentDetails?.user_description || '',
    yearsOfExperience: agentDetails?.years_of_experience || '',
    specializations: agentDetails?.specialization || [],
    languages: agentDetails?.languages || [],
    areasOfOperation: parsedAreasOfOperation || [],
    companyName: agentDetails?.brand_name || '',
    otherServices: agentDetails?.other_services || [],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(26, 'Name must not exceed 26 characters'),
    location: Yup.string(),
  });

  const handleFormSubmit = async (values) => {
    const {
      name,
      city,
      state,
      description,
      yearsOfExperience,
      specializations,
      languages,
      areasOfOperation,
      companyName,
      otherServices,
    } = values || {};

    const filterAreasOfOperation = areasOfOperation.filter((item) => item.city);

    const params = {
      full_name: name,
      state,
      city,
      user_description: description,
      years_of_experience: yearsOfExperience,
      languages: languages || [],
      area_of_operation: JSON.stringify(filterAreasOfOperation || {}) || [],
      specialization: specializations || [],
      other_services: otherServices || [],
      brand_name: companyName || '',
    };

    const response = await apis.agent.enhanceAgentProfile(params);

    if (response.data) {
      toast.success('Profile updated successfully');
      getAgentDetails();
      onClose();
    }
  };

  const handleRegenerateDescription = async (setFieldValue) => {
    setIsDescriptionLoading(true);
    const parsedAreasOfOperation = JSON.parse(
      JSON.parse(agentDetails?.area_of_operation || null)
    );

    const params = {
      full_name: agentDetails?.full_name || '',
      specialization: agentDetails?.specialization || [],
      languages: agentDetails?.languages || [],
      area_of_operation: parsedAreasOfOperation || [],
      years_of_experience: agentDetails?.years_of_experience || 0,
      company_name: '',
    };

    const response = await apis.agent.regenerateAgentDescription(params);

    if (response?.data?.status === 'success') {
      setFieldValue('description', response?.data?.agent_description);
    }

    setIsDescriptionLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          background: '#fff',
          width: { lg: '68%', xs: '96%' },
          height: { lg: '90%', xs: '96%' },
          overflow: 'scroll',
          borderRadius: '10px',
          position: 'relative',
        }}
      >
        <Box sx={{ width: '100%', height: '6rem', background: '#F5EDFF' }} />

        <CloseIcon
          onClick={onClose}
          sx={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer' }}
        />

        <Box sx={{ padding: { lg: '1rem 2rem', xs: '1rem' } }}>
          <AgentProfilePicture
            userId={userId}
            agentDetails={agentDetails}
            updateAgentProfilePicture={updateAgentProfilePicture}
          />

          <Formik
            enableReinitialize
            initialValues={profileInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleSubmit,
              handleBlur,
              isSubmitting,
            }) => (
              <Box
                sx={{
                  padding: { lg: '.5rem 1.5rem', xs: '.5rem' },
                  marginTop: { lg: '4rem', xs: '2rem' },
                }}
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  marginBottom={2}
                >
                  <Grid item lg={3} xs={12}>
                    <FormLabel
                      sx={{
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.NAME)}
                    </FormLabel>
                  </Grid>
                  <Grid item lg={9} xs={12}>
                    <TextField
                      fullWidth
                      name="name"
                      placeholder={t(TRANSLATIONS.TITLES.NAME)}
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  marginBottom={2}
                >
                  <Grid item lg={3} xs={12}>
                    <FormLabel
                      sx={{
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.LOCATION)}
                    </FormLabel>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    sx={{ marginTop: { lg: '2rem', xs: 0 } }}
                  >
                    <DropdownWithLabel
                      lg={12}
                      hideLabel
                      name="state"
                      label={t(TRANSLATIONS.TITLES.STATE)}
                      value={values.state}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('state', e.target.value);
                        setFieldValue('city', '');
                        getCities(e.target.value);
                      }}
                      onBlur={handleBlur}
                      values={states?.map((state) => state.state_name)}
                      error={touched.state && Boolean(errors.state)}
                      touched={touched.state && Boolean(errors.state)}
                      helperText={touched.state && errors.state}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4.5}
                    xs={12}
                    sx={{ marginTop: { lg: '2rem', xs: 0 } }}
                  >
                    <DropdownWithLabel
                      lg={12}
                      hideLabel
                      name="city"
                      label={t(TRANSLATIONS.TITLES.CITY)}
                      value={values.city}
                      onChange={(e) => setFieldValue('city', e.target.value)}
                      onBlur={handleBlur}
                      values={cities?.map((city) => city.city_name)}
                      error={touched.city && Boolean(errors.city)}
                      touched={touched.city && Boolean(errors.city)}
                      helperText={touched.city && errors.city}
                    />
                  </Grid>
                </Grid>

                {/* Description Field */}
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  marginBottom={2}
                >
                  <Grid item lg={3} xs={12}>
                    <FormLabel
                      sx={{
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.DESCRIPTION)}
                    </FormLabel>
                  </Grid>
                  <Grid item lg={9} xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      name="description"
                      placeholder={t(TRANSLATIONS.TITLES.ENTER_DESCRIPTION)}
                      value={values.description}
                      onChange={handleChange}
                    />
                    <Button
                      variant="text"
                      sx={{
                        textTransform: 'capitalize',
                        textDecoration: 'underline',
                      }}
                      onClick={() => handleRegenerateDescription(setFieldValue)}
                    >
                      {isDescriptionLoading
                        ? t(TRANSLATIONS.TITLES.REGENERATING_DESCRIPTION)
                        : t(TRANSLATIONS.TITLES.REGENERATE)}

                      <RotatingLines
                        visible={isDescriptionLoading}
                        height="24"
                        width="24"
                        strokeColor="#600DB4"
                        strokeWidth="5"
                        animationDuration="0.7"
                      />
                    </Button>
                  </Grid>
                </Grid>

                <Grid sx={{ margin: '2rem 0' }}>
                  <Divider />
                </Grid>

                <Typography
                  sx={{ color: '#600DB4', fontSize: '1rem', fontWeight: '500' }}
                >
                  {t(TRANSLATIONS.TITLES.INFO)}{' '}
                </Typography>

                {/* Company Name */}
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  marginBottom={2}
                >
                  <Grid item lg={3} xs={12}>
                    <FormLabel
                      sx={{
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.COMPANY)}
                    </FormLabel>
                  </Grid>
                  <Grid item lg={9} xs={12}>
                    <TextField
                      fullWidth
                      name="companyName"
                      value={values.companyName}
                      onChange={handleChange}
                      placeholder="Ex: XYZ Pvt Ltd"
                      error={touched.companyName && Boolean(errors.companyName)}
                      helperText={touched.companyName && errors.companyName}
                    />
                  </Grid>
                </Grid>

                {/* Years of Experience */}
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  marginBottom={2}
                >
                  <Grid item lg={3} xs={12}>
                    <FormLabel
                      sx={{
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.CLOSED_DEALS)}
                    </FormLabel>
                  </Grid>
                  <Grid item lg={9} xs={12}>
                    <TextField
                      fullWidth
                      name="yearsOfExperience"
                      value={values.yearsOfExperience}
                      onChange={handleChange}
                      placeholder={t(TRANSLATIONS.PARAGRAPHS.ENTER_YRS_OF_EXP)}
                      error={
                        touched.yearsOfExperience &&
                        Boolean(errors.yearsOfExperience)
                      }
                      helperText={
                        touched.yearsOfExperience && errors.yearsOfExperience
                      }
                    />
                  </Grid>
                </Grid>

                <Grid sx={{ margin: '2rem 0' }}>
                  <Divider />
                </Grid>

                {/* Specializations */}
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  marginBottom={6}
                >
                  <Grid item lg={3} xs={12}>
                    <FormLabel
                      sx={{
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      {t(TRANSLATIONS.SUB_TITLES.MY_SPECIALIZATION)}
                    </FormLabel>
                  </Grid>
                  <Grid item lg={9} xs={12}>
                    {[
                      'Residential Sales',
                      'Residential Leasing',
                      'Commercial Sales',
                      'Commercial Rent',
                    ].map((specialization) => (
                      <Chip
                        key={specialization}
                        label={specialization}
                        clickable
                        onClick={() => {
                          const currentSelection = values.specializations;
                          if (currentSelection.includes(specialization)) {
                            setFieldValue(
                              'specializations',
                              currentSelection.filter(
                                (item) => item !== specialization
                              )
                            );
                          } else {
                            setFieldValue('specializations', [
                              ...currentSelection,
                              specialization,
                            ]);
                          }
                        }}
                        sx={{
                          backgroundColor: values.specializations.includes(
                            specialization
                          )
                            ? '#F5EDFF'
                            : '#fff',
                          border: '1px solid #DCBCFAE5',
                          color: '#600DB4',
                          margin: '4px',
                          borderRadius: '8px',
                          '&:hover': {
                            backgroundColor: values.specializations.includes(
                              specialization
                            )
                              ? '#E0D0FF'
                              : 'grey.400',
                          },
                        }}
                      />
                    ))}
                    {touched.specializations &&
                      Boolean(errors.specializations) && (
                        <div
                          style={{
                            color: colorsEnums.error,
                            fontSize: '0.75rem',
                          }}
                        >
                          {errors.specializations}
                        </div>
                      )}
                  </Grid>
                </Grid>

                {/* Other Services */}
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  marginBottom={2}
                >
                  <Grid item lg={3} xs={12}>
                    <FormLabel
                      sx={{
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.OTHER_SERVICES)}
                    </FormLabel>
                  </Grid>
                  <Grid item lg={9} xs={12}>
                    {dropdownValues?.other_services?.map((service) => (
                      <Chip
                        key={service.id}
                        label={service.value}
                        clickable
                        onClick={() => {
                          const currentSelection = values.otherServices;
                          if (currentSelection.includes(service.value)) {
                            setFieldValue(
                              'otherServices',
                              currentSelection.filter(
                                (item) => item !== service.value
                              )
                            );
                          } else {
                            setFieldValue('otherServices', [
                              ...currentSelection,
                              service.value,
                            ]);
                          }
                        }}
                        sx={{
                          backgroundColor: values.otherServices.includes(
                            service.value
                          )
                            ? '#F5EDFF'
                            : '#fff',
                          border: '1px solid #DCBCFAE5',
                          color: '#600DB4',
                          margin: '4px',
                          borderRadius: '8px',
                          '&:hover': {
                            backgroundColor: values.otherServices.includes(
                              service.value
                            )
                              ? '#E0D0FF'
                              : 'grey.400',
                          },
                        }}
                      />
                    ))}
                    {touched.otherServices && Boolean(errors.otherServices) && (
                      <div
                        style={{
                          color: colorsEnums.error,
                          fontSize: '0.75rem',
                        }}
                      >
                        {errors.otherServices}
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Grid sx={{ margin: '2rem 0' }}>
                  <Divider />
                </Grid>

                {/* Languages */}
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  marginBottom={2}
                >
                  <Grid item lg={3} xs={12}>
                    <FormLabel
                      sx={{
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.LANGUAGE_S)}
                    </FormLabel>
                  </Grid>
                  <Grid item lg={9} xs={12}>
                    {[
                      'English',
                      'Hindi',
                      'Kannada',
                      'Tamil',
                      'Telugu',
                      'Malayalam',
                      'Bangla',
                    ].map((language) => (
                      <Chip
                        key={language}
                        label={language}
                        clickable
                        onClick={() => {
                          const currentSelection = values.languages;
                          if (currentSelection.includes(language)) {
                            setFieldValue(
                              'languages',
                              currentSelection.filter(
                                (item) => item !== language
                              )
                            );
                          } else {
                            setFieldValue('languages', [
                              ...currentSelection,
                              language,
                            ]);
                          }
                        }}
                        sx={{
                          backgroundColor: values.languages.includes(language)
                            ? '#F5EDFF'
                            : '#fff',
                          border: '1px solid #DCBCFAE5',
                          color: '#600DB4',
                          margin: '4px',
                          borderRadius: '8px',
                          '&:hover': {
                            backgroundColor: values.languages.includes(language)
                              ? '#E0D0FF'
                              : 'grey.400', // Optional hover effect
                          },
                        }}
                      />
                    ))}
                    {touched.languages && Boolean(errors.languages) && (
                      <div
                        style={{
                          color: colorsEnums.error,
                          fontSize: '0.75rem',
                        }}
                      >
                        {errors.languages}
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Grid sx={{ margin: '2rem 0' }}>
                  <Divider />
                </Grid>

                {/* Location Dropdown */}
                <Grid container spacing={2} marginBottom={2}>
                  <Grid item lg={3} xs={12}>
                    <Typography
                      sx={{
                        color: '#600DB4',
                        fontSize: '1rem',
                        fontWeight: '500',
                        marginBottom: '1rem',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.INFO)}
                    </Typography>

                    <FormLabel
                      sx={{
                        color: '#000000',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.LOCATION)}
                    </FormLabel>
                  </Grid>
                  <Grid item lg={9} xs={12}>
                    {values?.areasOfOperation?.map((area, index) => (
                      <AreasOfOperation
                        key={index}
                        hideLabel
                        areasOfOperation={values.areasOfOperation}
                        area={area}
                        index={index}
                        setFieldValue={setFieldValue}
                        dropdownValues={dropdownValues}
                      />
                    ))}

                    {values.areasOfOperation.length <
                      dropdownValues?.cities?.length && (
                      <Button
                        sx={{
                          background: '#F5EDFF',
                          color: '#7B0DB4',
                          textTransform: 'capitalize',
                          gap: '.2rem',
                          borderRadius: '8px',
                        }}
                        onClick={() => {
                          let newArr = [
                            ...values.areasOfOperation,
                            {
                              city: '',
                              places: '',
                            },
                          ];
                          setFieldValue('areasOfOperation', newArr);
                        }}
                      >
                        <Add sx={{ fontSize: '18px' }} />
                        {t(TRANSLATIONS.SUB_TITLES.ADD_ANOTHER_CITY)}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <Grid sx={{ margin: '2rem 0' }}>
                  <Divider />
                </Grid>

                <Box
                  sx={{
                    textAlign: 'right',
                    display: 'flex',
                    columnGap: 2,
                    justifyContent: 'flex-end',
                    marginBottom: '2rem',
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    sx={{
                      border: '1.2px solid #D2D2D2',
                      color: '#000',
                      textTransform: 'capitalize',
                    }}
                  >
                    {t(TRANSLATIONS.TITLES.CANCEL)}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    sx={{
                      textTransform: 'capitalize',
                      columnGap: '4px',
                      borderRadius: '8px',
                    }}
                  >
                    {isSubmitting
                      ? t(TRANSLATIONS.TITLES.SAVING_CHANGES)
                      : t(TRANSLATIONS.TITLES.SAVE)}
                    <RotatingLines
                      visible={isDescriptionLoading}
                      height="24"
                      width="24"
                      strokeColor="#fff"
                      strokeWidth="5"
                      animationDuration="0.7"
                    />
                  </Button>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateProfile;
