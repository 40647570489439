import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nextHttpBackend from 'i18next-http-backend';
import i18nextLanguageDetector from 'i18next-browser-languagedetector';
import { persistor } from '../Store/store';

import translationEnglish from './locales/english';
import translationHindi from './locales/hindi';
import translationBangla from './locales/bangla';
import translationKannada from './locales/kannada';
import translationTamil from './locales/tamil';
import translationTelugu from './locales/telugu';

export const pluralize = (str) => `${str}_plural`;
const defaultLanguage = persistor.getState().userDetails?.language;

const resources = {
  english: { translation: translationEnglish },
  hindi: { translation: translationHindi },
  bangla: { translation: translationBangla },
  kannada: { translation: translationKannada },
  tamil: { translation: translationTamil },
  telugu: { translation: translationTelugu },
};

i18n
  .use(i18nextHttpBackend)
  .use(i18nextLanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: true,
    lng: defaultLanguage, // default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
