import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ImageCropper from '../../../components/imageCropper';

export default function AgentProfilePicture({
  userId,
  agentDetails,
  updateAgentProfilePicture,
  isEditable = true,
  uploadedImage,
  preview,
}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isCropping, setIsCropping] = useState(false);

  const handleImageSelect = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        setIsCropping(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCropSave = (croppedResult) => {
    updateAgentProfilePicture(croppedResult);
    setIsCropping(false);
  };

  return (
    <>
      <Box className="profile-photo">
        <img
          src={
            uploadedImage
              ? URL.createObjectURL(uploadedImage)
              : agentDetails?.profile_picture
          }
          alt="Profile"
          style={{
            borderRadius: '50%',
            width: '145px',
            height: '145px',
            border: '4px solid white',
            objectFit: 'cover',
          }}
        />
        {agentDetails?.customer_id === userId && isEditable && (
          <Button component="label" className="edit-button">
            <input
              type="file"
              hidden
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => handleImageSelect(e.target.files[0])}
            />
            <DriveFileRenameOutlineIcon className="edit-icon" />
          </Button>
        )}
      </Box>

      {/* isCropping is being specified because without that the modal will be open by default*/}
      <ImageCropper
        open={isCropping && { image: selectedImage }} //passing the selected image
        onClose={() => setIsCropping(false)} //close the modal
        onCropSave={handleCropSave} //function to handle the profile update
        aspect={1} //aspect ratio
      />
    </>
  );
}
