import React, { useState } from 'react';
import { Modal, Box, Button } from '@mui/material';
import Cropper from 'react-easy-crop';
import getCroppedImg from './getCroppedImg';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from '../Utils/translations';

export default function ImageCropper({ open, onClose, onCropSave, aspect }) {
  const { t } = useTranslation();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSave = async (image) => {
    try {
      const croppedResult = await getCroppedImg(image, croppedAreaPixels);
      onCropSave(croppedResult);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '440px',
          height: '580px',
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Cropper
          image={open.image}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            textAlign: 'center',
            gap: 2,
            marginTop: '24px',
            width: { lg: '100%', xs: '80%', margin: '0 auto' },
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ textTransform: 'capitalize' }}
            onClick={() => handleSave(open.image)}
          >
            {t(TRANSLATIONS.TITLES.SAVE)}
          </Button>

          <Button
            onClick={onClose}
            variant="outlined"
            sx={{
              padding: '8px 0',
              color: '#fff',
              textTransform: 'capitalize',
              background: 'rgba(255, 255, 255, 0.4)',
            }}
          >
            {t(TRANSLATIONS.TITLES.CANCEL)}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
