import React, { useEffect, useState } from 'react';
import './loader.css';
import RectLoader from '../Assets/images/rect-loader.gif';
import { Typography } from '@mui/material';

const LoaderComponent = ({ text = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [isFlipping, setIsFlipping] = useState(false);

  useEffect(() => {
    if (text.length === 1) {
      // Handle single text: show it without animations
      setDisplayedText(text[0]);
      return;
    }

    if (text.length > 1) {
      let typingTimeout;
      let flipTimeout;

      const typeText = (index) => {
        const currentText = text[index];
        setDisplayedText('');
        let charIndex = 0;

        typingTimeout = setInterval(() => {
          if (charIndex < currentText.length) {
            setDisplayedText((prev) => prev + currentText[charIndex]);
            charIndex++;
          } else {
            clearInterval(typingTimeout);
            flipTimeout = setTimeout(() => {
              setIsFlipping(true);
              setTimeout(() => {
                setIsFlipping(false);
                setCurrentIndex((prevIndex) => (prevIndex + 1) % text.length);
              }, 600); // Flip animation duration
            }, 2000); // Delay before flipping
          }
        }, 50); // Typing speed
      };

      typeText(currentIndex);

      return () => {
        clearInterval(typingTimeout);
        clearTimeout(flipTimeout);
      };
    }
  }, [currentIndex, text]);

  return (
    <div className="loader-container">
      <div id="loader-content" className="loading">
        <img  src={RectLoader} width={70} height={77} alt="loader" />
        {text.length > 0 && (
          <Typography
            color={'#000000'}
            sx={{
              marginY: '22px',
              fontWeight: 400,
              fontSize: '18px',
              textAlign: 'center',
              transform: isFlipping ? 'rotateX(90deg)' : 'rotateX(0deg)',
              opacity: isFlipping ? 0 : 1,
              transition: 'transform 0.6s, opacity 0.6s',
            }}
          >
            {displayedText}...
          </Typography>
        )}
      </div>
    </div>
  );
};

export default LoaderComponent;
