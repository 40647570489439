import React, { useEffect, useState } from 'react';
import BRANDLOGO from '../Assets/images/brand-logo.png';
import PROFILE from '../Assets/images/nav-profile.png';
import NOTIFY from '../Assets/images/nav-nofication.png';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import './navbar.css';
import {
  ADD_PROPERTY,
  DASHBOARD,
  HOME,
  MESSAGES,
} from '../constants/route-path';
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import HomeSidebar from './home-sidebar';
import MENU from '../Assets/images/menu.png';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconRosetteDiscountCheckFilled } from '@tabler/icons-react';
import { handleProfileRedirection } from '../constants/enums';
import Notifications from './Notifications';
import apis from '../Services/api-services';
import { userActions } from '../Store/userSlice';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from '../Utils/translations';
import LanguageModal from './LanguageModal';

const Navbar = ({
  handleLogout,
  selectedNavLink,
  setSelectedNavLink,
  totalUnreadCount,
  notificationsCount,
}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { userAvatar, userName, userId, isUserVerified } = useSelector(
    (state) => state.userDetails || {}
  );
  const { isLoggedIn } = useSelector((state) => state.login || {});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNotificationModal, setOpenNotificationModal] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [openLanguageModal, setOpenLanguageModal] = useState(false);

  const navLinks = [
    {
      title: t(TRANSLATIONS.TITLES.HOME),
      id: 1,
      to: HOME,
      badge: false,
    },
    {
      title: t(TRANSLATIONS.TITLES.PROPERTIES),
      id: 2,
      to: DASHBOARD,
      badge: false,
    },
    {
      title: t(TRANSLATIONS.TITLES.MESSAGES),
      id: 3,
      to: MESSAGES,
      badge: true,
    },
  ];

  const handleClick = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleDrawerToggle = () => setOpenSidebar(true);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = notifications?.length
      ? `(${notifications?.length}) AIRA`
      : 'AIRA';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {
    if (isLoggedIn) {
      getNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotifications = async () => {
    setIsLoading(true);
    const response = await apis.notification.getNotifications();
    setNotifications(response?.data?.notifications || []);
    setIsLoading(false);
  };

  if (!isLoggedIn) {
    return;
  }

  return (
    <>
      <nav className="navbar">
        <div className="nav-brand">
          <img
            src={BRANDLOGO}
            alt="logo"
            className="nav-brand-img"
            onClick={() => {
              navigate(HOME);
              dispatch(userActions.setRecomendationFilters({}));
              window.location.reload();
            }}
          />
        </div>
        <div className="nav-links-main">
          <div className="nav-links-content">
            {navLinks.map((item) => (
              <NavLink
                index={item.id}
                onClick={() => setSelectedNavLink(item.id)}
                to={item.to}
                className={`nav-links ${
                  selectedNavLink === item.id ? 'active-nav-link' : ''
                }`}
              >
                {' '}
                {item.title}{' '}
                {item.badge && (
                  <Badge
                    badgeContent={totalUnreadCount}
                    color="secondary"
                    variant="dot"
                    sx={{
                      marginLeft: '8px',
                      '& .MuiBadge-badge': {
                        background: '#600DB4 !important',
                      },
                    }}
                  >
                    {' '}
                  </Badge>
                )}
              </NavLink>
            ))}
          </div>
        </div>
        <div className="nav-buttons">
          {pathname !== ADD_PROPERTY && (
            <span
              className="nav-list-property-btn"
              onClick={() => navigate('/add-property')}
            >
              <AddIcon className="nav-add-icon" />
              {t(TRANSLATIONS.SUB_TITLES.ADD_A_PROPERTY)}
            </span>
          )}

          <span
            className="nav-button"
            style={{ position: 'relative' }}
            onClick={() => {
              getNotifications();
              setOpenNotificationModal((val) => !val);
            }}
          >
            <img src={NOTIFY} alt="notification" />

            {notificationsCount > 0 && (
              <span
                style={{
                  position: 'absolute',
                  background: '#600DB4',
                  top: '-8px',
                  right: '-8px',
                  width: '22px',
                  height: '22px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '12px',
                  color: '#fff',
                }}
              >
                {notificationsCount}
              </span>
            )}
          </span>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              boxShadow: '1px 1px 7.3px 0px rgba(185, 185, 185, 0.25)',
              padding: '8px 10px',
              borderRadius: '12px',
              marginLeft: '.5rem',
              cursor: 'pointer',
              maxWidth: '50%',
            }}
            onClick={handleClick}
            aria-describedby={id}
          >
            <Avatar src={userAvatar} sx={{ width: '36px', height: '36px' }} />

            <Typography sx={{ color: '#474747' }}>
              {t(userName || '')}
            </Typography>
            <KeyboardArrowDownIcon sx={{ color: '#474747' }} />
          </Box>

          <Popover
            id={id}
            transition
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            sx={{
              '.MuiBox-root': {
                border: 'none',
                borderRadius: '10px',
                boxShadow: '1px 1px 7.3px 0px rgba(185, 185, 185, 0.25)',
                cursor: 'pointer',
              },
            }}
          >
            <Box
              sx={{
                border: 1,
                p: 2,
                bgcolor: 'background.paper',
                cursor: 'pointer',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              {t(TRANSLATIONS.TITLES.AGENT_ID)} : {userId}
              {isUserVerified === 'true' && (
                <IconRosetteDiscountCheckFilled
                  color="#600DB4"
                  width={'20px'}
                  height={'20px'}
                />
              )}
            </Box>
            <Box
              onClick={() => {
                handleClose();
                handleProfileRedirection(navigate);
              }}
              sx={{
                border: 1,
                p: 2,
                bgcolor: 'background.paper',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              {t(TRANSLATIONS.TITLES.PROFILE)}
            </Box>
            <Box
              onClick={() => {
                handleClose();
                setOpenLanguageModal(true);
              }}
              sx={{
                border: 1,
                p: 2,
                bgcolor: 'background.paper',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              {t(TRANSLATIONS.TITLES.LANGUAGE)}
            </Box>
            <Box
              onClick={() => {
                handleClose();
                window.open(
                  'https://airtable.com/embed/appYSVKAFJ9TqK3q1/pagDIOsuVcgNnNbjt/form',
                  '_blank'
                );
              }}
              sx={{
                border: 1,
                p: 2,
                bgcolor: 'background.paper',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              {t(TRANSLATIONS.TITLES.SHARE_FEEDBACK)}
            </Box>
            <Box
              onClick={() => {
                handleClose();
                handleLogout();
              }}
              sx={{
                border: 1,
                p: 2,
                bgcolor: 'background.paper',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              {t(TRANSLATIONS.TITLES.LOGOUT)}
            </Box>
          </Popover>
        </div>
      </nav>

      <div className="home-open-drawer">
        <span
          className="nav-button"
          onClick={() => {
            getNotifications();
            setOpenNotificationModal((val) => !val);
          }}
        >
          <img src={NOTIFY} alt="notification" />
        </span>
        <span
          className="nav-button"
          onClick={handleClick}
          aria-describedby={id}
        >
          <img src={PROFILE} alt="profile" />
        </span>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            display: 'none',
            '.MuiBox-root': {
              border: 'none',
              borderRadius: '10px',
              boxShadow: '1px 1px 7.3px 0px rgba(185, 185, 185, 0.25)',
              cursor: 'pointer',
              alignItems: 'center',
              width: '100%',
            },
          }}
          className="home-profile-content"
        >
          <Box
            onClick={() => handleLogout()}
            sx={{
              borderBottom: '1px solid #D2D2D2 ',
              p: 2,
              bgcolor: 'background.paper',
              cursor: 'pointer',
            }}
          >
            {t(TRANSLATIONS.TITLES.AGENT_ID)} : {userId}{' '}
            <ContentCopyIcon sx={{ color: '#474747' }} />
          </Box>
          <Box
            onClick={() => handleLogout()}
            sx={{
              border: 1,
              p: 2,
              bgcolor: 'background.paper',
              cursor: 'pointer',
            }}
            className="home-profile-content"
          >
            {t(TRANSLATIONS.TITLES.LOGOUT)}
          </Box>
        </Popover>

        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className="home-menu-icon"
          onClick={handleDrawerToggle}
          sx={{ mx: 2, display: { xmd: 'none' } }}
        >
          <img src={MENU} alt="Menu" style={{ width: 25, height: 25 }} />
        </IconButton>
      </div>

      <HomeSidebar open={openSidebar} setOpen={setOpenSidebar} />

      <Notifications
        open={openNotificationModal}
        onClose={() => setOpenNotificationModal(false)}
        notifications={notifications}
        getNotifications={getNotifications}
        isLoading={isLoading}
        notificationsCount={notificationsCount}
      />

      <LanguageModal
        open={openLanguageModal}
        onClose={() => setOpenLanguageModal(false)}
      />
    </>
  );
};

export default Navbar;
