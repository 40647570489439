import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';

import AIRA_LOGO_PURPLE from '../Assets/images/Aira_Logo_purple.png';

const PageTitle = ({ title, description, note, isLoading }) => {
  const [animatedText, setAnimatedText] = useState({
    title: '',
    description: '',
    note: '',
  });

  useEffect(() => {
    if (!isLoading) {
      const animateText = async (text, key) => {
        for (let i = 0; i <= text.length; i++) {
          setAnimatedText((prev) => ({ ...prev, [key]: text.slice(0, i) }));
          await new Promise((resolve) => setTimeout(resolve, 6));
        }
      };

      const animateAllText = async () => {
        await animateText(title || '', 'title');
        await animateText(description || '', 'description');
        await animateText(note || '', 'note');
      };

      animateAllText();
    }
  }, [isLoading, title, description, note]);

  return (
    !isLoading && (
      <Box
        sx={{
          background:
            'linear-gradient(45deg,rgba(219, 182, 247, 0.1) 0%,rgba(248, 234, 244, 1)  40%, rgba(248, 234, 244, 0.1) 60%, rgba(234, 202, 255, 1) 80%,  rgba(234, 202, 255, 1) 40%)',
          borderRadius: '16px',
          width: '100%',
          padding: '1rem',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={AIRA_LOGO_PURPLE}
            alt="aira logo"
            style={{ margin: '0 4px', width: '18px' }}
          />
          <Typography
            sx={{
              color: '#600DB4',
              fontSize: '18px',
              fontWeight: '600',
              marginLeft: '.5rem',
            }}
          >
            AIRA
          </Typography>
        </Box>
        <Box sx={{ marginLeft: '2rem', marginTop: '.5rem' }}>
          {title && (
            <Typography
              sx={{ color: '#474747', fontSize: '16px', fontWeight: '700' }}
            >
              {animatedText.title}
            </Typography>
          )}

          {description && (
            <Typography
              sx={{
                color: '#474747',
                fontSize: '14px',
                fontWeight: '500',
                margin: '.5rem 0',
              }}
            >
              {animatedText.description}
            </Typography>
          )}
        </Box>
        {note && (
          <>
            <Divider sx={{ margin: '1rem 0' }} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {animatedText.note !== '' && (
                <AutoAwesomeOutlinedIcon
                  sx={{ color: '#600DB4', fontWeight: '400' }}
                />
              )}

              <Typography
                sx={{
                  color: '#600DB4',
                  fontSize: '14px',
                  fontWeight: '500',
                  fontStyle: 'italic',
                  marginLeft: '.5rem',
                }}
              >
                {animatedText.note}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    )
  );
};

export default PageTitle;
