import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  ADD_PROPERTY,
  DASHBOARD,
  HOME,
  MESSAGES,
} from '../constants/route-path';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import MessageIcon from '@mui/icons-material/Message';
import GridViewIcon from '@mui/icons-material/GridView';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from '../Utils/translations';

export default function HomeSidebar({ open, setOpen }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const navlinks = [
    {
      title: t(TRANSLATIONS.TITLES.HOME),
      id: 1,
      to: HOME,
      icon: <HomeRoundedIcon />,
    },
    {
      title: t(TRANSLATIONS.TITLES.DASHBOARD),
      id: 2,
      to: DASHBOARD,
      icon: <GridViewIcon />,
    },
    {
      title: t(TRANSLATIONS.TITLES.MESSAGES),
      id: 3,
      to: MESSAGES,
      icon: <MessageIcon />,
    },
  ];

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      {pathname !== ADD_PROPERTY && (
        <span
          className="nav-list-property-btn"
          style={{ marginTop: '40px' }}
          onClick={() => navigate('/add-property')}
        >
          <AddIcon className="nav-add-icon" />
          {t(TRANSLATIONS.SUB_TITLES.LIST_MY_PROPERTY)}
        </span>
      )}
      <List>
        {navlinks.map((item, index) => (
          <ListItem
            key={item.id}
            disablePadding
            onClick={() => navigate(item.to)}
          >
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
