import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';

const TypingEffect = ({ text, delay = 17, onTypingComplete }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  const formatMessage = (text) => {
    const formattedText = text
      .replace(/^####\s(.+)$/gm, '<h4>$1</h4>') // Format level 4 headings
      .replace(/^###\s(.+)$/gm, '<h3>$1</h3>') // Format level 3 headings
      .replace(/^##\s(.+)$/gm, '<h2>$1</h2>') // Format level 2 headings
      .replace(/^#\s(.+)$/gm, '<h1>$1</h1>') // Format level 1 headings
      .replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>') // Bold text
      .replace(/^-\s(.+)$/gm, '<li>$1</li>') // Bullet points
      .replace(/\n/g, '<br />'); // Line breaks

    // Wrap bullet points in <ul> tags
    return formattedText.replace(/(<li>[\s\S]*?<\/li>)/g, '<ul>$1</ul>');
  };

  const splitTextByTags = (html) => {
    const regex = /(<[^>]*>)/g;
    return html.split(regex).filter(Boolean);
  };

  useEffect(() => {
    const formattedText = formatMessage(text);

    const parts = splitTextByTags(formattedText);
    const textParts = parts.filter((part) => !part.startsWith('<'));

    const typeNextChar = () => {
      if (currentIndex < textParts.join('').length) {
        setDisplayedText((prev) => {
          let charCount = 0;
          let result = '';
          let i = 0;
          while (charCount <= currentIndex) {
            const part = textParts[i] || '';
            const length = part.length;
            if (charCount + length >= currentIndex) {
              result += part.slice(0, currentIndex - charCount + 1);
              break;
            }
            result += part;
            charCount += length;
            i += 1;
          }
          return result;
        });
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else if (onTypingComplete) {
        onTypingComplete();
      }
    };

    const typingInterval = setInterval(typeNextChar, delay);
    return () => clearInterval(typingInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, delay, text]);

  const combineTextWithTags = () => {
    const formattedText = formatMessage(text);
    const parts = splitTextByTags(formattedText);
    let result = '';
    let textPosition = 0;

    parts.forEach((part) => {
      if (part.startsWith('<') && part.endsWith('>')) {
        if (displayedText.length > textPosition) {
          result += part;
        }
      } else {
        result += displayedText.slice(textPosition, textPosition + part.length);
        textPosition += part.length;
      }
    });

    return result;
  };

  return <>{parse(combineTextWithTags())}</>;
};

export default TypingEffect;
