import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import './propertyDetail.css';
import { Button, Checkbox, Grid, Skeleton, Typography } from '@mui/material';
import SUCCESSTICK from '../Assets/images/success-tick.png';
import GREENSUCCESSTICK from '../Assets/images/green-success-tick.svg';
import { useEffect, useState } from 'react';
import apis from '../Services/api-services';
import { numDifferentiation } from '../Utils/helperUtils';
import { formatMessage } from '../constants/enums';
import { useNavigate } from 'react-router-dom';
import {
  DASHBOARD,
  DECLARATION,
  IMAGE_VERIFICATION,
  MESSAGES,
  PROFILE_ONBOARDING,
} from '../constants/route-path';
import PropertyVerification from './property-verification-modal';
import LoaderComponent from './loader';
import { IconFileTypeDoc, IconX } from '@tabler/icons-react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import UNLOCKIMG from '../Assets/images/unlock.png';
import SAVEICON from '../Assets/images/save-icon.png';
import UNSAVEICON from '../Assets/images/unsave-icon.png';
import PropertyDealClosure from './property-deal-closure';
import { RENTVALUE, SALEVALUE } from '../constants/app-constants';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from '../Utils/translations';
const SAVED = 'saved';
const HOME = 'home';
const LISTING = 'listing';
const UNLOCK = 'unlock';
const PROFILE = 'profile';

export default function PropertyDetail(props) {
  const {
    toggleDrawer,
    open,
    setOpen,
    selectedPropertyID,
    from,
    fromMsg = false,
    externalAgentId = '',
    fetchListedProperties,
    myleads = false,
  } = props;

  const { t } = useTranslation();

  const loggedInAgentId = useSelector(
    (state) => state.userDetails.userId || {}
  );

  const [selectedproperty, setSelectedProperty] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeImg, setActiveImg] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [chatUnlocked, setChatUnlocked] = useState(false);
  const [verificationIniated, setVerificationIniated] = useState(false);
  const [dealClosureInitiated, setDealClosureInitiated] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [brokerStatus, setBrokerStatus] = useState({});
  const [isSave, setIsSave] = useState(false);

  const resetData = () => {
    setVerificationSuccess(false);
    setVideoPreview(null);
    setVideoFile(null);
    setVerificationIniated(false);
    setDealClosureInitiated(false);
  };

  useEffect(() => {
    if (from === LISTING) {
      setLoading(true);

      apis.agent
        .getAgentProfileCompletionStatus()
        .then((res) => {
          setLoading(false);

          const profile = res?.data?.profile;
          setBrokerStatus(profile);
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
        });
    }

    return () => {
      resetData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedPropertyID) {
      setLoading(true);
      if (from === SAVED) {
        apis.property
          .getSavedPropertyByID({ id: selectedPropertyID })
          .then((response) => {
            setLoading(false);
            if (response.status === 200) {
              const [property] = response?.data?.property;
              if (property?.files && property?.files.length > 0) {
                let coverImg = property?.files?.find((item) => {
                  return item.is_cover_picture;
                });
                coverImg
                  ? setActiveImg(coverImg)
                  : setActiveImg(property?.files[0]);
              }
              setSelectedProperty(property);
            } else {
              setSelectedProperty('');
              setOpen(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log('Error fetching property by ID', err);
          });
      } else if (from === UNLOCK) {
        apis.property
          .getUnlockedPropertiesByID({ id: selectedPropertyID })
          .then((response) => {
            setLoading(false);
            if (response.status === 200) {
              const [property] = response?.data?.property;
              if (property?.files && property?.files.length > 0) {
                let coverImg = property?.files?.find((item) => {
                  return item.is_cover_picture;
                });
                coverImg
                  ? setActiveImg(coverImg)
                  : setActiveImg(property?.files[0]);
              }
              setSelectedProperty(property);
            } else {
              setSelectedProperty('');
              setOpen(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log('Error fetching property by ID', err);
          });
      } else if (from === HOME) {
        apis.property
          .getRecomendedPropertyByID({ id: selectedPropertyID })
          .then((response) => {
            setLoading(false);
            if (response.status === 200) {
              const [property] = response?.data?.property;
              if (property?.files && property?.files.length > 0) {
                let coverImg = property?.files?.find((item) => {
                  return item.is_cover_picture;
                });
                coverImg
                  ? setActiveImg(coverImg)
                  : setActiveImg(property?.files[0]);
              }
              setSelectedProperty(property);
            } else {
              setSelectedProperty('');
              setOpen(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log('Error fetching property by ID', err);
          });
      } else if (from === PROFILE && externalAgentId) {
        apis.agent
          .getAgentPropertyDetailsById({
            agentId: externalAgentId,
            propertyId: selectedPropertyID,
          })
          .then((response) => {
            setLoading(false);
            if (response.status === 200) {
              const [property] = response?.data?.property;
              if (property?.files && property?.files.length > 0) {
                let coverImg = property?.files?.find((item) => {
                  return item.is_cover_picture;
                });
                coverImg
                  ? setActiveImg(coverImg)
                  : setActiveImg(property?.files[0]);
              }
              setSelectedProperty(property);
            } else {
              setSelectedProperty('');
              setOpen(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log('Error fetching property by ID', err);
          });
      } else {
        apis.property
          .getListingPropertiesByID({ id: selectedPropertyID })
          .then((response) => {
            setLoading(false);
            if (response.status === 200) {
              const [property] = response?.data?.property;
              if (property?.files && property?.files.length > 0) {
                let coverImg = property?.files?.find((item) => {
                  return item.is_cover_picture;
                });
                if (coverImg?.file_id) {
                  setCoverImage(coverImg?.file_id);
                }
                coverImg
                  ? setActiveImg(coverImg)
                  : setActiveImg(property?.files[0]);
              }
              setSelectedProperty(property);
            } else {
              setSelectedProperty('');
              setOpen(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log('Error fetching property by ID', err);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPropertyID, navigate]);

  const handlePdfClick = (pdf) => {
    window.open(pdf, '_blank');
  };

  const handleSetCoverImage = (fileID) => {
    apis.property
      .setPropertyCoverPicture(selectedPropertyID, fileID)
      .then((res) => {
        if (res.status === 200) {
          setCoverImage(fileID);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleUnlockChat = () => {
    let obj = {
      chat_room: null,
      cover_picture: activeImg.file_url,
      owner_name: selectedproperty.created_by,
      property_title: selectedproperty.property_title,
      property_id: selectedproperty.property_id,
      broker_id: selectedproperty.broker_id,
    };
    setChatUnlocked(true);
    setTimeout(() => {
      navigate(MESSAGES, { state: obj });
      externalAgentId && navigate(0);
    }, 2500);
  };

  const handleMessageClick = () => {
    let obj = {
      chat_room: selectedproperty.chat_room,
      cover_picture: activeImg.file_url,
      owner_name: selectedproperty.created_by,
      property_title: selectedproperty.property_title,
      broker_id: selectedproperty.broker_id,
      property_id: {},
    };
    navigate(MESSAGES, { state: obj });
  };

  const handleVerifyProperty = () => {
    if (brokerStatus?.is_verified) {
      if (verificationIniated) {
        setUploadLoader(true);
        const formData = new FormData();
        formData.append('video_verification', videoFile);
        apis.property
          .verifyProperty(formData, selectedproperty.property_id)
          .then((response) => {
            if (response?.data?.status === 'success') {
              setUploadLoader(false);
              setVideoFile(null);
              setVideoPreview(null);
              setVerificationSuccess(true);
            }

            if (response?.response?.data?.status === 'error') {
              toast.error(
                response?.response?.data?.message || 'Something went wrong'
              );
            }
          })
          .catch((err) => {
            setUploadLoader(false);
            console.log(err, 'Error Uploading Video - Property Verification');
          })
          .finally(() => {
            setUploadLoader(false);
          });
      } else {
        setVerificationIniated(true);
      }
    } else if (
      brokerStatus?.is_part1_complete &&
      !brokerStatus?.is_ekyc_completed
    ) {
      navigate(DECLARATION);
    } else if (
      brokerStatus?.is_ekyc_completed &&
      !brokerStatus?.is_verification_inititated
    ) {
      navigate(IMAGE_VERIFICATION);
    } else {
      navigate(PROFILE_ONBOARDING);
    }
  };

  const handleVerificationClose = () => {
    setVerificationIniated(false);
    fetchListedProperties();
    navigate(DASHBOARD);
  };

  const details = [
    {
      label: t(TRANSLATIONS.SUB_TITLES.PROPERTY_SALE_TYPE),
      value: selectedproperty?.property_sale_type || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.BEDROOMS),
      value: selectedproperty?.bedroom_count || '-',
      hide: selectedproperty?.property_category === 'Commercial',
    },
    {
      label: t(TRANSLATIONS.TITLES.BATHROOMS),
      value: selectedproperty?.bathroom_count || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.BALCONIES),
      value: selectedproperty?.balcony_count || '-',
      hide: selectedproperty?.propertyCategory === 'Commercial',
    },
    {
      label: t(TRANSLATIONS.TITLES.PROPERTY_TYPE),
      value: selectedproperty?.property_type || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.PROPERTY_CATEGORY),
      value: selectedproperty?.property_category || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.OCCUPANCY_STATUS),
      value: selectedproperty?.occupancy_status || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.CARPET_AREA),
      value: selectedproperty?.carpet_area + ' sq.ft.' || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.TOTAL_FLOOR),
      value: selectedproperty?.total_no_of_floors || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.PROPERTY_ON_FLOOR),
      value: selectedproperty?.property_on_floor || '-',
      hide: selectedproperty?.property_type === 'Independent House',
    },
    {
      label: t(TRANSLATIONS.TITLES.PROPERTY_FACING),
      value: selectedproperty?.property_facing || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.BUILT_IN_YEAR),
      value: selectedproperty?.property_established || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.PROPERTY_NAME),
      value: selectedproperty?.property_title || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.LIFT),
      value: selectedproperty?.lift || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.CAR_PARKING),
      value: selectedproperty?.residential_parking || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.FURNISHING_TYPE),
      value: selectedproperty?.furnish_type || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.PROPERTY_VALUE),
      value: numDifferentiation(selectedproperty?.sale_price || 0) || '-',
      hide: selectedproperty?.property_sale_type === RENTVALUE,
    },
    {
      label: t(TRANSLATIONS.TITLES.BROKERAGE),
      value: selectedproperty?.brokerage || '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.LOCK_IN_PERIOD),
      value: selectedproperty?.lock_in_period || '-',
      hide: selectedproperty?.property_sale_type === SALEVALUE,
    },
    {
      label: t(TRANSLATIONS.TITLES.RENT_AMOUNT),
      value: selectedproperty?.rent_amount || '-',
      hide: selectedproperty?.property_sale_type === SALEVALUE,
    },
    {
      label: t(TRANSLATIONS.TITLES.SECURITY_DEPOSIT),
      value: numDifferentiation(selectedproperty?.security_deposit || 0) || '-',
      hide: selectedproperty?.property_sale_type === SALEVALUE,
    },
    {
      label: t(TRANSLATIONS.TITLES.MAINTENANCE_AMOUNT),
      value: selectedproperty?.maintenance_amount || '-',
      hide: selectedproperty?.property_sale_type === SALEVALUE,
    },
    {
      label: t(TRANSLATIONS.TITLES.RENT_AVAILABLE_FROM),
      value: selectedproperty?.rent_available_from
        ? dayjs(selectedproperty?.rent_available_from).format('DD/MM/YYYY')
        : '-',
      hide: selectedproperty?.property_sale_type === SALEVALUE,
    },
    {
      label: t(TRANSLATIONS.TITLES.PROPERTY_ADDED_ON),
      value: selectedproperty?.created_at
        ? dayjs(selectedproperty?.created_at).format('DD/MM/YYYY')
        : '-',
    },
    {
      label: t(TRANSLATIONS.TITLES.TENANT_PREFERENCES),
      value: selectedproperty?.tenant_preferences?.join(', ') || '-',
      hide: selectedproperty?.property_sale_type === SALEVALUE,
    },
  ];

  const handleWishList = () => {
    apis.property
      .wishList(
        { property_id: selectedPropertyID },
        !isSave ? 'POST' : 'DELETE'
      )
      .then((response) => {
        console.log(response);

        setIsSave(!isSave);
      })
      .then((err) => {
        console.log(err, 'Saving property');
      });
    setIsSave(!isSave);
  };

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          id="property-detail"
          sx={{ width: 645 }}
          role="presentation"
          className="property-detail-container"
        >
          {!verificationIniated && !dealClosureInitiated && (
            <span
              className="property-detail-close"
              onClick={toggleDrawer(false)}
            >
              <IconX className="property-detail-close-icon" />
            </span>
          )}

          {verificationIniated && (
            <PropertyVerification
              videoFile={videoFile}
              setVideoFile={setVideoFile}
              videoPreview={videoPreview}
              setVideoPreview={setVideoPreview}
              onClose={() => handleVerificationClose()}
              verificationSuccess={verificationSuccess}
              verificationCode={selectedproperty?.prop_id}
            />
          )}

          {dealClosureInitiated && (
            <PropertyDealClosure
              onClose={() => setDealClosureInitiated(false)}
              propertyId={selectedPropertyID}
              closeDrawer={toggleDrawer(false)}
              fetchListedProperties={fetchListedProperties}
            />
          )}

          {loading ? (
            <Box sx={{ margin: 3 }}>
              <Skeleton height={300} />
              <Skeleton height={200} />
              <Skeleton height={300} />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          ) : (
            !verificationIniated &&
            !dealClosureInitiated && (
              <Box
                className="property-detail-content"
                id="property-detail"
                sx={{
                  margin: {
                    lg: '40px 37px 37px 40px',
                    xs: '34px 12px 20px 12px',
                  },
                }}
              >
                <div className="property-info-postedBy">
                  <div className="property-info-agent-details">
                    <div className="agent-profile-details">
                      <img
                        src={selectedproperty?.profile_picture}
                        alt="profile"
                        className="agent-profile-icon"
                        onClick={() =>
                          from !== LISTING &&
                          navigate(
                            `/profile?customer_id=${selectedproperty?.broker_id}`
                          )
                        }
                      />
                    </div>
                    <div
                      className="agent-details"
                      onClick={() =>
                        from !== LISTING &&
                        navigate(
                          `/profile?customer_id=${
                            selectedproperty?.broker_id || loggedInAgentId
                          }`
                        )
                      }
                    >
                      <>
                        <span className="agent-details-posted-by-text">
                          Posted by:
                        </span>
                        <span className="agent-name">
                          {selectedproperty?.created_by}{' '}
                          {selectedproperty?.is_verified && (
                            <img
                              src={SUCCESSTICK}
                              alt="profile"
                              className="agent-verified-icon"
                            />
                          )}
                        </span>
                      </>
                    </div>

                    {from === LISTING && (
                      <span
                        onClick={() =>
                          selectedproperty?.property_unlock_count &&
                          navigate(`${MESSAGES}?prop=${selectedPropertyID}`)
                        }
                        className="property-item-views"
                      >
                        <img
                          src={UNLOCKIMG}
                          alt="view"
                          className="property-item-icon"
                        />
                        <span>
                          {selectedproperty?.property_unlock_count} Unlocks
                        </span>
                      </span>
                    )}

                    {from === HOME && !fromMsg && (
                      <div onClick={() => handleWishList()}>
                        <img
                          src={isSave ? SAVEICON : UNSAVEICON}
                          alt="save"
                          id="property-item-save"
                          width={14}
                          title={
                            !isSave
                              ? t(TRANSLATIONS.TITLES.SAVE)
                              : t(TRANSLATIONS.TITLES.UNSAVE)
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                {from === LISTING &&
                  selectedproperty?.closed_with_name &&
                  selectedproperty?.property_status === 'Closed' && (
                    <Box
                      sx={{
                        width: '100%',
                        my: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#7D7D7D',
                        }}
                      >
                        {t(TRANSLATIONS.TITLES.CLOSED_WITH)}
                      </Typography>
                      <Box
                        sx={{
                          background: '#F5F5F5',
                          fontSize: '14px',
                          ml: 1,
                          px: 1,
                          py: '2px',
                          borderRadius: '34px',
                        }}
                      >
                        {selectedproperty?.closed_with_name}
                      </Box>
                    </Box>
                  )}
                {chatUnlocked ? (
                  <div className="chat-unlocked-container">
                    <div className="chat-unlocked-content">
                      <img
                        src={GREENSUCCESSTICK}
                        className="chat-unlocked-success-tick-icon"
                        alt="success-tick"
                      />
                      <span className="chat-unlocked-title">
                        {t(TRANSLATIONS.TITLES.CHAT_UNLOCKED)}
                      </span>
                      <span className="chat-unlocked-subtitle">
                        {t(TRANSLATIONS.TITLES.CHAT_TO_KNOW_MORE)}
                      </span>
                    </div>
                  </div>
                ) : (
                  <>
                    {selectedproperty?.files &&
                      selectedproperty?.files.length > 0 && (
                        <div className="property-images">
                          <Box
                            className="property-active-image-container"
                            sx={{
                              minWidth: { lg: '550px', xs: '100%' },
                              minHeight: { lg: '350px', xs: '100%' },
                            }}
                          >
                            {from === LISTING && (
                              <Box
                                onClick={() =>
                                  coverImage !== activeImg?.file_id &&
                                  handleSetCoverImage(activeImg?.file_id)
                                }
                                sx={{
                                  position: 'absolute',
                                  top: 0,
                                  right: 0,
                                  background: '#0000005E',
                                  color: '#ffffff',
                                  cursor: 'pointer',
                                  pr: 1,
                                }}
                              >
                                <Checkbox
                                  sx={{ color: '#FFFFFF' }}
                                  checked={coverImage === activeImg?.file_id}
                                  color="default"
                                />{' '}
                                {` ${
                                  coverImage !== activeImg?.file_id
                                    ? 'Set as'
                                    : ''
                                } Cover image`}
                              </Box>
                            )}
                            {activeImg?.file_type?.startsWith('video') ? (
                              <video
                                src={activeImg?.file_url}
                                controls
                                height={'320px'}
                                style={{
                                  borderRadius: '12px',
                                }}
                              />
                            ) : (
                              <img
                                src={activeImg?.file_url}
                                alt="active-image"
                                className="active-property-img"
                              />
                            )}
                          </Box>
                          {selectedproperty?.files.length > 1 && (
                            <div className="property-images-list">
                              {selectedproperty?.files.map((item, index) => (
                                <div
                                  key={item.file_id}
                                  className="property-image-box"
                                  onClick={() => setActiveImg(item)}
                                >
                                  {item?.file_type?.startsWith('image') && (
                                    <img
                                      src={item.file_url}
                                      alt="active-image"
                                      style={{
                                        width: '125px',
                                        borderRadius: '12px',
                                        float: 'left',
                                        height: '100px',
                                        objectFit: 'cover',
                                      }}
                                    />
                                  )}

                                  {item?.file_type?.startsWith('video') && (
                                    <video
                                      src={item.file_url}
                                      controls
                                      style={{
                                        width: '125px',
                                        borderRadius: '12px',
                                        float: 'left',
                                        height: '100px',
                                        objectFit: 'cover',
                                      }}
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}

                    <div className="property-info">
                      <div className="property-info-stats"></div>
                      <div className="property-info-details">
                        <div className="property-info-details-list">
                          <Typography
                            className="property-info-title"
                            sx={{ fontSize: { lg: '20px', xs: '16px' } }}
                          >
                            {selectedproperty?.property_title}{' '}
                            {selectedproperty?.property_status ===
                              'Verified' && (
                              <img
                                src={SUCCESSTICK}
                                alt="view"
                                className="success-tick-icon"
                              />
                            )}
                          </Typography>
                          <span className="property-info-location">
                            {selectedproperty?.locality},{' '}
                            {selectedproperty?.city}
                          </span>
                          <Typography className="property-info-subtitle">
                            {selectedproperty?.property_type} |{' '}
                            {selectedproperty?.furnish_type} |{' '}
                            {selectedproperty?.built_up_area} sq.ft.
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            sx={{
                              fontSize: { lg: '22px', xs: '18px' },
                              fontWeight: 500,
                            }}
                          >
                            {selectedproperty?.sale_price
                              ? numDifferentiation(selectedproperty?.sale_price)
                              : numDifferentiation(
                                  selectedproperty?.rent_amount
                                )}
                            {selectedproperty?.property_sale_type === 'Rent' &&
                              ' p.m'}
                          </Typography>
                        </div>
                      </div>
                      <Box className="property-info-rooms">
                        {selectedproperty?.bedroom_count && (
                          <div className="property-rooms">
                            <span className="property-room-count">
                              {selectedproperty?.bedroom_count || 0}
                            </span>
                            <span className="property-room-type">
                              {t(TRANSLATIONS.TITLES.BEDROOMS)}
                            </span>
                          </div>
                        )}
                        {selectedproperty?.bathroom_count && (
                          <div className="property-rooms">
                            <span className="property-room-count">
                              {selectedproperty?.bathroom_count || 0}
                            </span>
                            <span className="property-room-type">
                              {t(TRANSLATIONS.TITLES.BATHROOMS)}
                            </span>
                          </div>
                        )}
                        {selectedproperty?.balcony_count && (
                          <div className="property-rooms">
                            <span className="property-room-count">
                              {selectedproperty?.balcony_count || 0}
                            </span>
                            <span className="property-room-type">
                              {' '}
                              {t(TRANSLATIONS.TITLES.BALCONY)}
                            </span>
                          </div>
                        )}
                      </Box>
                    </div>
                    {selectedproperty?.property_description && (
                      <div className="property-overview property-box-border">
                        <Typography
                          sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            color: '#474747',
                            marginTop: '8px',
                            padding: '0px 12px',
                          }}
                        >
                          {t(TRANSLATIONS.TITLES.INTERESTING_HIGHLIGHTS)}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            marginTop: '16px',
                            padding: '0px 12px',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: formatMessage(
                              selectedproperty?.property_description
                            ),
                          }}
                        />
                      </div>
                    )}

                    <Box
                      sx={{
                        marginTop: '32px',
                        border: '1px solid #D2D2D2',
                        borderRadius: '8px',
                        padding: '16px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: 600,
                          color: '#474747',
                          marginBottom: '28px',
                        }}
                      >
                        {t(TRANSLATIONS.TITLES.ESSENTIAL_INFORMATION)}
                      </Typography>

                      <Grid container spacing={4}>
                        {details?.map((detail, index) => (
                          <InfoItem
                            key={index}
                            label={detail.label}
                            value={detail.value}
                            hide={detail.hide}
                          />
                        ))}
                      </Grid>
                    </Box>

                    {selectedproperty?.amenities?.length > 0 && (
                      <div className="property-amenties">
                        <div className="property-box-border">
                          <Typography
                            sx={{
                              fontSize: '18px',
                              fontWeight: 600,
                              color: '#474747',
                              margin: '12px 0',
                              padding: '0px 12px',
                            }}
                          >
                            {t(TRANSLATIONS.TITLES.AMENITIES)}
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                            }}
                          >
                            {selectedproperty?.amenities?.map((item) => (
                              <div className="property-amenties-items">
                                <span className="property-amenties-value">
                                  {item}
                                </span>
                              </div>
                            ))}
                          </Box>
                        </div>
                      </div>
                    )}

                    {selectedproperty?.property_sale_type === 'Rent' && (
                      <div className=" property-rent-details">
                        {selectedproperty?.security_deposit && (
                          <div className="property-rent-row">
                            <span className="property-rent-details-label">
                              {t(TRANSLATIONS.TITLES.SECURITY_DEPOSIT)}
                            </span>
                            <span className="property-rent-details-value">
                              ₹ {selectedproperty?.security_deposit}
                            </span>
                          </div>
                        )}
                        {selectedproperty?.maintenance_amount && (
                          <div className="property-rent-row">
                            <span className="property-rent-details-label">
                              {t(TRANSLATIONS.TITLES.MAINTENANCE_AMOUNT)}
                            </span>
                            <span className="property-rent-details-value">
                              ₹ {selectedproperty?.maintenance_amount}
                            </span>
                          </div>
                        )}
                        {selectedproperty?.lock_in_period && (
                          <div className="property-rent-row">
                            <span className="property-rent-details-label">
                              Lock-in Period
                            </span>
                            <span className="property-rent-details-value">
                              {selectedproperty?.lock_in_period}
                            </span>
                          </div>
                        )}
                        {selectedproperty?.tenant_preferences?.length > 0 && (
                          <div className="property-rent-row">
                            <span className="property-rent-details-label">
                              {t(TRANSLATIONS.TITLES.TENANT_PREFERENCES)}
                            </span>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                              }}
                            >
                              {selectedproperty?.tenant_preferences?.map(
                                (item, index) => (
                                  <span className="property-rent-details-value">
                                    {item}{' '}
                                    {index <
                                    selectedproperty?.tenant_preferences
                                      ?.length -
                                      1
                                      ? ','
                                      : ''}
                                  </span>
                                )
                              )}
                            </Box>
                          </div>
                        )}
                      </div>
                    )}

                    {selectedproperty?.documents?.length > 0 && (
                      <div className="property-docs">
                        <div className="property-overview property-box-border">
                          <div className="property-overview-title">
                            {t(TRANSLATIONS.TITLES.DOCUMENT)}
                          </div>
                          {selectedproperty?.documents?.map((item) => (
                            <div
                              className="property-docs-items property-box-border"
                              onClick={() => handlePdfClick(item.file_url)}
                            >
                              <IconFileTypeDoc
                                size={68}
                                strokeWidth={1.5}
                                color="#9C9CA8"
                              />
                              <span className="property-doc-name">
                                {item.file_name}
                              </span>
                              <span className="property-doc-size">
                                {item.file_size}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Box>
            )
          )}

          {(from === HOME || from === SAVED || externalAgentId) &&
            !chatUnlocked &&
            selectedproperty?.property_status === 'Verified' &&
            !myleads && (
              <div className="property-detail-footer">
                {loading ? (
                  <Skeleton width={100} height={50} />
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    className="property-detail-btn"
                    color="primary"
                    onClick={handleUnlockChat}
                    sx={{
                      borderRadius: '8px',
                      marginY: '9px',
                      paddingY: '7px',
                    }}
                  >
                    {t(TRANSLATIONS.TITLES.UNLOCK_TO_CHAT)}
                  </Button>
                )}
              </div>
            )}

          {from === UNLOCK && !fromMsg && (
            <div className="property-detail-footer">
              {loading ? (
                <Skeleton width={100} height={50} />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  className="property-detail-btn"
                  color="primary"
                  onClick={handleMessageClick}
                  sx={{ borderRadius: '8px' }}
                >
                  {t(TRANSLATIONS.TITLES.MESSAGE)}
                </Button>
              )}
            </div>
          )}

          {from === LISTING &&
            !verificationSuccess &&
            selectedproperty?.property_status !== 'Verified' &&
            selectedproperty?.property_status !== 'Closed' && (
              <div className="property-detail-footer">
                {verificationIniated && (
                  <Typography
                    color={'#A03C42'}
                    sx={{ mb: 1, fontSize: '12px', textAlign: 'center' }}
                  >
                    {t(TRANSLATIONS.PARAGRAPHS.FAILURE_TO_GUIDELINES)}
                  </Typography>
                )}

                {loading ? (
                  <Skeleton width={100} height={50} />
                ) : (
                  !verificationIniated &&
                  selectedproperty?.property_status === 'Rejected' && (
                    <>
                      <Typography
                        color={'#A03C42'}
                        sx={{
                          mb: 1,
                          fontSize: '16px',
                          textAlign: 'center',
                          width: '100%',
                          background: '#FFF0CD',
                        }}
                      >
                        {t(TRANSLATIONS.TITLES.PROPERTY_REJECTED)}
                      </Typography>

                      {selectedproperty?.rejection_reason?.length > 0 &&
                        selectedproperty?.rejection_reason?.map((item) => {
                          return (
                            <Typography
                              color={'#A03C42'}
                              sx={{
                                mb: 1,
                                fontSize: '12px',
                                textAlign: 'left',
                                width: '100%',
                              }}
                            >
                              *{item}
                            </Typography>
                          );
                        })}
                    </>
                  )
                )}

                {!brokerStatus?.is_verified &&
                  !brokerStatus?.is_verification_initiated && (
                    <Typography
                      sx={{ mb: 1, fontSize: '12px', textAlign: 'center' }}
                    >
                      {t(
                        TRANSLATIONS.PARAGRAPHS
                          .COMPLETE_YOUR_PROFILE_FOR_PROP_VER
                      )}
                    </Typography>
                  )}

                {loading ? (
                  <Skeleton width={100} height={50} />
                ) : selectedproperty?.property_status === 'Initiated' ||
                  selectedproperty?.property_status === 'Re-Initiated' ? (
                  <span className="verification-pending-msg">
                    {t(TRANSLATIONS.PARAGRAPHS.VERIFICATION_PENDING_NOTE)}
                  </span>
                ) : !brokerStatus?.is_verified &&
                  brokerStatus?.is_verification_initiated ? (
                  <Typography
                    sx={{ mb: 1, py: 2, fontSize: '14px', textAlign: 'center' }}
                  >
                    {t(TRANSLATIONS.PARAGRAPHS.VERIFICATION_IN_PROGRESS_NOTE)}
                  </Typography>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    className="property-detail-btn"
                    color="primary"
                    disabled={verificationIniated && !videoFile}
                    sx={{ textTransform: 'capitalize', borderRadius: '8px' }}
                    onClick={handleVerifyProperty}
                  >
                    {verificationIniated
                      ? t(TRANSLATIONS.TITLES.SUBMIT)
                      : selectedproperty?.property_status === 'Rejected'
                      ? t(TRANSLATIONS.TITLES.RE_VERIFY_NOW)
                      : t(TRANSLATIONS.TITLES.VERIFY_NOW)}
                  </Button>
                )}
              </div>
            )}

          {from === LISTING &&
            selectedproperty?.property_status === 'Verified' &&
            !dealClosureInitiated && (
              <div className="property-detail-footer">
                {loading ? (
                  <Skeleton width={100} height={50} />
                ) : (
                  <Button
                    type="submit"
                    className="property-detail-btn"
                    color="primary"
                    variant="outlined"
                    disabled={verificationIniated && !videoFile}
                    sx={{ textTransform: 'capitalize', borderRadius: '8px' }}
                    onClick={() => setDealClosureInitiated(true)}
                  >
                    {t(TRANSLATIONS.TITLES.MARK_AS_CLOSED)}
                  </Button>
                )}
              </div>
            )}
        </Box>
      </Drawer>
      {uploadLoader && (
        <LoaderComponent
          text={[
            'Hang tight! Uploading your property video securely',
            'Processing your video to ensure everything is in order',
            'Verifying the quality of your video for seamless review',
            'Optimizing your video for the verification process',
          ]}
        />
      )}
    </div>
  );
}

const InfoItem = ({ label, value, hide }) => {
  if (hide) {
    return null;
  }

  return (
    <Grid item xs={6} lg={4}>
      <Typography
        sx={{ color: '#7D7D7D', fontFamily: 'Inter', fontSize: '14px' }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          color: '#474747',
          fontFamily: 'Inter',
          fontSize: '16px',
          marginTop: '8px',
        }}
      >
        {value}
      </Typography>
    </Grid>
  );
};
