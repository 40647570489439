import { Box, Divider, Grid, Modal, Typography } from '@mui/material';
import { IconX } from '@tabler/icons-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../Store/userSlice';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from '../Utils/translations';

export default function LanguageModal({ open, onClose }) {
  const { i18n, t } = useTranslation();
  const selectedLanguage = useSelector(
    (state) => state.userDetails?.language || 'english'
  );

  const dispatch = useDispatch();
  const languages = [
    { name: 'english', letter: 'A' },
    { name: 'hindi', letter: 'अ' },
    { name: 'kannada', letter: 'ಕ' },
    { name: 'tamil', letter: 'அ' },
    { name: 'telugu', letter: 'అ' },
    { name: 'bangla', letter: 'অ' },
  ];

  const handleSelectLanguage = (language) => {
    i18n.changeLanguage(language);
    dispatch(userActions.setLanguage(language));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: '8px',
          maxHeight: 800,
          position: 'absolute',
          left: { xs: '5%', lg: 'auto' },
          right: { lg: '10%', xs: '5%' },
          top: { lg: '10%', xs: '3%' },
          bottom: { xs: '3%', lg: 'auto' },
          width: { lg: '30%', xs: '90%' },
          overflowY: 'scroll',
          outline: 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '2rem 1rem 1rem 1rem',
          }}
        >
          <Typography
            sx={{
              marginRight: '1rem',
              color: '#474747',
              fontSize: '18px',
              fontWeight: '600',
            }}
          >
            {t(TRANSLATIONS.SUB_TITLES.CHOOSE_LANGUAGE)}
          </Typography>

          <IconX
            strokeWidth="1.5"
            size="32"
            style={{ cursor: 'pointer' }}
            onClick={() => onClose()}
          />
        </Box>

        <Box>
          <Divider sx={{ margin: '0  1rem' }} />
        </Box>

        <Grid
          container
          gap={1}
          sx={{ padding: '1rem', justifyContent: 'space-between' }}
        >
          {languages.map((lang) => (
            <Grid
              item
              lg={3.5}
              sm={5.5}
              xs={12}
              sx={{
                padding: '2rem',
                margin: '6px 0',
                border:
                  selectedLanguage === lang.name
                    ? '1.5px solid #600DB4'
                    : '1px solid #D2D2D2',
                borderRadius: '16px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
              onClick={() => handleSelectLanguage(lang.name)}
            >
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: '800',
                  color: selectedLanguage === lang.name ? '#600DB4' : '#7D7D7D',
                }}
              >
                {lang.letter}
              </Typography>
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  color: selectedLanguage === lang.name ? '#600DB4' : '#7D7D7D',
                }}
              >
                {lang.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Modal>
  );
}
