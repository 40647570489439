import { TRANSLATIONS } from '../translations';

const translationKannada = {
  // titles
  [TRANSLATIONS.TITLES.HOME]: 'ಮುಖಪುಟ',
  [TRANSLATIONS.TITLES.PROPERTIES]: 'ಆಸ್ತಿಗಳು',
  [TRANSLATIONS.TITLES.MESSAGES]: 'ಸಂದೇಶಗಳು',
  [TRANSLATIONS.TITLES.AGENT_ID]: 'ಏಜೆಂಟ್ ಐಡಿ',
  [TRANSLATIONS.TITLES.LOGOUT]: 'ಲಾಗ್ ಔಟ್',
  [TRANSLATIONS.TITLES.PROFILE]: 'ಪ್ರೊಫೈಲ್',
  [TRANSLATIONS.TITLES.ASK_AIRA]: 'ಐರಾಳನ್ನು ಕೇಳಿ',
  [TRANSLATIONS.TITLES.SAVED]: 'ಉಳಿಸಿದವು',
  [TRANSLATIONS.TITLES.UNLOCKED]: 'ಅನ್ಲಾಕ್ ಮಾಡಲಾಗಿದೆ',
  [TRANSLATIONS.TITLES.MY_LISTINGS]: 'ನನ್ನ ಪಟ್ಟಿಗಳು',
  [TRANSLATIONS.TITLES.LANGUAGE]: 'ಭಾಷೆ',
  [TRANSLATIONS.TITLES.SUBMIT]: 'ಸಲ್ಲಿಸು',
  [TRANSLATIONS.TITLES.CHANGE]: 'ಬದಲಾಯಿಸಿ',
  [TRANSLATIONS.TITLES.BACK]: 'ಹಿಂದೆ',
  [TRANSLATIONS.TITLES.DASHBOARD]: 'ಡ್ಯಾಶ್ಬೋರ್ಡ್',
  [TRANSLATIONS.TITLES.SAVE]: 'ಉಳಿಸಿ',
  [TRANSLATIONS.TITLES.CANCEL]: 'ರದ್ದುಮಾಡಿ',
  [TRANSLATIONS.TITLES.SHARE_FEEDBACK]: 'ಪ್ರತಿಕ್ರಿಯೆ ಹಂಚಿಕೊಳ್ಳಿ',
  [TRANSLATIONS.TITLES.CITY]: 'ನಗರ',
  [TRANSLATIONS.TITLES.LOCALITY]: 'ಪ್ರದೇಶ',
  [TRANSLATIONS.TITLES.LOOKING_FOR]: 'ಏನು ಹುಡುಕುತ್ತಿದ್ದೀರಿ',
  [TRANSLATIONS.TITLES.PROPERTY_TYPE]: 'ಆಸ್ತಿ ಪ್ರಕಾರ',
  [TRANSLATIONS.TITLES.BEDROOM]: 'ಬೆಡ್‌ರೂಮ್',
  [TRANSLATIONS.TITLES.BEDROOMS]: 'ಬೆಡ್‌ರೂಮ್‌ಗಳು',
  [TRANSLATIONS.TITLES.FILTERS]: 'ಫಿಲ್ಟರ್‌ಗಳು',
  [TRANSLATIONS.TITLES.FURNISHING]: 'ಫರ್ನಿಷಿಂಗ್',
  [TRANSLATIONS.TITLES.BUILD_UP_AREA]: 'ನಿರ್ಮಿಸಿದ ಪ್ರದೇಶ',
  [TRANSLATIONS.TITLES.BUDGET]: 'ಬಜೆಟ್',
  [TRANSLATIONS.TITLES.CLEAR_ALL]: 'ಎಲ್ಲಾ ತೆರವುಗೊಳಿಸಿ',
  [TRANSLATIONS.TITLES.APPLY]: 'ಅನ್ವಯಿಸು',
  [TRANSLATIONS.TITLES.MIN_BUILD_UP]: 'ಕನಿಷ್ಠ ನಿರ್ಮಿಸಿದ ಪ್ರದೇಶ',
  [TRANSLATIONS.TITLES.MAX_BUILD_UP]: 'ಗರಿಷ್ಠ ನಿರ್ಮಿಸಿದ ಪ್ರದೇಶ',
  [TRANSLATIONS.TITLES.FILTER]: 'ಫಿಲ್ಟರ್',
  [TRANSLATIONS.TITLES.AMENITIES]: 'ಸೌಲಭ್ಯಗಳು',
  [TRANSLATIONS.TITLES.NOTIFICATIONS]: 'ಅಧಿಸೂಚನೆಗಳು',
  [TRANSLATIONS.TITLES.BATHROOMS]: 'ಸ್ನಾನಗೃಹಗಳು',
  [TRANSLATIONS.TITLES.MESSAGE]: 'ಸಂದೇಶ',
  [TRANSLATIONS.TITLES.NAME]: 'ಹೆಸರು',
  [TRANSLATIONS.TITLES.PHONE_NUMBER]: 'ಫೋನ್ ಸಂಖ್ಯೆ',
  [TRANSLATIONS.TITLES.EMAIL]: 'ಇಮೇಲ್',
  [TRANSLATIONS.TITLES.LANGUAGE_S]: 'ಭಾಷೆ(ಗಳು)',
  [TRANSLATIONS.TITLES.REMOVE]: 'ತೆಗೆದುಹಾಕಿ',
  [TRANSLATIONS.TITLES.ADDRESS]: 'ವಿಳಾಸ',
  [TRANSLATIONS.TITLES.AGENT]: 'ಏಜೆಂಟ್',
  [TRANSLATIONS.TITLES.AREA]: 'ಪ್ರದೇಶ',
  [TRANSLATIONS.TITLES.VERIFY]: 'ಪರಿಶೀಲಿಸಿ',
  [TRANSLATIONS.TITLES.PROCEED]: 'ಮುಂದುವರಿಸಿ',
  [TRANSLATIONS.TITLES.STATE]: 'ರಾಜ್ಯ',
  [TRANSLATIONS.TITLES.PINCODE]: 'ಪಿನ್‌ಕೋಡ್',
  [TRANSLATIONS.TITLES.SELECT]: 'ಆಯ್ಕೆಮಾಡಿ',
  [TRANSLATIONS.TITLES.RERA_NUMBER]: 'RERA ಸಂಖ್ಯೆ',
  [TRANSLATIONS.TITLES.ADD_MORE_DETAILS]: 'ಹೆಚ್ಚಿನ ವಿವರಗಳನ್ನು ಸೇರಿಸಿ',
  [TRANSLATIONS.TITLES.RETAKE_REUPLOAD_PHOTO]:
    'ಮರುತೆಗೆದುಕೊಳ್ಳಿ / ಮರು-ಅಪ್ಲೋಡ್ ಫೋಟೋ',
  [TRANSLATIONS.TITLES.CLOSED_DEALS]: 'ಮುಚ್ಚಿದ ಒಪ್ಪಂದಗಳು',
  [TRANSLATIONS.TITLES.ACTIVE_LISTING]: 'ಸಕ್ರಿಯ ಪಟ್ಟಿ',
  [TRANSLATIONS.TITLES.VERIFICATION]: 'ಪರಿಶೀಲನೆ',
  [TRANSLATIONS.TITLES.ABOUT]: 'ಬಗ್ಗೆ',
  [TRANSLATIONS.TITLES.CLOSED]: 'ಮುಚ್ಚಲಾಗಿದೆ',
  [TRANSLATIONS.TITLES.AVAILABILITY]: 'ಲಭ್ಯತೆ',
  [TRANSLATIONS.TITLES.ACCESS]: 'ಪ್ರವೇಶ',
  [TRANSLATIONS.TITLES.TRY_AGAIN]: 'ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ',
  [TRANSLATIONS.TITLES.LATITUDE]: 'ಅಕ್ಷಾಂಶ',
  [TRANSLATIONS.TITLES.LONGITUDE]: 'ರೇಖಾಂಶ',
  [TRANSLATIONS.TITLES.RE_VERIFY]: 'ಮರು-ಪರಿಶೀಲಿಸಿ',
  [TRANSLATIONS.TITLES.HIDE]: 'ಮರೆಮಾಡಿ',
  [TRANSLATIONS.TITLES.VIEW_ALL]: 'ಎಲ್ಲಾ ವೀಕ್ಷಿಸಿ',
  [TRANSLATIONS.TITLES.FIND_PROPERTY]: 'ಮನೆ/ಜಾಗ ಹುಡುಕಿ',
  [TRANSLATIONS.TITLES.TAP_THE]: 'ಟ್ಯಾಪ್ ಮಾಡಿ',
  [TRANSLATIONS.TITLES.WE_FOUND]: 'ನಾವು ಕಂಡುಕೊಂಡಿದ್ದೇವೆ',
  [TRANSLATIONS.TITLES.MATCHES_INSTANTLY]: 'ತಕ್ಷಣ ಹೊಂದಾಣಿಕೆಗಳು',
  [TRANSLATIONS.TITLES.WE_ARE_ON_IT]: 'ನಾವು ಅದರ ಮೇಲೆ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೇವೆ!',
  [TRANSLATIONS.TITLES.SELECT_CITY]: 'ನಗರವನ್ನು ಆಯ್ಕೆಮಾಡಿ',
  [TRANSLATIONS.TITLES.TYPE]: 'ಪ್ರಕಾರ',
  [TRANSLATIONS.TITLES.FIND]: 'ಹುಡುಕಿ',
  [TRANSLATIONS.TITLES.BALCONIES]: 'ಬಾಲ್ಕನಿಗಳು',
  [TRANSLATIONS.TITLES.PROPERTY_CATEGORY]: 'ಮನೆ/ಜಾಗದ ವರ್ಗ',
  [TRANSLATIONS.TITLES.OCCUPANCY_STATUS]: 'ಆಕ್ಯುಪೆನ್ಸಿ ಸ್ಥಿತಿ',
  [TRANSLATIONS.TITLES.CARPET_AREA]: 'ಕಾರ್ಪೆಟ್ ಪ್ರದೇಶ',
  [TRANSLATIONS.TITLES.TOTAL_FLOOR]: 'ಒಟ್ಟು ಮಹಡಿ',
  [TRANSLATIONS.TITLES.PROPERTY_ON_FLOOR]: 'ಮನೆ/ಜಾಗದ ಮಹಡಿ',
  [TRANSLATIONS.TITLES.PROPERTY_FACING]: 'ಮನೆ/ಜಾಗದ ಮುಖ',
  [TRANSLATIONS.TITLES.BUILT_IN_YEAR]: 'ನಿರ್ಮಾಣದ ವರ್ಷ',
  [TRANSLATIONS.TITLES.PROPERTY_NAME]: 'ಮನೆ/ಜಾಗದ ಹೆಸರು',
  [TRANSLATIONS.TITLES.LIFT]: 'ಲಿಫ್ಟ್',
  [TRANSLATIONS.TITLES.CAR_PARKING]: 'ಕಾರ್ ಪಾರ್ಕಿಂಗ್',
  [TRANSLATIONS.TITLES.FURNISHING_TYPE]: 'ಫರ್ನಿಶಿಂಗ್ ಪ್ರಕಾರ',
  [TRANSLATIONS.TITLES.PROPERTY_VALUE]: 'ಮನೆ/ಜಾಗದ ಮೌಲ್ಯ',
  [TRANSLATIONS.TITLES.LOCK_IN_PERIOD]: 'ಲಾಕ್-ಇನ್ ಅವಧಿ',
  [TRANSLATIONS.TITLES.RENT_AMOUNT]: 'ಬಾಡಿಗೆ ಮೊತ್ತ',
  [TRANSLATIONS.TITLES.SECURITY_DEPOSIT]: 'ಭದ್ರತಾ ಠೇವಣಿ',
  [TRANSLATIONS.TITLES.MAINTENANCE_AMOUNT]: 'ನಿರ್ವಹಣೆ ಮೊತ್ತ',
  [TRANSLATIONS.TITLES.RENT_AVAILABLE_FROM]: 'ಬಾಡಿಗೆ ಲಭ್ಯವಿದೆ',
  [TRANSLATIONS.TITLES.PROPERTY_ADDED_ON]: 'ಮನೆ/ಜಾಗ ಸೇರಿಸಲಾಗಿದೆ',
  [TRANSLATIONS.TITLES.TENANT_PREFERENCES]: 'ಬಾಡಿಗೆದಾರರ ಆದ್ಯತೆಗಳು',
  [TRANSLATIONS.TITLES.UNSAVE]: 'ಅನ್ಸೇವ್ ಮಾಡಿ',
  [TRANSLATIONS.TITLES.CLOSED_WITH]: 'ಜೊತೆ ಮುಚ್ಚಲಾಗಿದೆ',
  [TRANSLATIONS.TITLES.CHAT_UNLOCKED]: 'ಚಾಟ್ ಅನ್ಲಾಕ್ ಆಗಿದೆ!',
  [TRANSLATIONS.TITLES.BALCONY]: 'ಬಾಲ್ಕನಿ',
  [TRANSLATIONS.TITLES.INTERESTING_HIGHLIGHTS]: 'ಆಸಕ್ತಿದಾಯಕ ಹೈಲೈಟ್ಸ್',
  [TRANSLATIONS.TITLES.ESSENTIAL_INFORMATION]: 'ಅಗತ್ಯ ಮಾಹಿತಿ',
  [TRANSLATIONS.TITLES.DOCUMENT]: 'ದಾಖಲೆ',
  [TRANSLATIONS.TITLES.UNLOCK_TO_CHAT]: 'ಚಾಟ್ ಮಾಡಲು ಅನ್ಲಾಕ್ ಮಾಡಿ',
  [TRANSLATIONS.TITLES.PROPERTY_REJECTED]: 'ಮನೆ/ಜಾಗ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ',
  [TRANSLATIONS.TITLES.VERIFY_NOW]: 'ಈಗ ಪರಿಶೀಲಿಸಿ',
  [TRANSLATIONS.TITLES.RE_VERIFY_NOW]: 'ಈಗ ಮರು-ಪರಿಶೀಲಿಸಿ',
  [TRANSLATIONS.TITLES.MARK_AS_CLOSED]: 'ಮುಚ್ಚಿದೆ ಎಂದು ಗುರುತಿಸಿ',
  [TRANSLATIONS.TITLES.PREVIEW_AND_PERFECT]: 'ಮುನ್ನೋಟ ಮತ್ತು ಪರಿಪೂರ್ಣಗೊಳಿಸಿ',
  [TRANSLATIONS.TITLES.EDIT_PROFILE]: 'ಪ್ರೊಫೈಲ್ ಸಂಪಾದಿಸಿ',
  [TRANSLATIONS.TITLES.SHARE_PROFILE]: 'ಪ್ರೊಫೈಲ್ ಹಂಚಿಕೊಳ್ಳಿ',
  [TRANSLATIONS.TITLES.RE_INITIATE_VERIFICATION]: 'ಮರು-ಪರಿಶೀಲನೆ ಪ್ರಾರಂಭಿಸಿ',
  [TRANSLATIONS.TITLES.VERIFICATION_IN_PROGRESS]: 'ಪರಿಶೀಲನೆ ಪ್ರಗತಿಯಲ್ಲಿದೆ',
  [TRANSLATIONS.TITLES.VERIFICATION_REJECTED]: 'ಪರಿಶೀಲನೆ ನಿರಾಕರಿಸಲಾಗಿದೆ',
  [TRANSLATIONS.TITLES.ADDITIONAL_SERVICES]: 'ಹೆಚ್ಚುವರಿ ಸೇವೆಗಳು',
  [TRANSLATIONS.TITLES.GENERATE]: 'ಉತ್ಪಾದಿಸಿ',
  [TRANSLATIONS.TITLES.ADD_CITY]: 'ನಗರವನ್ನು ಸೇರಿಸಿ',
  [TRANSLATIONS.TITLES.LISTING_TYPE]: 'ಪಟ್ಟಿಯ ಪ್ರಕಾರ',
  [TRANSLATIONS.TITLES.COMMERCIAL_PARKING]: 'ವಾಣಿಜ್ಯ ಪಾರ್ಕಿಂಗ್',
  [TRANSLATIONS.TITLES.ADD_AMENITY]: 'ಸೌಲಭ್ಯವನ್ನು ಸೇರಿಸಿ',
  [TRANSLATIONS.TITLES.ADD]: 'ಸೇರಿಸಿ',
  [TRANSLATIONS.TITLES.SKIP]: 'ಹಿಡಿಯಿರಿ',
  [TRANSLATIONS.TITLES.NEXT]: 'ಮುಂದೆ',
  [TRANSLATIONS.TITLES.MY_LEADS]: 'ನನ್ನ ಲೀಡ್ಸ್',
  [TRANSLATIONS.TITLES.DONE]: 'ಮುಗಿಯಿತು',
  [TRANSLATIONS.TITLES.WELCOME_TO]: 'ಸ್ವಾಗತ',
  [TRANSLATIONS.TITLES.VIEW_PROFILE]: 'ಪ್ರೊಫೈಲ್ ವೀಕ್ಷಿಸಿ',
  [TRANSLATIONS.TITLES.DEALS_CLOSED]: 'ಒಪ್ಪಂದಗಳು ಮುಗಿದಿವೆ',
  [TRANSLATIONS.TITLES.YEARS]: 'ವರ್ಷಗಳು',
  [TRANSLATIONS.TITLES.FRESHER]: 'ಹೊಸಬ',
  [TRANSLATIONS.TITLES.SPECIALIZATION]: 'ವಿಶೇಷತೆ',
  [TRANSLATIONS.TITLES.AGENTS]: 'ಏಜೆಂಟ್‌ಗಳು',
  [TRANSLATIONS.TITLES.TOP]: 'ಅತ್ಯುತ್ತಮ',
  [TRANSLATIONS.TITLES.SHARE_NOW]: 'ಈಗ ಹಂಚಿಕೊಳ್ಳಿ',
  [TRANSLATIONS.TITLES.SEARCH]: 'ಹುಡುಕಿ',
  [TRANSLATIONS.TITLES.BUY_PURCHASE]: 'ಖರೀದಿಸಿ/ಮಾಲಿಕೆ ಪಡೆಯಿರಿ',
  [TRANSLATIONS.TITLES.VERIFYING_OTP]: 'OTP ಪರಿಶೀಲಿಸಲಾಗುತ್ತಿದೆ',
  [TRANSLATIONS.TITLES.RESEND_CODE]: 'ಕೋಡ್ ಮರುಹಂಚಿ',
  [TRANSLATIONS.TITLES.CONGRATULATIONS]: 'ಅಭಿನಂದನೆಗಳು',
  [TRANSLATIONS.TITLES.SEARCH_MESSAGES]: 'ಸಂದೇಶಗಳನ್ನು ಹುಡುಕಿ',
  [TRANSLATIONS.TITLES.UPLOAD_DOC]: 'ದಸ್ತಾವೇಜು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ',
  [TRANSLATIONS.TITLES.UNLOCKS]: 'ಅನ್‌ಲಾಕ್‌ಗಳು',
  [TRANSLATIONS.TITLES.LOCATION]: 'ಸ್ಥಳ',
  [TRANSLATIONS.TITLES.INFO]: 'ಮಾಹಿತಿ',
  [TRANSLATIONS.TITLES.BROKERAGE]: 'ದಲಾಲಿ',
  [TRANSLATIONS.TITLES.COMPANY]: 'ಕಂಪನಿ',
  [TRANSLATIONS.TITLES.DESCRIPTION]: 'ವಿವರಣೆ',
  [TRANSLATIONS.TITLES.SAVING_CHANGES]: 'ಬದಲಾವಣೆಗಳನ್ನು ಉಳಿಸಲಾಗುತ್ತಿದೆ...',
  [TRANSLATIONS.TITLES.REGENERATE]: 'ಮರುಸೃಷ್ಟಿಸಿ',
  [TRANSLATIONS.TITLES.REGENERATING_DESCRIPTION]:
    'ವಿವರಣೆಯನ್ನು ಮರುಸೃಷ್ಟಿಸಲಾಗುತ್ತಿದೆ...',
  [TRANSLATIONS.TITLES.ENTER_DESCRIPTION]: 'ವಿವರಣೆಯನ್ನು ನಮೂದಿಸಿ',

  // sub titles
  [TRANSLATIONS.SUB_TITLES.PROPERTY_VERIFICATION]: 'ಆಸ್ತಿ ಪರಿಶೀಲನೆ',
  [TRANSLATIONS.SUB_TITLES.SELECTED_COORDINATES]: 'ಆಯ್ಕೆಮಾಡಿದ ನಿರ್ದೇಶಾಂಕಗಳು',
  [TRANSLATIONS.SUB_TITLES.LOCATION_PIN_REQUIRED]: 'ಸ್ಥಳ ಪಿನ್ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.SUB_TITLES.PAGE_NOT_FOUND]: 'ಪುಟ ಕಂಡುಬಂದಿಲ್ಲ',
  [TRANSLATIONS.SUB_TITLES.FIND_CONNECT_CLOSE_FASTER]:
    'ಹುಡುಕಿ, ಸಂಪರ್ಕಿಸಿ, ವೇಗವಾಗಿ ಮುಚ್ಚಿ.',
  [TRANSLATIONS.SUB_TITLES.PASTE_PROPERTY_DETAILS_HERE]:
    'ನಿಮ್ಮ ಆಸ್ತಿಯ ವಿವರಗಳನ್ನು ಇಲ್ಲಿ ಅಂಟಿಸಿ',
  [TRANSLATIONS.SUB_TITLES.AUTO_FILL_WITH]: 'ಸ್ವಯಂ ಭರ್ತಿ ಮಾಡಿ',
  [TRANSLATIONS.SUB_TITLES.FILTER_S_ADDED]: 'ಫಿಲ್ಟರ್(ಗಳು) ಸೇರಿಸಲಾಗಿದೆ',
  [TRANSLATIONS.SUB_TITLES.AREAS_OF_OPERATION]: 'ಕಾರ್ಯಾಚರಣೆಯ ಪ್ರದೇಶಗಳು',
  [TRANSLATIONS.SUB_TITLES.LEGAL_BUSINESS_NAME]: 'ಕಾನೂನು ವ್ಯಾಪಾರ ಹೆಸರು',
  [TRANSLATIONS.SUB_TITLES.BRAND_NAME]: 'ಬ್ರಾಂಡ್ ಹೆಸರು',
  [TRANSLATIONS.SUB_TITLES.GST_NUMBER]: 'GST ಸಂಖ್ಯೆ',
  [TRANSLATIONS.SUB_TITLES.SOCIAL_LINKS]: 'ಸಾಮಾಜಿಕ ಲಿಂಕ್‌ಗಳು',
  [TRANSLATIONS.SUB_TITLES.INSTAGRAM_PAGE_LINK]: 'ಇನ್ಸ್ಟಾಗ್ರಾಮ್ ಪುಟ ಲಿಂಕ್',
  [TRANSLATIONS.SUB_TITLES.FACEBOOK_PAGE_LINK]: 'ಫೇಸ್‌ಬುಕ್ ಪುಟ ಲಿಂಕ್',
  [TRANSLATIONS.SUB_TITLES.LINKEDIN_PAGE_LINK]: 'ಲಿಂಕ್‌ಡಿನ್ ಪುಟ ಲಿಂಕ್',
  [TRANSLATIONS.SUB_TITLES.YOUTUBE_PAGE_LINK]: 'ಯೂಟ್ಯೂಬ್ ಪುಟ ಲಿಂಕ್',
  [TRANSLATIONS.SUB_TITLES.PHONE_NUMBER_OF_THE_AGENT]: 'ಏಜೆಂಟ್‌ನ ಫೋನ್ ಸಂಖ್ಯೆ',
  [TRANSLATIONS.SUB_TITLES.ADD_A_PROPERTY]: 'ಆಸ್ತಿಯನ್ನು ಸೇರಿಸಿ',
  [TRANSLATIONS.SUB_TITLES.CONNECT_SMARTER]: 'ಚುರುಕಾಗಿ ಸಂಪರ್ಕಿಸಿ,',
  [TRANSLATIONS.SUB_TITLES.CLOSE_FASTER]: 'vREfyd ನೊಂದಿಗೆ ವೇಗವಾಗಿ ಮುಚ್ಚಿ.',
  [TRANSLATIONS.SUB_TITLES.AIRA_FIND]: 'ನೀವು ಹುಡುಕುತ್ತಿರುವುದನ್ನು ಹುಡುಕಿ...',
  [TRANSLATIONS.SUB_TITLES.NO_PROPERTY]:
    'ನೀವು ಇನ್ನೂ ಯಾವುದೇ ಆಸ್ತಿಯನ್ನು ಸೇರಿಸಿಲ್ಲ',
  [TRANSLATIONS.SUB_TITLES.NOT_JUST_PART_OF_SYSTEM]:
    'ನೀವು ಕೇವಲ ವ್ಯವಸ್ಥೆಯ ಭಾಗವಲ್ಲ.',
  [TRANSLATIONS.SUB_TITLES.YOU_ARE_THE_SYSTEM]: 'ನೀವೇ ವ್ಯವಸ್ಥೆ!',
  [TRANSLATIONS.SUB_TITLES.JOIN_COMMUNITY]:
    'ಭಾರತದ ಅತ್ಯಂತ ವಿಶ್ವಸನೀಯ ರಿಯಲ್ ಎಸ್ಟೇಟ್ ಸಮುದಾಯವನ್ನು ಸೇರಿಕೊಳ್ಳಿ',
  [TRANSLATIONS.SUB_TITLES.WELCOME_TO_VREFYD]: 'vREfyd ಗೆ ಸ್ವಾಗತ',
  [TRANSLATIONS.SUB_TITLES.LETS_BEGIN]: 'ನಿಮ್ಮ ಫೋನ್ ಸಂಖ್ಯೆಯೊಂದಿಗೆ ಪ್ರಾರಂಭಿಸೋಣ',
  [TRANSLATIONS.SUB_TITLES.OTP_NOT_RECEIVED]: 'OTP ಸ್ವೀಕರಿಸಲಿಲ್ಲವೇ? ಮರುಕಳುಹಿಸಿ',
  [TRANSLATIONS.SUB_TITLES.ENTER_VERIFICATION_CODE]:
    'ನಿಮ್ಮ ಫೋನ್‌ಗೆ ಕಳುಹಿಸಲಾದ ಪರಿಶೀಲನೆ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ',
  [TRANSLATIONS.SUB_TITLES.PLEASE_CHECK_WHATSAPP_OR_SMS]:
    'ದಯವಿಟ್ಟು ಪರಿಶೀಲನೆ ಕೋಡ್‌ಗಾಗಿ WhatsApp/SMS ಪರಿಶೀಲಿಸಿ',
  [TRANSLATIONS.SUB_TITLES.LIST_MY_PROPERTY]: 'ನನ್ನ ಆಸ್ತಿಯನ್ನು ಪಟ್ಟಿ ಮಾಡಿ',
  [TRANSLATIONS.SUB_TITLES.CHOOSE_LANGUAGE]: 'ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ',
  [TRANSLATIONS.SUB_TITLES.LOADING_NOTIFICATIONS]:
    'ಅಧಿಸೂಚನೆಗಳನ್ನು ಲೋಡ್ ಮಾಡಲಾಗುತ್ತಿದೆ....',
  [TRANSLATIONS.SUB_TITLES.NO_NOTIFICATION_FOUND]:
    'ಯಾವುದೇ ಅಧಿಸೂಚನೆ ಕಂಡುಬಂದಿಲ್ಲ',
  [TRANSLATIONS.SUB_TITLES.BY_SUBMITTING]:
    'ಸಲ್ಲಿಸುವ ಮೂಲಕ ನೀವು ನಮ್ಮ ಗೌಪ್ಯತಾ ನೀತಿಗೆ ಸಮ್ಮತಿಸುತ್ತೀರಿ',
  [TRANSLATIONS.SUB_TITLES.PRIVACY_POLICY]: 'ಗೌಪ್ಯತಾ ನೀತಿ',
  [TRANSLATIONS.SUB_TITLES.AND]: 'ಮತ್ತು',
  [TRANSLATIONS.SUB_TITLES.TERMS_OF_USE]: 'ಬಳಕೆಯ ನಿಯಮಗಳು',
  [TRANSLATIONS.SUB_TITLES.LETS_GET_TO_KNOW_YOU]: 'ನಿಮ್ಮನ್ನು ತಿಳಿದುಕೊಳ್ಳೋಣ',
  [TRANSLATIONS.SUB_TITLES.YEARS_OF_EXPERIENCE]: 'ಅನುಭವದ ವರ್ಷಗಳು',
  [TRANSLATIONS.SUB_TITLES.HIGHLIGHT_YOUR_SUPERPOWERS]:
    'ನಿಮ್ಮ ಸೂಪರ್‌ಪವರ್‌ಗಳನ್ನು ಹೈಲೈಟ್ ಮಾಡಿ',
  [TRANSLATIONS.SUB_TITLES.MY_SPECIALIZATION]: 'ನನ್ನ ವಿಶೇಷತೆ',
  [TRANSLATIONS.SUB_TITLES.OTHER_SERVICES]: 'ಇತರ ಸೇವೆಗಳು',
  [TRANSLATIONS.SUB_TITLES.SPEAK_THEIR_LANGUAGE]: 'ಅವರ ಭಾಷೆಯಲ್ಲಿ ಮಾತನಾಡಿ',
  [TRANSLATIONS.SUB_TITLES.DEFINE_YOUR_AREA_OF_OPERATION]:
    'ನಿಮ್ಮ ಕಾರ್ಯಾಚರಣೆಯ ಪ್ರದೇಶವನ್ನು ವ್ಯಾಖ್ಯಾನಿಸಿ',
  [TRANSLATIONS.SUB_TITLES.ADD_ANOTHER_CITY]: 'ಮತ್ತೊಂದು ನಗರವನ್ನು ಸೇರಿಸಿ',
  [TRANSLATIONS.SUB_TITLES.SHOWCASE_YOUR_BRAND]:
    'ನಿಮ್ಮ ಬ್ರಾಂಡ್ ಅನ್ನು ಪ್ರದರ್ಶಿಸಿ',
  [TRANSLATIONS.SUB_TITLES.BUSINESS_DETAILS]: 'ವ್ಯಾಪಾರ ವಿವರಗಳು',
  [TRANSLATIONS.SUB_TITLES.IMAGE_OF_VISITING_CARD]: 'ವಿಜಿಟಿಂಗ್ ಕಾರ್ಡ್‌ನ ಚಿತ್ರ',
  [TRANSLATIONS.SUB_TITLES.IMAGE_OF_SHOP_ESTABLISHMENT]:
    'ಅಂಗಡಿ / ಸ್ಥಾಪನೆಯ ಚಿತ್ರ',
  [TRANSLATIONS.SUB_TITLES.COMPANY_PAN_CARD_GST_CERTIFICATE]:
    'ಕಂಪನಿ PAN ಕಾರ್ಡ್ / GST ಪ್ರಮಾಣಪತ್ರ',
  [TRANSLATIONS.SUB_TITLES.VERIFIED_AGENT_VERIFICATION]:
    'ಪರಿಶೀಲಿತ ಏಜೆಂಟ್ ಪರಿಶೀಲನೆ',
  [TRANSLATIONS.SUB_TITLES.FASTEST_IMMEDIATE_VERIFICATION]:
    'ವೇಗವಾದ - ತ್ವರಿತ ಪರಿಶೀಲನೆ',
  [TRANSLATIONS.SUB_TITLES.BUSINESS_VERIFICATION]: 'ವ್ಯಾಪಾರ ಪರಿಶೀಲನೆ',
  [TRANSLATIONS.SUB_TITLES.RERA_VERIFICATION]: 'RERA ಪರಿಶೀಲನೆ',
  [TRANSLATIONS.SUB_TITLES.COMMUNITY_VERIFICATION]: 'ಸಮುದಾಯ ಪರಿಶೀಲನೆ',
  [TRANSLATIONS.SUB_TITLES.UPLOAD_DOCUMENTS]: 'ದಾಖಲೆಗಳನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ',
  [TRANSLATIONS.SUB_TITLES.LETS_CAPTURE_YOUR_BEST_LOOK]:
    'ನಿಮ್ಮ ಉತ್ತಮ ನೋಟವನ್ನು ಕ್ಯಾಪ್ಚರ್ ಮಾಡೋಣ',
  [TRANSLATIONS.SUB_TITLES.POSITION_YOUR_FACE_IN_THE_SQUARE_BELOW]:
    'ನಿಮ್ಮ ಮುಖವನ್ನು ಕೆಳಗಿನ ಚೌಕದಲ್ಲಿ ಇರಿಸಿ',
  [TRANSLATIONS.SUB_TITLES.SAMPLE_PROFILE_PREVIEW]:
    'ಮಾದರಿ ಪ್ರೊಫೈಲ್ ಪೂರ್ವವೀಕ್ಷಣೆ',
  [TRANSLATIONS.SUB_TITLES.SAMPLE_PROFILE_APPEARS]:
    'ಮಾದರಿ ಪ್ರೊಫೈಲ್ – ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಹೇಗೆ ಕಾಣುತ್ತದೆ',
  [TRANSLATIONS.SUB_TITLES.BACK_TO_HOME]: 'ಮುಖಪುಟಕ್ಕೆ ಹಿಂತಿರುಗಿ',
  [TRANSLATIONS.SUB_TITLES.DEAL_CLOSED_OUTSIDE]:
    'ಒಪ್ಪಂದವನ್ನು ಹೊರಗೆ ಮುಚ್ಚಲಾಗಿದೆ',
  [TRANSLATIONS.SUB_TITLES.VERIFICATION_INITIATED]: 'ಪರಿಶೀಲನೆ ಪ್ರಾರಂಭಿಸಲಾಗಿದೆ!',
  [TRANSLATIONS.SUB_TITLES.PROPERTY_VERIFICATION_FAILED]:
    'ಆಸ್ತಿ ಪರಿಶೀಲನೆ ವಿಫಲವಾಗಿದೆ',
  [TRANSLATIONS.SUB_TITLES.DUPLICATE_LISTING_DETECTED]:
    'ನಕಲಿ ಪಟ್ಟಿ ಪತ್ತೆಯಾಗಿದೆ',
  [TRANSLATIONS.SUB_TITLES.IMAGE_NOT_AVAILABLE]: 'ಚಿತ್ರ ಲಭ್ಯವಿಲ್ಲ',
  [TRANSLATIONS.SUB_TITLES.SAVE_FAV_PROP]: 'ನಿಮ್ಮ ಮೆಚ್ಚಿನ ಆಸ್ತಿಗಳನ್ನು ಉಳಿಸಿ',
  [TRANSLATIONS.SUB_TITLES.TAP_TO_SAVE]:
    'ಯಾವುದೇ ಆಸ್ತಿಯನ್ನು ನಿಮ್ಮ ಉಳಿಸಿದ ಆಸ್ತಿಗಳಿಗೆ ಸೇರಿಸಲು ಟ್ಯಾಪ್ ಮಾಡಿ',
  [TRANSLATIONS.SUB_TITLES.PERSONALIZE_SEARCH]:
    'ನಿಮ್ಮ ಹುಡುಕಾಟವನ್ನು ವೈಯಕ್ತೀಕರಿಸಲು ಹೆಚ್ಚಿನ ಫಿಲ್ಟರ್ಗಳನ್ನು ಅನ್ವಯಿಸಿ',
  [TRANSLATIONS.SUB_TITLES.SEARCH_FOR_LOCALITY]: 'ಪ್ರದೇಶಕ್ಕಾಗಿ ಹುಡುಕಿ',
  [TRANSLATIONS.SUB_TITLES.PURCHASE_RENT]: 'ಖರೀದಿಸಿ/ಬಾಡಿಗೆಗೆ ತೆಗೆದುಕೊಳ್ಳಿ',
  [TRANSLATIONS.SUB_TITLES.ADVANCE_FILTERS]: 'ಸುಧಾರಿತ ಫಿಲ್ಟರ್ಗಳು',
  [TRANSLATIONS.SUB_TITLES.PROPERTY_SALE_TYPE]: 'ಆಸ್ತಿ ಮಾರಾಟ ಪ್ರಕಾರ',
  [TRANSLATIONS.SUB_TITLES.CHAT_TO_KNOW_MORE]:
    'ಆಸ್ತಿಯ ಬಗ್ಗೆ ಹೆಚ್ಚು ತಿಳಿಯಲು ಚಾಟ್ ಮಾಡಿ',
  [TRANSLATIONS.SUB_TITLES.HOUSE_FLAT_BLOCK_NUMBER]:
    'ಮನೆ / ಫ್ಲಾಟ್ / ಬ್ಲಾಕ್ ನಂಬರ್',
  [TRANSLATIONS.SUB_TITLES.APARTMENT_SOCIETY]: 'ಅಪಾರ್ಟ್ಮೆಂಟ್ / ಸೊಸೈಟಿ',
  [TRANSLATIONS.SUB_TITLES.ROAD_AREA]: 'ರಸ್ತೆ / ಪ್ರದೇಶ',
  [TRANSLATIONS.SUB_TITLES.BEDROOMS_ARE_REQUIRED]: 'ಬೆಡ್‌ರೂಮ್‌ಗಳು ಅಗತ್ಯವಿವೆ',
  [TRANSLATIONS.SUB_TITLES.BALCONIES_ARE_REQUIRED]: 'ಬಾಲ್ಕನಿಗಳು ಅಗತ್ಯವಿವೆ',
  [TRANSLATIONS.SUB_TITLES.BATHROOMS_ARE_REQUIRED]: 'ಬಾತ್‌ರೂಮ್‌ಗಳು ಅಗತ್ಯವಿವೆ',
  [TRANSLATIONS.SUB_TITLES.LISTING_TYPE_IS_REQUIRED]: 'ಪಟ್ಟಿ ಪ್ರಕಾರ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.SUB_TITLES.PROPERTY_CATEGORY_IS_REQUIRED]:
    'ಸಂಪತ್ತಿಯ ವರ್ಗ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.SUB_TITLES.PROPERTY_TYPE_IS_REQUIRED]:
    'ಸಂಪತ್ತಿಯ ಪ್ರಕಾರ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.SUB_TITLES.FURNISHING_TYPE_IS_REQUIRED]:
    'ಸಜ್ಜಿತ ಪ್ರಕಾರ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.SUB_TITLES.OCCUPANCY_STATUS_IS_REQUIRED]:
    'ಆಕ್ಯುಪೆನ್ಸಿ ಸ್ಥಿತಿ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.SUB_TITLES.BUILD_UP_AREA_IS_REQUIRED]:
    'ನಿರ್ಮಿತ ಪ್ರದೇಶ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.SUB_TITLES.CARPET_AREA_IS_REQUIRED]:
    'ಕಾರ್ಪೆಟ್ ಪ್ರದೇಶ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.SUB_TITLES.NO_PROPERTIES_YET]:
    'ಇನ್ನುಮುಂದೆ ಯಾವುದೇ ಆಸ್ತಿಗಳು ಇಲ್ಲ',
  [TRANSLATIONS.SUB_TITLES.NO_CLOSED_PROPERTIES_YET]:
    'ಇನ್ನುಮುಂದೆ ಯಾವುದೇ ಮುಚ್ಚಿದ ಆಸ್ತಿಗಳು ಇಲ್ಲ',
  [TRANSLATIONS.SUB_TITLES.NO_LISTING_AVAILABLE]:
    'ಈ ಕ್ಷಣಕ್ಕೆ ಯಾವುದೇ ಲಿಸ್ಟಿಂಗ್ ಲಭ್ಯವಿಲ್ಲ.',
  [TRANSLATIONS.SUB_TITLES.NO_DEALS_CLOSED]:
    'ಇನ್ನುಮುಂದೆ ಯಾವುದೇ ಒಪ್ಪಂದಗಳು ಮುಗಿದಿಲ್ಲ. ಸಂಪರ್ಕದಲ್ಲಿರಿ!',
  [TRANSLATIONS.SUB_TITLES.PERSONAL_DETAILS]: 'ವೈಯಕ್ತಿಕ ವಿವರಗಳು',
  [TRANSLATIONS.SUB_TITLES.WELCOME_GLAD_TO_HAVE_YOU_ABOARD]:
    'ಸ್ವಾಗತ! ನಿಮ್ಮನ್ನು ನಮ್ಮೊಂದಿಗೆ ಹೊಂದಿದ್ದು ಸಂತೋಷವಾಗಿದೆ.',
  [TRANSLATIONS.SUB_TITLES.TELL_US_YOUR_NAME]: 'ನಮ್ಮಗೆ ನಿಮ್ಮ ಹೆಸರು ಹೇಳಿ',
  [TRANSLATIONS.SUB_TITLES.YOUR_PREFERRED_EMAIL_ADDRESS]:
    'ನಿಮ್ಮ ಆದ್ಯತೆಯ ಇಮೇಲ್ ವಿಳಾಸ',
  [TRANSLATIONS.SUB_TITLES.TRANSACTIONAL_MESSAGES_WHATSAPP]:
    'ವ್ಯವಹಾರ ಸಂಬಂಧಿತ ಸಂದೇಶಗಳು ಮತ್ತು ನವೀಕರಣಗಳನ್ನು ನಿಮ್ಮ ವಾಟ್ಸಪ್‌ಗೆ ಕಳುಹಿಸಲಾಗುತ್ತದೆ',
  [TRANSLATIONS.SUB_TITLES.LETS_BEGIN_WITH_PHONE_NUMBER]:
    'ನಿಮ್ಮ ಫೋನ್ ನಂಬರಿನಿಂದ ಪ್ರಾರಂಭಿಸೋಣ',
  [TRANSLATIONS.SUB_TITLES.NO_CONVERSATIONS_AVAILABLE]:
    'ಯಾವುದೇ ಸಂಭಾಷಣೆಗಳು ಲಭ್ಯವಿಲ್ಲ.',
  [TRANSLATIONS.SUB_TITLES.NO_SPECIALIZATIONS_ADDED]:
    'ಯಾವುದೇ ವಿಶೇಷತೆಗಳು ಸೇರಿಸಲ್ಪಟ್ಟಿಲ್ಲ',
  [TRANSLATIONS.SUB_TITLES.MATCHING_LISTINGS]: 'ಹೋಲಿಕೆಯಾಗುವ ಲಿಸ್ಟಿಂಗ್ಸ್',
  [TRANSLATIONS.SUB_TITLES.ENTER_YRS_OF_EXP]: 'ಅನುಭವದ ವರ್ಷಗಳನ್ನು ನಮೂದಿಸಿ',

  // paragraphs
  [TRANSLATIONS.PARAGRAPHS.SERVICEABLE_IN_BENGALURU]:
    'ನಾವು ಪ್ರಸ್ತುತ ಬೆಂಗಳೂರಿನಲ್ಲಿ ಸೇವೆ ಸಲ್ಲಿಸುತ್ತಿದ್ದೇವೆ ಮತ್ತು ಶೀಘ್ರದಲ್ಲೇ ಹೆಚ್ಚಿನ ನಗರಗಳಿಗೆ ವಿಸ್ತರಿಸಲಿದ್ದೇವೆ!',
  [TRANSLATIONS.PARAGRAPHS.AIRA_INTRO]:
    'ನಾನು ಐರಾ, ಭಾರತದ ಮೊದಲ AI ರಿಯಲ್ ಎಸ್ಟೇಟ್ ಏಜೆಂಟ್. ನಾನು ಹೊಂದಾಣಿಕೆಗಳನ್ನು ಹುಡುಕುತ್ತೇನೆ, ಆಸ್ತಿ ವಿವರಗಳನ್ನು ಬರೆಯುತ್ತೇನೆ ಮತ್ತು ನಿಮ್ಮ ಕೆಲಸವನ್ನು ಸುಲಭಗೊಳಿಸಲು ಸಲಹೆಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತೇನೆ.',
  [TRANSLATIONS.PARAGRAPHS.ADD_OTHER_DETAILS_FOR_SETUP]:
    'ನಿಮ್ಮ ಹೆಸರು ಮತ್ತು ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಾನು ಪಡೆದುಕೊಂಡಿದ್ದೇನೆ. ಕೇವಲ ಇತರ ವಿವರಗಳನ್ನು ಸೇರಿಸಿ, ಮತ್ತು ನಾನು ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ತ್ವರಿತವಾಗಿ ಸೆಟ್ ಅಪ್ ಮಾಡುತ್ತೇನೆ!',
  [TRANSLATIONS.PARAGRAPHS.QUCIK_AND_EASY_SETUP]:
    'ತ್ವರಿತ ಮತ್ತು ಸುಲಭ ಸೆಟಪ್ ನಿಮ್ಮ ವ್ಯಾಪಾರವನ್ನು ಬೆಳೆಸಲು ಸಮಯವನ್ನು ಉಳಿಸುತ್ತದೆ.',
  [TRANSLATIONS.PARAGRAPHS.SHARE_YOUR_TOP_SKILLS]:
    'ನಿಮ್ಮ ಉನ್ನತ ಕೌಶಲ್ಯಗಳು ಮತ್ತು ಶಕ್ತಿಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಿ. ನೀವು ರಿಯಲ್ ಎಸ್ಟೇಟ್‌ನಲ್ಲಿ ಉತ್ತಮವಾಗಿರುವ ಕಾರಣವನ್ನು ನಾನು ಪ್ರದರ್ಶಿಸುತ್ತೇನೆ.',
  [TRANSLATIONS.PARAGRAPHS.ATTRACT_MORE_LEADS]:
    'ನಿಮ್ಮ ವಿಶಿಷ್ಟತೆಯನ್ನು ಸ್ಪಷ್ಟವಾಗಿ ಪ್ರಸ್ತುತಪಡಿಸುವ ಮೂಲಕ ಹೆಚ್ಚಿನ ಲೀಡ್‌ಗಳನ್ನು ಆಕರ್ಷಿಸಿ.',
  [TRANSLATIONS.PARAGRAPHS.TELL_ME_LANGUAGE]:
    'ನೀವು ಸುಗಮವಾಗಿ ಮಾತನಾಡುವ ಭಾಷೆಗಳನ್ನು ನನಗೆ ತಿಳಿಸಿ. ಇದು ವ್ಯಾಪಕ ಪ್ರೇಕ್ಷಕರನ್ನು ತಲುಪಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.',
  [TRANSLATIONS.PARAGRAPHS.EXPAND_YOUR_REACH]:
    'ಸ್ಥಳೀಯ ಪ್ರದೇಶಗಳನ್ನು ಮೀರಿ ನಿಮ್ಮ ವ್ಯಾಪ್ತಿಯನ್ನು ವಿಸ್ತರಿಸಿ ಮತ್ತು ವೈವಿಧ್ಯಮಯ ಗ್ರಾಹಕರೊಂದಿಗೆ ಸಂಪರ್ಕಿಸಿ.',
  [TRANSLATIONS.PARAGRAPHS.SELECT_THE_CITIES]:
    'ನೀವು ಕಾರ್ಯನಿರ್ವಹಿಸುವ ನಗರಗಳು ಮತ್ತು ಪ್ರದೇಶಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ. ಇದು ಜನರಿಗೆ ನಿಮ್ಮನ್ನು ಹುಡುಕಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.',
  [TRANSLATIONS.PARAGRAPHS.TARGET_LOCAL_MARKET]:
    'ನಿಮ್ಮ ಸ್ಥಳೀಯ ಮಾರುಕಟ್ಟೆಯನ್ನು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಗುರಿಯಾಗಿಸಿ ಮತ್ತು ನಿಮ್ಮ ವ್ಯಾಪಾರ ಅವಕಾಶಗಳನ್ನು ಹೆಚ್ಚಿಸಿ.',
  [TRANSLATIONS.PARAGRAPHS.AVAILABLE_IN_FIVE_CITIES]:
    'ನಾವು ಪ್ರಸ್ತುತ ಈ ಐದು ನಗರಗಳಲ್ಲಿ ಲಭ್ಯವಿದ್ದೇವೆ ಮತ್ತು ಶೀಘ್ರದಲ್ಲೇ ವಿಸ್ತರಿಸಲು ನಿರೀಕ್ಷಿಸುತ್ತಿದ್ದೇವೆ',
  [TRANSLATIONS.PARAGRAPHS.ADD_BUSSINESS_AND_SOCIAL_MEDIA_DETAILS]:
    'ನಿಮ್ಮ ವ್ಯಾಪಾರ ವಿವರಗಳು ಮತ್ತು ಸಾಮಾಜಿಕ ಮಾಧ್ಯಮ ಲಿಂಕ್‌ಗಳನ್ನು ಸೇರಿಸಿ. ನಾನು ಅವುಗಳನ್ನು ಸಂಯೋಜಿಸಿ ನಿಮ್ಮ ವೃತ್ತಿಪರ ಚಿತ್ರವನ್ನು ಹೆಚ್ಚಿಸುತ್ತೇನೆ.',
  [TRANSLATIONS.PARAGRAPHS.ENCHANE_ONLINE_PRESENCE]:
    'ನಿಮ್ಮ ಆನ್‌ಲೈನ್ ಉಪಸ್ಥಿತಿಯನ್ನು ಹೆಚ್ಚಿಸಿ ಮತ್ತು ಬ್ರಾಂಡಿಂಗ್ ಪ್ರಯತ್ನಗಳಲ್ಲಿ ಹಣವನ್ನು ಉಳಿಸಿ.',
  [TRANSLATIONS.PARAGRAPHS.RERA_VERIFICATION_NOTE]:
    'vREfyd ನಲ್ಲಿ, ನಾವು ನಿಮ್ಮ ಗೌಪ್ಯತೆಯನ್ನು ಆದ್ಯತೆಗೆ ತೆಗೆದುಕೊಂಡು ಎಲ್ಲಾ ದಾಖಲೆಗಳನ್ನು ಸುರಕ್ಷಿತವಾಗಿ ನಿರ್ವಹಿಸುತ್ತೇವೆ ಮತ್ತು RERA ಪರಿಶೀಲನೆ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿ ನಿಮ್ಮ ಡೇಟಾವನ್ನು ರಕ್ಷಿಸುತ್ತೇವೆ.',
  [TRANSLATIONS.PARAGRAPHS.DOCUMENT_VERIFICATION_NOTE]:
    'vREfyd ನಲ್ಲಿ, ನಿಮ್ಮ ಗೌಪ್ಯತೆಯು ನಮ್ಮ ಆದ್ಯತೆಯಾಗಿದೆ. ನಿಮ್ಮ ಎಲ್ಲಾ ದಾಖಲೆಗಳನ್ನು ಸುರಕ್ಷಿತವಾಗಿ ನಿರ್ವಹಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ಪ್ರತಿ ಹಂತದಲ್ಲೂ ನಿಮ್ಮ ಡೇಟಾವನ್ನು ರಕ್ಷಿಸಲಾಗುತ್ತದೆ.',
  [TRANSLATIONS.PARAGRAPHS.VREFYD_AGENT_DETAILS]:
    'ದಯವಿಟ್ಟು vREfyd ಏಜೆಂಟ್‌ನ ವಿವರಗಳನ್ನು ನೀಡಿ',
  [TRANSLATIONS.PARAGRAPHS.COMMUNITY_DETAILS]:
    'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಸಮುದಾಯದ ವಿವರಗಳನ್ನು ನೀಡಿ',
  [TRANSLATIONS.PARAGRAPHS.PROVIDE_RERA_DETAILS]:
    'ದಯವಿಟ್ಟು ನಿಮ್ಮ RERA ವಿವರಗಳನ್ನು ನೀಡಿ',
  [TRANSLATIONS.PARAGRAPHS.TWO_AGENT_REFERENCE]:
    'ದಯವಿಟ್ಟು ನೀವು ಏಜೆಂಟ್ ಎಂದು ಪರಿಶೀಲಿಸಲು ನಮಗೆ ಸಹಾಯ ಮಾಡಬಹುದಾದ ಇಬ್ಬರು ಏಜೆಂಟ್‌ಗಳ ಉಲ್ಲೇಖಗಳನ್ನು ನೀಡಿ',
  [TRANSLATIONS.PARAGRAPHS.KICK_THINGS_OFF]:
    'ಒಳ್ಳೆಯ ಫೋಟೋದಿಂದ ಪ್ರಾರಂಭಿಸಿ! ನೀವು ಸುಲಭವಾಗಿ ಸಂಪರ್ಕಿಸಬಹುದಾದ ಮತ್ತು ನೆನಪಿನಲ್ಲಿಡಬಹುದಾದ ವೃತ್ತಿಪರ ಚಿತ್ರವನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ.',
  [TRANSLATIONS.PARAGRAPHS.INSPIRATION_EXAMPLE]:
    'ತಕ್ಷಣವೇ ಗಮನ ಸೆಳೆಯಿರಿ ಮತ್ತು ಹೆಚ್ಚಿನ ಆಸಕ್ತಿಯನ್ನು ಆಕರ್ಷಿಸಿ—ಯಾವುದೇ ಹೆಚ್ಚಿನ ಮಾರ್ಕೆಟಿಂಗ್ ಅಗತ್ಯವಿಲ್ಲ! ಸ್ಫೂರ್ತಿಗಾಗಿ ಕೆಳಗಿನ ಉದಾಹರಣೆಯನ್ನು ನೋಡಿ.',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_IMAGE_APPEARANCE]:
    'ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಚಿತ್ರವು ಹೇಗೆ ಕಾಣುತ್ತದೆ. ಉತ್ತಮ ಪ್ರಭಾವಕ್ಕಾಗಿ ಗುಣಮಟ್ಟದ ಚಿತ್ರವನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ/ಕ್ಲಿಕ್ ಮಾಡಿ.',
  [TRANSLATIONS.PARAGRAPHS.ENCRYPT_DATA]:
    'ನೀವು ಹಂಚಿಕೊಳ್ಳುವ ಡೇಟಾವನ್ನು ಎನ್ಕ್ರಿಪ್ಟ್ ಮಾಡಲಾಗುತ್ತದೆ, ಸುರಕ್ಷಿತವಾಗಿ ಸಂಗ್ರಹಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ನಿಮ್ಮ ಗುರುತನ್ನು ಪರಿಶೀಲಿಸಲು ಮಾತ್ರ ಬಳಸಲಾಗುತ್ತದೆ.',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_SHINE]:
    'ಪ್ರಾರಂಭಿಸೋಣ ಮತ್ತು ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಹೊಳಪುಗೊಳಿಸೋಣ! ಸ್ಫೂರ್ತಿಗಾಗಿ ಕೆಳಗಿನ ಉದಾಹರಣೆಯನ್ನು ಪರಿಶೀಲಿಸಿ.',
  [TRANSLATIONS.PARAGRAPHS.START_ADDING_PROPERTIES]:
    'ಸಂಭಾವ್ಯ ಗ್ರಾಹಕರಿಗೆ ನಿಮ್ಮ ಪಟ್ಟಿಗಳನ್ನು ಪ್ರದರ್ಶಿಸಲು ಈಗಲೇ ಆಸ್ತಿಗಳನ್ನು ಸೇರಿಸಲು ಪ್ರಾರಂಭಿಸಿ.',
  [TRANSLATIONS.PARAGRAPHS.EYE_CATECHING_PROFILE_NOTE]:
    'ನನ್ನ ಸಹಾಯದಿಂದ, ನೀವು ಹೆಚ್ಚಿನ ಲೀಡ್‌ಗಳನ್ನು ಆಕರ್ಷಿಸುವ, ಹಣವನ್ನು ಉಳಿಸುವ ಮತ್ತು ನಿಮ್ಮ ರಿಯಲ್ ಎಸ್ಟೇಟ್ ವ್ಯಾಪಾರವನ್ನು ಯಶಸ್ವಿಯಾಗಿಸುವ ವೃತ್ತಿಪರ ಮತ್ತು ಆಕರ್ಷಕ ಪ್ರೊಫೈಲ್ ಅನ್ನು ರಚಿಸುತ್ತೀರಿ.',
  [TRANSLATIONS.PARAGRAPHS.SIMPLY_TYPE_PROP_DETAILS_FOR_AUTOFILL]:
    'ನಿಮ್ಮ WhatsApp ಸಂದೇಶಗಳಿಂದ ವಿವರಗಳನ್ನು ಟೈಪ್ ಮಾಡಿ ಅಥವಾ ಹಂಚಿಕೊಳ್ಳಿ, ಮತ್ತು ನಾವು ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ಸುಲಭವಾಗಿ ಸ್ವಯಂ ಭರ್ತಿ ಮಾಡುತ್ತೇವೆ.',
  [TRANSLATIONS.PARAGRAPHS.PROPERTIES_TRUSTED_COMMUNITY]:
    'ಆಸ್ತಿಗಳು, ವಿಶ್ವಸನೀಯ ಸಮುದಾಯ ಮತ್ತು ತ್ವರಿತ, ಸುರಕ್ಷಿತ ಒಪ್ಪಂದಗಳಿಗೆ AI ಸಾಧನಗಳು.',
  [TRANSLATIONS.PARAGRAPHS.BETA_VERSION_NOTE]:
    'ನೀವು ಪ್ರಸ್ತುತ ಆಂತರಿಕ ಬೀಟಾ ಆವೃತ್ತಿಯನ್ನು ಬಳಸುತ್ತಿದ್ದೀರಿ',
  [TRANSLATIONS.PARAGRAPHS.HEAVY_LOAD_NOTE]:
    'ನಾವು ಪ್ರಸ್ತುತ ಕೆಲವು ಭಾರೀ ಲೋಡ್ ಅನ್ನು ಎದುರಿಸುತ್ತಿದ್ದೇವೆ ಮತ್ತು ಒತ್ತಡವನ್ನು ಅನುಭವಿಸುತ್ತಿದ್ದೇವೆ. ಸ್ವಲ್ಪ ಸಮಯ ಕಾಯಿರಿ, ಮತ್ತು ನಂತರ ನೀವು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಬಹುದು.',
  [TRANSLATIONS.PARAGRAPHS.CANNOT_FIND_PAGE]:
    'ಕ್ಷಮಿಸಿ, ನೀವು ಹುಡುಕುತ್ತಿರುವ ಪುಟವನ್ನು ನಾವು ಕಂಡುಹಿಡಿಯಲು ಸಾಧ್ಯವಾಗಲಿಲ್ಲ',
  [TRANSLATIONS.PARAGRAPHS.DROP_THE_MAP_PIN]:
    'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ದೃಢೀಕರಿಸಲು ನಕ್ಷೆಯಲ್ಲಿ ಪಿನ್ ಅನ್ನು ಬಿಡಿ',
  [TRANSLATIONS.PARAGRAPHS.DEAL_CLOSED_SUCCESSFULLY]:
    'ಒಪ್ಪಂದವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮುಚ್ಚಲಾಗಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.NEED_TO_CONFIRM_THE_DEAL_CLOSED_WITH]:
    'ಒಪ್ಪಂದವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮುಚ್ಚಲಾಗಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.USER_UNLOCKED_PROPERTY]:
    'ಒಪ್ಪಂದವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮುಚ್ಚಲಾಗಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.SIT_BACK_AND_RELAX]:
    'ಹಿಂದೆ ಸುಮ್ಮನೆ ಕುಳಿತುಕೊಳ್ಳಿ ಮತ್ತು ರಿಲ್ಯಾಕ್ಸ್ ಆಗಿ, ಆಸ್ತಿ ಪರಿಶೀಲನೆ ಮುಗಿದ ನಂತರ ನಾವು ನಿಮಗೆ ತಿಳಿಸುತ್ತೇವೆ.',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_ALREADY_LISTED]:
    'ಈ ಆಸ್ತಿ ಈಗಾಗಲೇ ಪಟ್ಟಿಮಾಡಲ್ಪಟ್ಟಿದೆ ಎಂದು ತೋರುತ್ತದೆ. ಮತ್ತೆ ಸಲ್ಲಿಸುವ ಮೊದಲು ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಪಟ್ಟಿಗಳನ್ನು ಪರಿಶೀಲಿಸಿ.',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_VERIFICATION_EXPIRES_IN]:
    'ಆಸ್ತಿ ಪರಿಶೀಲನೆಯ ಅವಧಿ ಮುಗಿಯುತ್ತಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_CANNOT_VERIFIED]:
    'ಕ್ಷಮಿಸಿ, ನಿಮ್ಮ ಆಸ್ತಿಯನ್ನು ಪರಿಶೀಲಿಸಲು ಸಾಧ್ಯವಾಗಲಿಲ್ಲ.',
  [TRANSLATIONS.PARAGRAPHS.NO_UNLOCK_PROPS_YET]:
    'ಇನ್ನೂ ಯಾವುದೇ ಅನ್ಲಾಕ್ ಆಸ್ತಿಗಳಿಲ್ಲ. ಹೆಚ್ಚಿನ ಲೀಡ್ಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಸಮಯ ಬಂದಿದೆ!',
  [TRANSLATIONS.PARAGRAPHS.NO_PERFECT_MATCH_YET]:
    'ಇನ್ನೂ ಸರಿಯಾದ ಮ್ಯಾಚ್ ಸಿಗಲಿಲ್ಲ, ಆದರೆ ಚಿಂತಿಸಬೇಡಿ, ನಾವು ನಮ್ಮ ಸಂಪೂರ್ಣ ನೆಟ್ವರ್ಕ್ಗೆ ಎಚ್ಚರಿಕೆ ನೀಡಿದ್ದೇವೆ!',
  [TRANSLATIONS.PARAGRAPHS.FIRST_TO_KNOW]:
    'ಯಾವುದೇ ಸೂಕ್ತವಾದ ಆಸ್ತಿ ಕಾಣಿಸಿಕೊಂಡರೆ, ನೀವು ಅದನ್ನು ಮೊದಲು ತಿಳಿದುಕೊಳ್ಳುವಿರಿ.',
  [TRANSLATIONS.PARAGRAPHS.FAILURE_TO_GUIDELINES]:
    '*ಈ ಮಾರ್ಗಸೂಚಿಗಳನ್ನು ಪಾಲಿಸದಿದ್ದರೆ ಪರಿಶೀಲನೆ ವಿಫಲವಾಗುತ್ತದೆ.',
  [TRANSLATIONS.PARAGRAPHS.COMPLETE_YOUR_PROFILE_FOR_PROP_VER]:
    'ಆಸ್ತಿ ಪರಿಶೀಲನೆ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಪ್ರಾರಂಭಿಸಲು ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಪರಿಶೀಲನೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ.',
  [TRANSLATIONS.PARAGRAPHS.VERIFICATION_PENDING_NOTE]:
    'ಪರಿಶೀಲನೆ ಬಾಕಿಯಿದೆ. ನಾವು ನಿಮಗೆ ಸ್ಥಿತಿಯ ಬಗ್ಗೆ ತಿಳಿಸುತ್ತೇವೆ.',
  [TRANSLATIONS.PARAGRAPHS.VERIFICATION_IN_PROGRESS_NOTE]:
    'ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಪರಿಶೀಲನೆ ಪ್ರಗತಿಯಲ್ಲಿದೆ, ಪ್ರೊಫೈಲ್ ಪರಿಶೀಲನೆ ಮುಗಿದ ನಂತರ ನಾವು ನಿಮಗೆ ತಿಳಿಸುತ್ತೇವೆ.',
  [TRANSLATIONS.PARAGRAPHS.QUICK_LOOK_PROFILE]:
    'ಲೈವ್ ಆಗುವ ಮೊದಲು ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ತ್ವರಿತವಾಗಿ ಪರಿಶೀಲಿಸಿ. ನೀವು ಯಾವುದೇ ಬದಲಾವಣೆಗಳನ್ನು ಬಯಸಿದರೆ ನನಗೆ ತಿಳಿಸಿ.',
  [TRANSLATIONS.PARAGRAPHS.ENSURE_PROFILE_PERFECTION]:
    'ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಪರಿಪೂರ್ಣವಾಗಿ ಕಾಣುವಂತೆ ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ, ಇದರಿಂದ ಭವಿಷ್ಯದಲ್ಲಿ ಸರಿಪಡಿಸುವ ಅಗತ್ಯವಿಲ್ಲ.',
  [TRANSLATIONS.PARAGRAPHS.PREFILLED_DETAILS_AIRA]:
    'ನೀವು ಹಂಚಿಕೊಂಡ ಮಾಹಿತಿಯ ಆಧಾರದ ಮೇಲೆ ನಾನು ನಿಮ್ಮಿಗಾಗಿ ವಿವರಗಳನ್ನು ಮುಂಚಿತವಾಗಿ ತುಂಬಿದ್ದೇನೆ. ಒಂದು ಬಾರಿ ತ್ವರಿತವಾಗಿ ಪರಿಶೀಲಿಸಿ ಮತ್ತು ಖಾಲಿ ಉಳಿದಿರುವ ಕ್ಷೇತ್ರಗಳನ್ನು ಭರ್ತಿ ಮಾಡಿ!',
  [TRANSLATIONS.PARAGRAPHS.PROPERTIES_YOU_RE_INTERESTED_IN]:
    'ನೀವು ಆಸಕ್ತರಾಗಿರುವ ಆಸ್ತಿಗಳು ಇಲ್ಲಿ ತೋರಿಸಲಾಗುತ್ತವೆ.',
  [TRANSLATIONS.PARAGRAPHS.CHAT_WITH_SOMEONE]:
    'ಯಾರಾದರೂ ಚಾಟ್ ಮಾಡುವುದು ಚೆನ್ನಾಗಿದೆ.',
  [TRANSLATIONS.PARAGRAPHS.PICK_A_PERSON]:
    'ಎಡ ಮೆನುವಿನಿಂದ ಒಬ್ಬರನ್ನು ಆಯ್ಕೆ ಮಾಡಿ ಮತ್ತು ನಿಮ್ಮ ಸಂಭಾಷಣೆಯನ್ನು ಪ್ರಾರಂಭಿಸಿ.',
  [TRANSLATIONS.PARAGRAPHS.LEAD_INTERESTED]:
    'ನಿಮ್ಮ ಆಸ್ತಿಯಲ್ಲಿ ಆಸಕ್ತಿ ಹೊಂದಿರುವ ಲೀಡ್‌ಗಳು ಇಲ್ಲಿ ತೋರಿಸಲಾಗುತ್ತವೆ.',
  [TRANSLATIONS.PARAGRAPHS.FOUND_A_PROP_MATCH]:
    'ನಾನು ನಿಮ್ಮ ಹುಡುಕಾಟಕ್ಕೆ ತಕ್ಕ ಆಸ್ತಿಯನ್ನು ಕಂಡುಹಿಡಿದಿದ್ದೇನೆ. ಇಲ್ಲಿದ್ದಾರೆ ಟಾಪ್ ಏಜೆಂಟ್‌ಗಳು, ಅವರು ನಿಮ್ಮ ಹುಡುಕಾಟ ಮಾನದಂಡಗಳಿಗೆ ಹೊಂದುವ ಆಸ್ತಿಗಳನ್ನು ಪಟ್ಟಿ ಮಾಡಿದ್ದಾರೆ.',
  [TRANSLATIONS.PARAGRAPHS.COULD_NOT_FIND_ANY_PROP]:
    'ನಾನು ಈಗಾಗಲೇ ನಿಮ್ಮ ಹುಡುಕಾಟಕ್ಕೆ ಹೊಂದುವ ಯಾವುದೇ ಆಸ್ತಿಯನ್ನು ಕಂಡುಹಿಡಿಯಲಿಲ್ಲ, ಆದರೆ ಚಿಂತಿಸಬೇಡಿ! ನಾನು ಈಗಾಗಲೇ ನಮ್ಮ ಏಜೆಂಟ್ ನೆಟ್ವರ್ಕ್‌ಗೆ ಮಾಹಿತಿ ನೀಡಿದ್ದೇನೆ, ಮತ್ತು ಏನಾದರೂ ಲಭ್ಯವಾದಾಗ ತಕ್ಷಣ ನಿಮಗೆ ತಿಳಿಸುತ್ತೇನೆ.',
  [TRANSLATIONS.PARAGRAPHS.FOUND_SEVERAL_PROP_MATCH]:
    'ನಾನು ಹಲವಾರು ಆಸ್ತಿಗಳನ್ನು ಕಂಡುಹಿಡಿದಿದ್ದೇನೆ, ಅವು ನಿಮ್ಮ ಹುಡುಕಾಟಕ್ಕೆ ಹೊಂದುತ್ತವೆ. ಇಲ್ಲಿದ್ದಾರೆ',
  [TRANSLATIONS.PARAGRAPHS.WHO_HAVE_LISTED]:
    'ಅವರು ನಿಮ್ಮ ಹುಡುಕಾಟಕ್ಕೆ ತಕ್ಕ ಆಸ್ತಿಗಳನ್ನು ಪಟ್ಟಿ ಮಾಡಿರುವವರು.',
  [TRANSLATIONS.PARAGRAPHS.SHARE_YOUR_PREFERRED_LOCALITY]:
    'ನೀವು ಆದ್ಯತೆಯ ಸ್ಥಳ, ಬಜೆಟ್ ಅಥವಾ ಬೇರೆ ಯಾವುದೇ ವಿವರಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಿ, ನಾನು ನಿಮಗೆ ಸರಿಯಾದ ಏಜೆಂಟ್ ಅನ್ನು ತಕ್ಷಣ ಸಂಪರ್ಕಿಸುವಂತೆ ಮಾಡುತ್ತೇನೆ!',
  [TRANSLATIONS.PARAGRAPHS.LOOKING_FOR_SOMETHING_SPECIFIC]:
    'ನೀವು ಯಾವುದಾದರೂ ನಿರ್ದಿಷ್ಟವಾದುದನ್ನು ಹುಡುಕುತ್ತಿದ್ದೀರಾ?',
  [TRANSLATIONS.PARAGRAPHS.BUT_WE_HAVE_ALERTED_OUR_NETWORK]:
    'ನಾವು ಈಗಾಗಲೇ ನಮ್ಮ ನೆಟ್ವರ್ಕ್‌ಗೆ ಮಾಹಿತಿ ನೀಡಿದ್ದಾರೆ. ಸರಿಯಾದ ಆಸ್ತಿ ಲಭ್ಯವಾದಾಗ ನಿಮಗೆ ತಕ್ಷಣ ತಿಳಿಸಲಾಗುತ್ತದೆ!',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_PREVIEW_AIRA_TITLE]:
    'ನಮೂದು ಪ್ರೊಫೈಲ್ – ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಹೀಗೆ ಕಾಣಿಸುತ್ತದೆ',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_PREVIEW_AIRA_DESCRIPTION]:
    'ನನ್ನ ಸಹಾಯದಿಂದ, ನೀವು ಹೆಚ್ಚು ಲೀಡ್ಗಳನ್ನು ಆಕರ್ಷಿಸುವ, ಹಣ ಉಳಿಸುವ, ಮತ್ತು ನಿಮ್ಮ ರಿಯಲ್ ಎಸ್ಟೇಟ್ ವ್ಯಾಪಾರವನ್ನು ಬೆಳೆಸುವ ವೃತ್ತಿಪರ ಮತ್ತು ಆಕರ್ಷಕ ಪ್ರೊಫೈಲ್ ಅನ್ನು ನಿರ್ಮಿಸಬಹುದು.',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_PREVIEW_AIRA_NOTE]:
    'ಆರಂಭಿಸೋಣ ಮತ್ತು ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಮತ್ತಷ್ಟು ಆಕರ್ಷಕವಾಗಿಸಿ! ಸ್ಪೂರ್ತಿಗಾಗಿ ಕೆಳಗಿನ ಉದಾಹರಣೆಯನ್ನು ನೋಡಿ.',
  [TRANSLATIONS.PARAGRAPHS.CHECK_VERIFICATION_CODE]:
    'ದಯವಿಟ್ಟು ವಾಟ್ಸಾಪ್/ಎಸ್‌ಎಂಎಸ್‌ನಲ್ಲಿ ಪರಿಶೀಲನಾ ಕೋಡ್ ಪರಿಶೀಲಿಸಿ.',
  [TRANSLATIONS.PARAGRAPHS.LOGIN_SUCCESSFUL]: 'ನಿಮ್ಮ ಲಾಗಿನ್ ಯಶಸ್ವಿಯಾಗಿದೆ.',
  [TRANSLATIONS.PARAGRAPHS.TELL_ME_ABOUT_PROPERTY_INVESTMENT]:
    'ನಾನು ಆಸ್ತಿ ಹೂಡಿಕೆಯ ಬಗ್ಗೆ ತಿಳಿಯಲು ಇಚ್ಛಿಸುತ್ತೇನೆ.',
  [TRANSLATIONS.PARAGRAPHS.NEED_ADVICE_ON_BUYING]:
    'ನನಗೆ ಮನೆ ಖರೀದಿಸುವ ಬಗ್ಗೆ ಸಲಹೆ ಬೇಕು.',
  [TRANSLATIONS.PARAGRAPHS.HOW_CAN_I_EARN_MORE_RENTAL_INCOME]:
    'ನಾನು ಹೆಚ್ಚುವರಿ ಬಾಡಿಗೆ ಆದಾಯವನ್ನು ಹೇಗೆ ಗಳಿಸಬಹುದು?',
  [TRANSLATIONS.PARAGRAPHS.WHAT_ARE_THE_BEST_AREAS]:
    'ರಿಯಲ್ ಎಸ್ಟೇಟ್‌ನಲ್ಲಿ ಹೂಡಿಕೆ ಮಾಡಲು ಅತ್ಯುತ್ತಮ ಪ್ರದೇಶಗಳು ಯಾವುವು?',
  [TRANSLATIONS.PARAGRAPHS.BETA_VERSION_FEEDBACK_NOTE]:
    'ನೀವು ಬೇಟಾ ಆವೃತ್ತಿಯನ್ನು ಬಳಸುತ್ತಿದ್ದೀರಿ. ಪ್ರತಿಕ್ರಿಯೆಗಳಿಗೆ ಥಂಬ್ಸ್-ಅಪ್ ಅಥವಾ ಥಂಬ್ಸ್-ಡೌನ್ ನೀಡುವುದರ ಮೂಲಕ AIRA ಅನ್ನು ಉತ್ತಮಗೊಳಿಸಲು ಸಹಾಯ ಮಾಡಿ.',
  [TRANSLATIONS.PARAGRAPHS.HANG_TIGHT]:
    'ಸ್ವಲ್ಪ ಕಾಯಿರಿ! ನಿಮ್ಮ ಆಸ್ತಿ ಪಟ್ಟಿ ಸಿದ್ಧಪಡಿಸುತ್ತಿದ್ದೇನೆ',
  [TRANSLATIONS.PARAGRAPHS.ANALYSING_YOUR_INPUTS]:
    'ಸರಿ ಸರಿಯಾದ ಆಸ್ತಿ ವಿವರಣೆಯನ್ನು ರಚಿಸಲು ನಿಮ್ಮ ಮಾಹಿತಿಯ ವಿಶ್ಲೇಷಣೆ ಮಾಡಲಾಗುತ್ತಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.OPTIMIZING_LISTING]:
    'ಸರಿಯಾದ ಖರೀದಿದಾರರು ಮತ್ತು ಬಾಡಿಗೆದಾರರನ್ನು ಆಕರ್ಷಿಸಲು ನಿಮ್ಮ ಪಟ್ಟಿಯನ್ನು ಸೂಕ್ತಗೊಳಿಸುತ್ತಿದ್ದೇನೆ',
  [TRANSLATIONS.PARAGRAPHS.HIGHLIGHTING_FEATURES]:
    'ನಿಮ್ಮ ಆಸ್ತಿಯನ್ನು目ರುತ್ತ ಮಾಡಿ ತೋರಿಸಲು ಪ್ರಮುಖ ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು ಹೀಗಿದೆರೆಂದು ಮಾಡುತ್ತಿದ್ದೇನೆ',
  [TRANSLATIONS.PARAGRAPHS.ALMOST_VERIFYING_DETAILS]:
    'ಹತ್ತಿರ ಬಂದಿದ್ದೇವೆ! ಅಂತಿಮ ಟಚ್‌ಗೆ ನಿಮ್ಮ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸುತ್ತಿದ್ದೇನೆ',
  [TRANSLATIONS.PARAGRAPHS.AT_LEAST_ONE_FILE_REQUIRED]:
    'ಕನಿಷ್ಠ ಒಂದು ಫೈಲ್ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_PINCODE]:
    'ದಯವಿಟ್ಟು ಮಾನ್ಯ ಪಿನ್ ಕೋಡ್ ನಮೂದಿಸಿ',
  [TRANSLATIONS.PARAGRAPHS.PINCODE_REQUIRED]: 'ಪಿನ್ ಕೋಡ್ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.PIN_LOCATION]: 'ನಕ್ಷೆಯಲ್ಲಿ ಸ್ಥಳವನ್ನು ಪಿನ್ ಮಾಡಿ',
  [TRANSLATIONS.PARAGRAPHS.ROAD_AREA_REQUIRED]: 'ರಸ್ತೆ ಅಥವಾ ಪ್ರದೇಶ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.LOCALITY_REQUIRED]: 'ಸ್ಥಳ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.CITY_REQUIRED]: 'ನಗರ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.STATE_REQUIRED]: 'ರಾಜ್ಯ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.APARTMENT_REQUIRED]:
    'ಅಪಾರ್ಟ್ಮೆಂಟ್ ಅಥವಾ ಸೊಸೈಟಿಯ ಹೆಸರು ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.HOUSE_NO_REQUIRED]: 'ಮನೆ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.BUILT_UP_AREA_MUST_BE_A_NUMBER]:
    'ನಿರ್ಮಿತ ಪ್ರದೇಶ ಒಂದು ಸಂಖ್ಯೆಯಾಗಿರಬೇಕು',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_BUILT_UP_AREA]:
    'ದಯವಿಟ್ಟು ಮಾನ್ಯ ನಿರ್ಮಿತ ಪ್ರದೇಶ ನಮೂದಿಸಿ',
  [TRANSLATIONS.PARAGRAPHS.CARPET_AREA_MUST_BE_A_NUMBER]:
    'ಕಾರ್ಪೆಟ್ ಪ್ರದೇಶ ಒಂದು ಸಂಖ್ಯೆಯಾಗಿರಬೇಕು',
  [TRANSLATIONS.PARAGRAPHS.CARPET_AREA_MUST_BE_SMALLER_THAN_BUILT_UP_AREA]:
    'ಕಾರ್ಪೆಟ್ ಪ್ರದೇಶ ನಿರ್ಮಿತ ಪ್ರದೇಶಕ್ಕಿಂತ ಕಡಿಮೆ ಇರಬೇಕು',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_CARPET_AREA]:
    'ದಯವಿಟ್ಟು ಮಾನ್ಯ ಕಾರ್ಪೆಟ್ ಪ್ರದೇಶ ನಮೂದಿಸಿ',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_INPUT]:
    'ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಒಳಹೊಕ್ಕಣೆ ನಮೂದಿಸಿ',
  [TRANSLATIONS.PARAGRAPHS.TOTAL_FLOORS_ARE_REQUIRED]:
    'ಒಟ್ಟು ಮಹಡಿಗಳ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_NUMBER_OF_FLOORS]:
    'ದಯವಿಟ್ಟು ಮಾನ್ಯ ಮಹಡಿಗಳ ಸಂಖ್ಯೆ ನಮೂದಿಸಿ',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_ON_FLOOR_REQUIRED]:
    'ಮಹಡಿಯಲ್ಲಿ ಆಸ್ತಿ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_ON_FLOOR_MUST_BE_LESSER_THAN_TOTAL_FLOORS]:
    'ಆಸ್ತಿ ಮಹಡಿ ಒಟ್ಟು ಮಹಡಿಗಳಿಗಿಂತ ಕಡಿಮೆ ಇರಬೇಕು',
  [TRANSLATIONS.PARAGRAPHS.LOCK_IN_PERIOD_IS_REQUIRED]:
    'ಲಾಕ್-ಇನ್ ಅವಧಿ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.MAINTENANCE_AMOUNT_REQUIRED]:
    'ಬಾಡಿಗೆ ಪಟ್ಟಿ ಮಾಡಲು ನಿರ್ವಹಣಾ ಮೊತ್ತ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_MAINTENANCE_AMOUNT]:
    'ದಯವಿಟ್ಟು ಮಾನ್ಯ ನಿರ್ವಹಣಾ ಮೊತ್ತ ನಮೂದಿಸಿ',
  [TRANSLATIONS.PARAGRAPHS.SECURITY_DEPOSIT_REQUIRED]:
    'ಬಾಡಿಗೆ ಪಟ್ಟಿ ಮಾಡಲು ಭದ್ರತಾ ಠೇವಣಿ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_SECURITY_DEPOSIT_AMOUNT]:
    'ದಯವಿಟ್ಟು ಮಾನ್ಯ ಭದ್ರತಾ ಠೇವಣಿ ಮೊತ್ತ ನಮೂದಿಸಿ',
  [TRANSLATIONS.PARAGRAPHS.RENT_AMOUNT_REQUIRED]:
    'ಬಾಡಿಗೆ ಪಟ್ಟಿ ಮಾಡಲು ಬಾಡಿಗೆ ಮೊತ್ತ ಅಗತ್ಯವಿದೆ',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_RENT_AMOUNT]:
    'ದಯವಿಟ್ಟು ಮಾನ್ಯ ಬಾಡಿಗೆ ಮೊತ್ತ ನಮೂದಿಸಿ',
  [TRANSLATIONS.PARAGRAPHS.SALE_PRICE_REQUIRED]:
    "'ಮಾರಾಟಕ್ಕಾಗಿ' ಪಟ್ಟಿಗೆ ಮಾರಾಟದ ಬೆಲೆ ಅಗತ್ಯವಿದೆ",
};

export default translationKannada;
